import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import queryString from 'query-string';
import { useHistory } from 'react-router';

export default function LineRegister(props) {
  const [user, setUser] = useState();
  const [linkToken, setLinkToken] = useState();
  const history = useHistory();  

  useEffect(() => {
    const query = queryString.parse(props.location.search);         
    setUser(JSON.parse(localStorage.getItem('user')))

    if (query.linkToken) {
      setLinkToken(query.linkToken)
    }
  }, [])

  function connectAccount() {
    const data = {
      userId: user.id,
      linkToken: linkToken
    }
    fetch( process.env.REACT_APP_API_URL + '/api/line/register', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(res => res.json())
    .then ( ( res ) => {
      if(res.ok){
          window.location.href = `https://access.line.me/dialog/bot/accountLink?linkToken=${linkToken}&nonce=${res.nonce}`; 
      } else {
          alert('เกิดข้อผิดพลาดกรุณาปิดหน้าต่าง แล้วลองลงทะเบียนอีกครั้ง')
      }
    })
  }

  return (

        <Grid container  direction="column" justify="center" alignItems="center" spacing={3} style={{ minHeight: '100vh' }}>
          { linkToken && user ?
            <React.Fragment>
              <Grid item md={4} xs={12}>
                <Typography align="center">
                  {'สวัสดีคุณ' + user.firstname + ' ' + user.lastname } 
                </Typography>
                <Typography align="center" style={{marginTop: "2rem"}}>
                  คุณกำลังเชื่อมต่อไลน์แอคเคาท์ เข้ากับระบบ Perfect-Med 
                </Typography>
                <Typography align="center">
                  กดปุ่มด้านล่างเพื่อดำเนินการต่อ
                </Typography>
              </Grid>
              <Grid item md={4} xs={12}>
                <Button variant="contained" color="primary" onClick={connectAccount}>
                  เชื่อมต่อแอคเคาท์
                </Button>
              </Grid>
              <Grid item md={12} xs={12}>
                <a style={{textDecoration: 'underline', color: 'gray', width: '100%'}} onClick={()=>history.push('/logout')}>
                  ออกจากระบบ
                </a>
              </Grid>
            </React.Fragment>
            :
            <Grid item md={4} xs={12}>
              <Typography align="center" color="error" gutterBottom>
                เกิดข้อผิดพลาด
              </Typography>
              <Typography align="center">
                กรุณาปิดหน้าต่างนี้แล้วลองใหม่อีกครั้ง
              </Typography>
            </Grid>
          }
        </Grid>

  );
}