import React, { Component, useCallback, useEffect } from 'react';
import { Route } from "react-router-dom";
import Patient from './Patient';
import SearchPatient from './SearchPatient';
import _ from 'lodash'

import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import SearchIcon from '@material-ui/icons/Search';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PrintIcon from '@material-ui/icons/Print';
import LockIcon from '@material-ui/icons/Lock';
import AttachmentIcon from '@material-ui/icons/Attachment';
import BarChartIcon from '@material-ui/icons/BarChart';
import SendXray from './SendXray';
import UploadQueue from './UploadQueue';
import Upload from './Upload';
import HistoryIcon from '@material-ui/icons/History';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import ReadQueue from './ReadQueue';
import ReadFilm from './ReadFilm';
import ReadDoneQueue from './ReadDoneQueue';
import AllQueue from './AllQueue';
import ExcelExport from './ExcelExport';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ChangePassword from './ChangePassword';
import ReadHistory from './ReadHistory';
import AllCenter from './AllCenter';
import Center from './Center';
import AllUser from './AllUser';
import User from './User';
import Ward from './Ward';
import Service from './Service';
import EditService from './EditService';
import History from './History';
import Sender from './Sender';
import ReverseCase from './ReverseCase';
import CopyService from './CopyService';
import SearchCaseId from './SearchCaseId';
import SearchReceiptId from './SearchReceiptId';
import DeletePatient from './DeletePatient';
import ReportAllCenter from './ReportAllCenter';
import ReportMed from './ReportMed';
import EditCase from './EditCase';
import ReportDF from './ReportDF';
import ReportCgd from './ReportCgd';
import DoctorTeam from './DoctorTeam';
import LineUserMenu from './LineUserMenu';
import ReportDFSummary from './ReportDFSummary';

const drawerWidth = 175;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const cssIcon = {
  minWidth: 38
}

function ResponsiveDrawer(props) {
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [pageTitle, setTitle] = React.useState('')
  const [xrayCenter, setXrayCenter] = React.useState('');
  const [user, setUser] = React.useState(JSON.parse(localStorage.getItem('user')));
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const buttonClick = (e) => {
    props.history.push(e)
  }

  useEffect(() => {
    if (localStorage.getItem('xrayCenter')) {
      const xrayCenter = JSON.parse(localStorage.getItem('xrayCenter'))
      setXrayCenter(xrayCenter.name)
    }
      
  })

  const handleSubmenu = event => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
    
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <List>
        <Divider/>
          {user.role === 'user' && <div>
            <MenuListItem label="งานทั้งหมด" link="/home/allQueue" buttonClick={buttonClick}>
              <HomeIcon />
            </MenuListItem>
            <MenuListItem label="ค้นหาผู้ป่วย" link="/home/searchPatient" buttonClick={buttonClick}>
              <SearchIcon />
            </MenuListItem>
            <MenuListItem label="ผู้ป่วยใหม่" link="/home/patient" buttonClick={buttonClick}>
              <PersonAddIcon />
            </MenuListItem>
            <MenuListItem label="อัพโหลดภาพ" link="/home/uploadQueue" buttonClick={buttonClick}>
              <AttachmentIcon />
            </MenuListItem>
            <MenuListItem label="ผลอ่านภาพ" link="/home/readDoneQueue" buttonClick={buttonClick}>
              <PrintIcon />
            </MenuListItem>
            <MenuListItem label="รายงานศูนย์" link="/home/report" buttonClick={buttonClick}>
              <BarChartIcon />
            </MenuListItem>
            <MenuListItem label="รายงานยา" link="/home/reportMed" buttonClick={buttonClick}>
              <BarChartIcon />
            </MenuListItem>
            <ListItem button key="อื่นๆ" 
              onClick={handleSubmenu}>
              <ListItemIcon style={{...cssIcon}}><MoreHorizIcon /></ListItemIcon>
                <EtcMenuUser anchorEl={anchorEl} buttonClick={buttonClick}/> 
              <ListItemText primary="อื่นๆ" />
            </ListItem>
          </div>}
          {user.role === 'doctor' && <div>
            <MenuListItem label="อ่านภาพ" link="/home/readQueue" buttonClick={buttonClick}>
              <PhotoLibraryIcon />
            </MenuListItem>
            <MenuListItem label="ประวัติอ่านภาพ" link="/home/readHistory" buttonClick={buttonClick}>
              <HistoryIcon />
            </MenuListItem>
            <MenuListItem label="รายงานDF" link="/home/reportDF" buttonClick={buttonClick}>
              <BarChartIcon />
            </MenuListItem>
            <ListItem button key="อื่นๆ" 
              onClick={handleSubmenu}>
              <ListItemIcon style={{...cssIcon}}><MoreHorizIcon /></ListItemIcon>
              <EtcMenuDoctor anchorEl={anchorEl} buttonClick={buttonClick}/> 
              <ListItemText primary="อื่นๆ" />
            </ListItem>
          </div>}
          {user.role === 'office' && <div>
            <MenuListItem label="รายงานศูนย์" link="/home/report" buttonClick={buttonClick}>
              <BarChartIcon />
            </MenuListItem>
            <MenuListItem label="รายงานยา" link="/home/reportMed" buttonClick={buttonClick}>
              <BarChartIcon />
            </MenuListItem>
            <MenuListItem label="รายงานรวม" link="/home/reportAllCenter" buttonClick={buttonClick}>
              <BarChartIcon />
            </MenuListItem>
            <MenuListItem label="รายงานDF" link="/home/reportDF" buttonClick={buttonClick}>
              <BarChartIcon />
            </MenuListItem>
            <ListItem button key="อื่นๆ" 
              onClick={handleSubmenu}>
              <ListItemIcon style={{...cssIcon}}><MoreHorizIcon /></ListItemIcon>
              <SimpleMenu anchorEl={anchorEl} buttonClick={buttonClick}/> 
              <ListItemText primary="อื่นๆ" />
            </ListItem>
          </div>}
          {user.role === 'admin' && <div>
            <MenuListItem label="ศูนย์ทั้งหมด" link="/home/allCenter" buttonClick={buttonClick}>
              <LocationOnIcon />
            </MenuListItem>
            <MenuListItem label="ผู้ใช้ทั้งหมด" link="/home/allUser" buttonClick={buttonClick}>
              <PersonAddIcon />
            </MenuListItem>
            <MenuListItem label="รายงานศูนย์" link="/home/report" buttonClick={buttonClick}>
              <BarChartIcon />
            </MenuListItem>
            <MenuListItem label="รายงานรวม" link="/home/reportAllCenter" buttonClick={buttonClick}>
              <BarChartIcon />
            </MenuListItem>
            <MenuListItem label="รายงานยา" link="/home/reportMed" buttonClick={buttonClick}>
              <BarChartIcon />
            </MenuListItem>
            <MenuListItem label="รายงานDF" link="/home/reportDF" buttonClick={buttonClick}>
              <BarChartIcon />
            </MenuListItem>
            <MenuListItem label="รายงานสรุปDF" link="/home/reportDFSummary" buttonClick={buttonClick}>
              <BarChartIcon />
            </MenuListItem>
            <MenuListItem label="รายงานCGD" link="/home/reportCgd" buttonClick={buttonClick}>
              <BarChartIcon />
            </MenuListItem>
            <ListItem button key="อื่นๆ" 
              onClick={handleSubmenu}>
              <ListItemIcon style={{...cssIcon}}><MoreHorizIcon /></ListItemIcon>
                <EtcMenuAdmin anchorEl={anchorEl} buttonClick={buttonClick}/> 
              <ListItemText primary="อื่นๆ" />
            </ListItem>
          </div>}
        </List>
        <div style={{ marginTop: "auto" }}/>
        <Divider/>
        <div style={{width: "80%", textAlign: "center", margin: "1rem auto 0 auto"}}><b>{user.role === 'doctor' ? 'แพทย์' : 'คุณ'}{user.firstname}</b><br/>
          <div style={{fontSize: "0.7rem"}}>{xrayCenter}</div>
        </div>
        <List >          
          <ListItem button key="ออกจากระบบ" onClick={() => buttonClick('/logout')}>
            <ListItemIcon style={{...cssIcon}}><LockIcon /></ListItemIcon>
            <ListItemText primary="ออกจากระบบ" />
          </ListItem>
        </List>
        <Divider/>
    </div>
  );

  const changeTitle = useCallback(
    event => {
      setTitle(event);
    },
    [pageTitle]
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            {pageTitle}
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      
      <main className={classes.content}>
        <div className={classes.toolbar} />
          {user.role === 'user' && 
            <Route exact path="/home" render={ (props) => <AllQueue changeTitle = {changeTitle} {...props} />} />
          }
          {user.role === 'doctor' && 
            <Route exact path="/home" render={ (props) => <ReadQueue changeTitle = {changeTitle} {...props} />} />
          }
          <Route path="/home/allQueue" render={ (props) => <AllQueue changeTitle = {changeTitle} {...props} />} />
          <Route path="/home/patient" render={ (props) => <Patient changeTitle = {changeTitle} {...props} />} />
          <Route path="/home/searchPatient" render={ (props) => <SearchPatient changeTitle = {changeTitle} {...props} />} />
          <Route path="/home/sendXray" render={ (props) => <SendXray changeTitle = {changeTitle} {...props} />} />
          <Route path="/home/uploadQueue" render={ (props) => <UploadQueue changeTitle = {changeTitle} {...props} />} />
          <Route path="/home/upload" render={ (props) => <Upload changeTitle = {changeTitle} {...props} />} />
          <Route path="/home/readQueue" render={ (props) => <ReadQueue changeTitle = {changeTitle} {...props} />} />
          <Route path="/home/readFilm" render={ (props) => <ReadFilm changeTitle = {changeTitle} {...props} />} />
          <Route path="/home/readDoneQueue" render={ (props) => <ReadDoneQueue changeTitle = {changeTitle} {...props} />} />
          <Route path="/home/report" render={ (props) => <ExcelExport changeTitle = {changeTitle} {...props} />} />
          <Route path="/home/changePassword" render={ (props) => <ChangePassword changeTitle = {changeTitle} {...props} />} />
          <Route path="/home/readHistory" render={ (props) => <ReadHistory changeTitle = {changeTitle} {...props} />} />
          <Route path="/home/allCenter" render={ (props) => <AllCenter changeTitle = {changeTitle} {...props} />} />
          <Route path="/home/center" render={ (props) => <Center changeTitle = {changeTitle} {...props} />} />
          <Route path="/home/allUser" render={ (props) => <AllUser changeTitle = {changeTitle} {...props} />} />
          <Route path="/home/user" render={ (props) => <User changeTitle = {changeTitle} {...props} />} />
          <Route path="/home/ward" render={ (props) => <Ward changeTitle = {changeTitle} {...props} />} />
          <Route path="/home/service" render={ (props) => <Service changeTitle = {changeTitle} {...props} />} />
          <Route path="/home/editService" render={ (props) => <EditService changeTitle = {changeTitle} {...props} />} />
          <Route path="/home/history" render={ (props) => <History changeTitle = {changeTitle} {...props} />} />
          <Route path="/home/sender" render={ (props) => <Sender changeTitle = {changeTitle} {...props} />} />
          <Route path="/home/reverseCase" render={ (props) => <ReverseCase changeTitle = {changeTitle} {...props} />} />
          <Route path="/home/searchCaseId" render={ (props) => <SearchCaseId changeTitle = {changeTitle} {...props} />} />
          <Route path="/home/searchReceiptId" render={ (props) => <SearchReceiptId changeTitle = {changeTitle} {...props} />} />
          <Route path="/home/deletePatient" render={ (props) => <DeletePatient changeTitle = {changeTitle} {...props} />} />
          <Route path="/home/copyService" render={ (props) => <CopyService changeTitle = {changeTitle} {...props} />} />
          <Route path="/home/reportAllCenter" render={ (props) => <ReportAllCenter changeTitle = {changeTitle} {...props} />} />
          <Route path="/home/reportMed" render={ (props) => <ReportMed changeTitle = {changeTitle} {...props} />} />
          <Route path="/home/editCase" render={ (props) => <EditCase changeTitle = {changeTitle} {...props} />} />
          <Route path="/home/reportDF" render={ (props) => <ReportDF changeTitle = {changeTitle} {...props} />} />
          <Route path="/home/reportDFSummary" render={ (props) => <ReportDFSummary changeTitle = {changeTitle} {...props} />} />
          <Route path="/home/reportCgd" render={ (props) => <ReportCgd changeTitle = {changeTitle} {...props} />} />
          <Route path="/home/doctorTeam" render={ (props) => <DoctorTeam changeTitle = {changeTitle} {...props} />}/> 
          <Route path="/home/lineUserMenu" render={ (props) => <LineUserMenu changeTitle = {changeTitle} {...props} />}/>
      </main>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element),
};

export default class Home extends Component {
  constructor() {
    super();
    this.state = {
      user: '',
    }
    this.renewCookie = this.renewCookie.bind(this)
    this.renewCookieThrottle = _.throttle(this.renewCookie, 25 * 60 * 1000)
  }
  
  componentDidMount() {
    this.setState({ user: JSON.parse(localStorage.getItem('user')) })
    setTimeout(function() {
      this.props.history.push('/logout')
    }.bind(this), 58*60*1000);
  }

  renewCookie () {
    fetch(process.env.REACT_APP_API_URL + '/api/renewToken?user=' + this.state.user.user)
      .then(res => {
        if(!res.ok) {
          throw new Error('Token Renewal Failed');
        } else {
          return res.json();
        }    
      })
      .catch( error => {
        if (error.message === 'Token Renewal Failed') {
          alert('หมดเวลา กรุณาล๊อกอินใหม่') 
          window.location.reload()
        }
      });
  }
  
  render() {
    return (
      <div onClick={ this.renewCookieThrottle } onKeyDown={ this.renewCookieThrottle }>
        <ResponsiveDrawer {...this.props}/>
      </div>
    );
  }
}

function SimpleMenu(props) {
  // office
  return (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={props.anchorEl}
        keepMounted
        open={Boolean(props.anchorEl)}
        // onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => props.buttonClick('/home/changePassword')}>เปลี่ยนพาสเวิร์ด</MenuItem>
      </Menu>
    </div>
  );
}

function EtcMenuDoctor(props) {
  return (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={props.anchorEl}
        keepMounted
        open={Boolean(props.anchorEl)}
        // onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {/* <MenuItem onClick={() => props.buttonClick('/home/reverseCase')}>ย้อนปิดเคส</MenuItem>    */}
        <MenuItem onClick={() => props.buttonClick('/home/searchCaseId')}>ค้นหาเคสจากรหัส</MenuItem>   
        <MenuItem onClick={() => props.buttonClick('/home/lineUserMenu')}>การเชื่อมต่อไลน์</MenuItem>   
        <MenuItem onClick={() => props.buttonClick('/home/changePassword')}>เปลี่ยนพาสเวิร์ด</MenuItem>
      </Menu>
    </div>
  );
}

function EtcMenuUser(props) {
  return (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={props.anchorEl}
        keepMounted
        open={Boolean(props.anchorEl)}
        // onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => props.buttonClick('/home/sender')}>แก้ไขแพทย์ส่ง</MenuItem>      
        <MenuItem onClick={() => props.buttonClick('/home/searchCaseId')}>ค้นหาเคสจากรหัส</MenuItem>    
        <MenuItem onClick={() => props.buttonClick('/home/editCase')}>แก้ไขส่งตรวจ</MenuItem>    
        <MenuItem onClick={() => props.buttonClick('/home/reverseCase')}>ย้อนปิดเคส</MenuItem>     
        <MenuItem onClick={() => props.buttonClick('/home/deletePatient')}>ลบผู้ป่วย</MenuItem>     
        <MenuItem onClick={() => props.buttonClick('/home/lineUserMenu')}>การเชื่อมต่อไลน์</MenuItem>  
        <MenuItem onClick={() => props.buttonClick('/home/changePassword')}>เปลี่ยนพาสเวิร์ด</MenuItem>  
      </Menu>
    </div>
  );
}

function EtcMenuAdmin(props) {
  return (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={props.anchorEl}
        keepMounted
        open={Boolean(props.anchorEl)}
        // onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => props.buttonClick('/home/copyService')}>คัดลอกรายการตรวจ</MenuItem>      
        <MenuItem onClick={() => props.buttonClick('/home/changePassword')}>เปลี่ยนพาสเวิร์ด</MenuItem> 
        {/* <MenuItem onClick={() => props.buttonClick('/home/searchReceiptId')}>ค้นหาใบเสร็จ</MenuItem>    */} 
      </Menu>
    </div>
  );
}

function MenuListItem(props) {

  return (
    <a href={props.link} style={{textDecoration: "inherit", color: "inherit"}}>
      <ListItem button key={props.label} onClick={() => props.buttonClick(props.link)}>
        <ListItemIcon style={{...cssIcon}}>
          {props.children}
        </ListItemIcon>
        <ListItemText primary={props.label} />
      </ListItem>
     </a>
  )
}