import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import queryString from 'query-string';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router';

export default function LineGroupRegister(props) {
  const [user, setUser] = useState()
  const [xrayCenter, setXrayCenter] = useState()
  const [groupId, setGroupId] = useState()
  const [lineId, setLineId] = useState()
  const history = useHistory()

  useEffect(() => {
    const query = queryString.parse(props.location.search);       
    if (query.groupId) {
      setGroupId(query.groupId)
    }
    if (query.lineId) {
        setLineId(query.lineId)
    }
    const user = JSON.parse(localStorage.getItem('user'))
    if (user.role !== "user") {
      setUser()
      return
    }
    setUser(user)

    const xrayCenter = JSON.parse(localStorage.getItem('xrayCenter'))
    setXrayCenter(xrayCenter)

  }, [])

  function connectAccount() {
    const data = {
      userId: user.id,
      lineId: lineId,
      lineGroupId: groupId,
      xrayCenterId: xrayCenter._id
    }
    fetch( process.env.REACT_APP_API_URL + '/api/line/groupRegister', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(res => res.json())
    .then ( ( res ) => {
      if(res.ok){
          history.push('/line/registerDone')
      } else {
          alert(res.reason)
      }
    })
  }

  return (

        <Grid container  direction="column" justify="center" alignItems="center" spacing={3} style={{ minHeight: '100vh' }}>
          <React.Fragment>
            { groupId && lineId && user ?
              <React.Fragment>
                <Grid item md={4} xs={12}>
                  <Typography align="center" style={{marginTop: "2rem"}}>
                    คุณกำลังเชื่อมต่อ
                  </Typography>
                  <Box textAlign="center" style={{margin: "2rem 0"}} fontWeight="fontWeightBold">
                    ไลน์กลุ่มของ<br/>{xrayCenter.name}
                  </Box>
                  <Typography align="center">
                      เข้ากับระบบ Perfect-Med<br/>
                    กดปุ่มด้านล่างเพื่อดำเนินการต่อ
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Button variant="contained" color="primary" onClick={connectAccount}>
                    เชื่อมต่อแอคเคาท์
                  </Button>
                </Grid>
              </React.Fragment>
              :
              <Grid item md={4} xs={12}>
                <Typography align="center" color="error" gutterBottom>
                  เกิดข้อผิดพลาด (เจ้าหน้าที่ศูนย์เท่านั้นที่จะสามารถลงทะเบียนไลน์กลุ่มได้)
                </Typography>
                <Typography align="center">
                  กรุณาปิดหน้าต่างนี้แล้วลองใหม่อีกครั้ง
                </Typography>
              </Grid>
            }
            <Grid item md={12} xs={12}>
                <a style={{textDecoration: 'underline', color: 'gray', width: '100%'}} onClick={()=>history.push('/logout')}>
                  ออกจากระบบ
                </a>
            </Grid>
          </React.Fragment>
        </Grid>

  );
}