import React, {Component} from 'react';
import moment from 'moment';
import _ from 'lodash'

import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import NavigationIcon from '@material-ui/icons/Navigation';
import EditIcon from '@material-ui/icons/Edit';
import HistoryIcon from '@material-ui/icons/History';
import { toBuddhistYear } from './service/utils';

const cssFab = {
    marginRight: "1rem"
}


export default class SearchPatient extends Component {
    constructor() {
        super();
        this.state = {
            xrayCenterId: '',
            keyword: '',
            patients: [],
        }

        this.query = _.debounce(this.query, 500)
    }

    componentDidMount() {
        this.props.changeTitle('ค้นหาผู้ป่วย')
        document.title = 'ค้นหาผู้ป่วย'
        const xrayCenter = JSON.parse(localStorage.getItem('xrayCenter'))
        this.setState({ xrayCenterId: xrayCenter._id })
        
    }

    query (keyword) {
        fetch(process.env.REACT_APP_API_URL + `/api/patientSearch?keyword=${keyword}&xrayCenterId=${this.state.xrayCenterId}` )
        .then(res => res.json())
        .then(x => {
            this.setState({patients: x.data})
        });
    }

    handleSearch = (e) => {
        const keyword = e.target.value
        if (keyword.length > 2) {
            this.query(keyword)
        }
        this.setState({keyword: keyword})
    }

    buttonClick = (e) => {
        this.props.history.push(e)
    }

    render() {
    const state = this.state
      
    return (
        <div>
            <TextField
                id="outlined-full-width"
                label="คีย์เวิร์ด"
                margin="normal"
                placeholder="ชื่อ / นามสกุล / HN / เลขบัตรประชาชน"
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
                value={state.keyword}
                onChange={this.handleSearch}
                variant="outlined"
            />

            { state.patients.length !== 0 && 
                <Paper style={{marginTop: "2rem"}}>
                    <Table style={{minWidth: "650px"}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{maxWidth: "50px"}}>HN</TableCell>
                            <TableCell>ขื่อ-นามสกุล</TableCell>
                            <TableCell align="right">อายุ</TableCell>
                            <TableCell align="center">วันที่สร้าง</TableCell>
                            <TableCell align="center">เมนู</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.patients.map(x => {
                            let fullname = x.fullname
                            const idx = fullname.indexOf(state.keyword)
                            let p1 = ''
                            let p2 = ''
                            let nameSearch = false
                            if (idx > -1) {
                                p1 = fullname.substr(0,idx)
                                p2 = fullname.substr(idx + state.keyword.length)
                                nameSearch = true
                            } else {
                                p1 = fullname
                                p2 = ''
                            }
                            let matchedHn = false
                                if (x.hn)
                                    matchedHn = x.hn.includes(state.keyword);
                            let matchedNationalId = false
                                if (x.nationalId)
                                    matchedNationalId = x.nationalId.includes(state.keyword);

                        
                        return (
                            <TableRow key={x._id}>
                                <TableCell component="th" scope="row">
                                    {x.hn}
                                </TableCell>
                                <TableCell>
                                    {x.title + " "}{p1}<b>{nameSearch ? state.keyword : ''}</b>{p2} 
                                    {x.nationalId && 
                                    <React.Fragment>
                                        <br/>บัตรปชช {x.nationalId}
                                    </React.Fragment>
                                    }
                                </TableCell>
                                <TableCell align="right">{x.age + " " + x.ageUnit}</TableCell>
                                <TableCell align="center">
                                    { toBuddhistYear( moment(x.createDate), 'H:mm')} <br/>
                                    { toBuddhistYear( moment(x.createDate), 'D/M/YYYY')} 
                                </TableCell>
                                <TableCell align="center">
                                    <Fab variant="extended" aria-label="like" size="small" style={cssFab} onClick={() => this.buttonClick('/home/sendXray?id=' + x._id)}>
                                        <NavigationIcon />
                                        ส่งตรวจ
                                    </Fab>
                                    <Fab variant="extended" aria-label="like" size="small" style={cssFab} onClick={() => this.buttonClick('/home/history?id=' + x._id)}>
                                        <HistoryIcon />
                                        ประวัติ
                                    </Fab>
                                    <Fab variant="extended" aria-label="like" size="small" style={cssFab} onClick={() => this.buttonClick('/home/patient?id=' + x._id)}>
                                        <EditIcon />
                                        แก้ไข
                                    </Fab>
                                </TableCell>
                            </TableRow>
                        )})}
                    </TableBody>
                    </Table>
                </Paper>
            }
        </div>   
    );
  }
}
