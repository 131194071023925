import React, {Component} from 'react';
import moment from 'moment';
import { formatNumber } from './service/utils';
import DateFnsUtils from '@date-io/date-fns';
import {  MuiPickersUtilsProvider,  KeyboardDatePicker, } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DownloadExcelButton from './component/DownloadExcelButton';

export default class ReportAllCenter extends Component {
	constructor(props) {
		super(props);
		this.state = {
            data: '', 
            from: moment().format("YYYY-MM-DD"),
            to:  moment().format("YYYY-MM-DD"),
            user: ''
		};
		
    };
    
    componentDidMount(){
        this.props.changeTitle('รายงานรวม')
        document.title = 'รายงานรวม'

        const user = JSON.parse(localStorage.getItem('user'))
        this.setState({ user: user })
        
    }

	exportFile = () => {
        const state = this.state
        const from = moment(state.from).format('YYYY-MM-DD')
        const to = moment(state.to).add(1,'days').format('YYYY-MM-DD')
        
        if ( parseInt( moment(to).diff(moment(from), 'days' )) > 66 ) {
            alert('ไม่สามารถออกรายงานที่มากกว่า 60 วันได้ กรุณาลดจำนวนวัน')
            return
        }

        fetch(process.env.REACT_APP_API_URL + `/api/report/allCenter?from=${from}&to=${to}`)
        .then(res => res.json())
        .then(x => {
            let data = x.data
            data.sort( (a,b) => {
                return a.xrayCenter.localeCompare(b.xrayCenter, 'th')
            } )
            data = data.map (x => {
                x.priceCTTotal = x.priceCT + x.priceCTMed + x.priceCTEtc
                x.priceMRITotal = x.priceMRI + x.priceMRIMed + x.priceMRIEtc
                x.undoneCase = x.caseTotal-x.caseDone
                return x
            })
            this.setState({data: x.data})
        })
    }
    

	render() { 
        const state = this.state
        let s_caseCT = 0, s_priceCT = 0, s_priceCTMed = 0, s_priceCTEtc = 0, s_caseMRI = 0, s_priceMRI = 0, s_priceMRIMed = 0, s_priceMRIEtc = 0, s_dfSender = 0, s_dfDoctor = 0, s_undoneCase = 0
        return (
            <div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div style={{marginRight: '2rem'}}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            label="ตั้งแต่วันที่"
                            value={state.from}
                            onChange={(e) => this.setState({from: e})}
                            KeyboardButtonProps={{
                            'aria-label': 'change date',
                            }}
                        />
                    </div>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        label="ถึงวันที่"
                        value={state.to}
                        onChange={(e) => this.setState({to: e})}
                        KeyboardButtonProps={{
                        'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
                <Button style={{ margin: '1rem 0 0 0', display: "block" }} variant="contained" color="primary" onClick={this.exportFile}>ดึงข้อมูล</Button>
                <DownloadExcelButton header={['ชื่อศูนย์','ผู้ป่วย CT','ตรวจ CT','ยา CT','อื่นๆ CT', 'รวม CT',
                                                'ผู้ป่วย MRI','ตรวจ MRI','ยา MRI','อื่นๆ MRI','รวม MRI',
                                                'DFส่ง','DFรังสี','เคสไม่ปิด']} 
                    dataDefinition={['xrayCenter', 'caseCT', 'priceCT', 'priceCTMed', 'priceCTEtc', 'priceCTTotal',
                                                'caseMRI', 'priceMRI', 'priceMRIMed', 'priceMRIEtc', 'priceMRITotal',
                                                'dfSender', 'dfDoctor', 'undoneCase' ]} 
                    data={state.data} filename={`ReportAllCenter`}/>
                {state.data && 
                    <Paper style={{marginTop: "2rem"}}>
                        <Table style={{minWidth: "650px"}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">ชื่อศูนย์</TableCell>
                                <TableCell align="center">ผู้ป่วย CT</TableCell>
                                <TableCell align="center">ตรวจ CT</TableCell>
                                <TableCell align="center">ยา CT</TableCell>
                                <TableCell align="center">อื่นๆ CT</TableCell>
                                <TableCell align="center"><b>รวม CT</b></TableCell>
                                <TableCell align="center">ผู้ป่วย MRI</TableCell>
                                <TableCell align="center">ตรวจ MRI</TableCell>
                                <TableCell align="center">ยา MRI</TableCell>
                                <TableCell align="center">อื่นๆ MRI</TableCell>
                                <TableCell align="center"><b>รวม MRI</b></TableCell>
                                <TableCell align="center">DFส่ง</TableCell>
                                <TableCell align="center">DFรังสี</TableCell>
                                <TableCell align="center">เคสไม่ปิด</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {state.data.map(x => {
                                s_caseCT += x.caseCT
                                s_priceCT += x.priceCT
                                s_priceCTMed += x.priceCTMed
                                s_priceCTEtc += x.priceCTEtc
                                s_caseMRI += x.caseMRI
                                s_priceMRI += x.priceMRI
                                s_priceMRIMed += x.priceMRIMed
                                s_priceMRIEtc += x.priceMRIEtc
                                s_dfSender += x.dfSender
                                s_dfDoctor += x.dfDoctor
                                s_undoneCase += x.undoneCase > 0 ? x.undoneCase : 0
                                return (
                                    <TableRow key={x.xrayCenterId}>
                                        <TableCell>{x.xrayCenter}</TableCell>
                                        <TableCell align="right">{x.caseCT}</TableCell>
                                        <TableCell align="right">{formatNumber(x.priceCT)}</TableCell>
                                        <TableCell align="right">{formatNumber(x.priceCTMed)}</TableCell>
                                        <TableCell align="right">{formatNumber(x.priceCTEtc)}</TableCell>
                                        <TableCell align="right"><b>{formatNumber(x.priceCTTotal)}</b></TableCell>
                                        <TableCell align="right">{x.caseMRI}</TableCell>
                                        <TableCell align="right">{formatNumber(x.priceMRI)}</TableCell>
                                        <TableCell align="right">{formatNumber(x.priceMRIMed)}</TableCell>
                                        <TableCell align="right">{formatNumber(x.priceMRIEtc)}</TableCell>
                                        <TableCell align="right"><b>{formatNumber(x.priceMRITotal)}</b></TableCell>
                                        <TableCell align="right">{formatNumber(x.dfSender)}</TableCell>
                                        <TableCell align="right">{formatNumber(x.dfDoctor)}</TableCell>
                                        <TableCell align="right">
                                            {x.undoneCase > 0 ? <div style={{fontWeight: "bold", color: "red"}}>{x.undoneCase}</div> : '-'}
                                        </TableCell>
                                    </TableRow>
                                )})
                            }
                            <TableRow style={{textDecoration: "underline"}}>
                                <TableCell>รวม</TableCell>
                                <TableCell align="right">{s_caseCT}</TableCell>
                                <TableCell align="right">{formatNumber(s_priceCT)}</TableCell>
                                <TableCell align="right">{formatNumber(s_priceCTMed)}</TableCell>
                                <TableCell align="right">{formatNumber(s_priceCTEtc)}</TableCell>
                                <TableCell align="right"><b>{formatNumber(s_priceCT + s_priceCTMed + s_priceCTEtc)}</b></TableCell>
                                <TableCell align="right">{s_caseMRI}</TableCell>
                                <TableCell align="right">{formatNumber(s_priceMRI)}</TableCell>
                                <TableCell align="right">{formatNumber(s_priceMRIMed)}</TableCell>
                                <TableCell align="right">{formatNumber(s_priceMRIEtc)}</TableCell>
                                <TableCell align="right"><b>{formatNumber(s_priceMRI + s_priceMRIMed + s_priceMRIEtc)}</b></TableCell>
                                <TableCell align="right">{formatNumber(s_dfSender)}</TableCell>
                                <TableCell align="right">{formatNumber(s_dfDoctor)}</TableCell>
                                <TableCell align="right">{s_undoneCase}</TableCell>
                            </TableRow>
                        </TableBody>
                        </Table>
                    </Paper>
                }
            </div>

            
        );      
    };
};
