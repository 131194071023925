import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AutoComplete from './component/AutoComplete';
import EditDoctorTeamMemberTable from './EditDoctorTeamMemberTable';

export default function EditDoctorTeam({ open, doctorTeam, onClose }) {
    const [name, setName] = useState('')
    const [doctors, setDoctors] = useState([])
    const [teamId, setTeamId] = useState()

    useEffect(() => {
        if (!doctorTeam) {
            setName('')
            setDoctors([])
            return
        }            
        setTeamId(doctorTeam._id)
        setName(doctorTeam.teamName)
        let newDoctors = doctorTeam.doctorInfo.map(d => {
            return {_id: d._id, name: `${d.title} ${d.firstname} ${d.lastname}`}
        })
        setDoctors(newDoctors)
    }, [doctorTeam])

    const handleDoctorSelect = (x) => {
        // check if doctor 'x' has already been part of the team
        const index = doctors.findIndex( d => d._id === x._id)
        if (index > -1) {
            alert('รังสีแพทย์ที่เลือกอยู่ในทีมแล้ว')
            return
        }

        let newDoctor = {
            _id: x._id,
            name: x.label
        }
        setDoctors([...doctors, newDoctor])

    }

    const handleSubmit = () => {
        if (!name || name === '') {
            alert('กรุณาใส่ชื่อทีมรังสีแพทย์')
            return
        }
        if (doctors.length === 0) {
            alert('กรุณาเพิ่มรังสีแพทย์ในทีม')
            return
        }
        let newDoctors = []
        doctors.map((d) => {
            newDoctors.push({userId: d._id})
            return d
        })

        let data = {
            teamName: name,
            doctors: newDoctors,
            active: true,
        }

        let method = 'POST'
        if (doctorTeam) {
            method = 'PATCH'
            data._id = teamId
        }
        fetch( process.env.REACT_APP_API_URL + '/api/doctorTeam', {
            method: method,
            body: JSON.stringify(data),
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(res => res.json())
          .then ( ( x ) => {
            if(x.updated || x.ok) {
                onClose()
            } else {
                alert('เกิดข้อผิดพลาด')
            }
          })
    }

    const handleRemoveDoctor = (id) => {
        let newDoctors = doctors.filter( (x) => {
            return x._id !== id 
        })
        setDoctors(newDoctors)
    }

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
            <div style={{ minWidth: "500px", height: "0" }} />
            <DialogTitle id="form-dialog-title">{ !doctorTeam ? 'สร้างทีมรังสีแพทย์ใหม่' : 'แก้ไขทีมรังสีแพทย์'}</DialogTitle>
            <DialogContent style={{marginTop: "-1.5rem"}}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="ขื่อทีมรังสีแพทย์"
                    type="text"
                    fullWidth
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    style={{marginBottom: "1rem"}}
                />
                <DialogContentText style={{marginBottom: "-0.3rem"}}>
                    เพิ่มรังสีแพทย์
                </DialogContentText>
                <AutoComplete endPoint={"/api/line/allDoctor"} onChange={handleDoctorSelect} width="100%" style={{marginBottom: "1rem"}} autoClear={true} />
                <DialogContentText style={{marginBottom: "-0.3rem"}}>
                    {doctors.length === 0 ? 'ไม่มีรังสีแพทย์ในทีม' : 'รายชื่อรังสีแพทย์ในทีม'}
                </DialogContentText>
                { doctors.length > 0 &&
                    <EditDoctorTeamMemberTable doctors={doctors} onDelete={handleRemoveDoctor}/>
                }

            </DialogContent>

            <DialogActions>
                <Button onClick={onClose} color="primary">
                    ยกเลิก
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    บันทึก
                </Button>
            </DialogActions>
        </Dialog>
    );
}

