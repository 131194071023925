import { Component } from 'react';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export default class Logout extends Component {

    componentDidMount () {
        localStorage.removeItem('user');
        localStorage.removeItem('xrayCenter');
        fetch(process.env.REACT_APP_API_URL + '/api/logout?user=1234')
        .then(res => res.json())
        .then(x => {
          window.location.reload()         
        })
    }

  render() {
    return (
    <Route>
        <Redirect to={{ pathname: '/login' }} />
    </Route>);
  }
}
