import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getDateString } from './service/utils';

const useStyles = makeStyles(theme => ({
    p: {
        marginLeft: '2rem'
    }
}));


export default function LineUserMenu (props) {
    const [user, setUser] = useState()
    const [lineUser, setLineUser] = useState()
    const classes = useStyles();

    useEffect(() => {
        props.changeTitle('การเชื่อมต่อไลน์')
        setUser(JSON.parse(localStorage.getItem('user')))
    }, [])

    useEffect(() => {
        if (!user)
            return

        fetch(`${process.env.REACT_APP_API_URL}/api/line/checkUser?userId=${user.id}`)
        .then(res => res.json())
        .then(x => {
            if (x.ok) {
                setLineUser(x.data)
            }
        });

    }, [user])

    return (
        <div>
            <h3>สถานะการเชื่อมต่อไลน์</h3>
            <p className={classes.p}>
                {lineUser ? `เชื่อมต่อไลน์แล้วเมื่อวันที่ ${getDateString( new Date(lineUser.date), true)}` :  'ยังไม่ได้เชื่อมต่อ' }
            </p>
        </div>
    );
}

