import React, {Component} from 'react';
import moment from 'moment';
import _ from 'lodash'
import { formatNumber, toBuddhistYear } from './service/utils';
import queryString from 'query-string';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

export default class ReportDFPrint extends Component {
	constructor(props) {
		super(props);
		this.state = {
            from: moment().format("YYYY-MM-DD"),
            to:  moment().format("YYYY-MM-DD"),
            doctor: '',
            data: '',
		};
    };
    
    componentDidMount(){
        const query = queryString.parse(this.props.location.search); 
        this.setState({ from: query.from, to: query.to })
        this.loadDfData(query.from, query.to, query.doctor)
        this.loadDoctorData(query.doctor)
    }

    loadDoctorData = (doctorId) => {
        fetch(process.env.REACT_APP_API_URL + '/api/userById?_id=' + doctorId)
        .then(res => res.json())
        .then(x => {
            const user = x.data
            this.setState({ doctor: user })
        });
    }

    loadDfData = (from, to, doctor) => {
        
        if ( parseInt( moment(to).diff(moment(from), 'days' )) > 35 ) {
            alert('ไม่สามารถออกรายงานที่มากกว่า 30 วันได้ กรุณาลดจำนวนวัน')
            return
        }

        if (!doctor) {
            alert('กรุณาระบุรังสีแพทย์')
            return
        }

        fetch(`${process.env.REACT_APP_API_URL}/api/orderByDate?isDeleted=false&from=${from}&to=${to}&doctorId=${doctor}`)
        .then(res => res.json())
        .then(x => {
            let data = x.data
            if (data.length === 0) {
                alert('ไม่พบข้อมูล')
                return
            }
            const anOrder = data[0]
            data = _.chain(data).groupBy("xrayCenter").map((value, key) => ({ xrayCenter: key, orders: value })).value()
            data.map( x => {
                x.cases = x.orders.length
                let totalDF = 0
                x.orders.map( o => {
                    o.option.map( oo => {
                        totalDF += oo.dfDoctor
                        return oo
                    })
                    return o
                })
                x.totalDF = totalDF
                delete x.orders
                return x
            })
            document.title = anOrder.doctor
            this.setState({data: data})
        })

    }


	render() { 
        const state = this.state
        const doctor = state.doctor
        let s_case = 0
        let s_df = 0
        return (
            <div>
                {state.data && <div>
                    <h2>รายงาน DF รังสีแพทย์ {doctor && `${doctor.title} ${doctor.firstname} ${doctor.lastname}`}</h2>
                    <p>ระหว่างวันที่ { toBuddhistYear( moment(state.from), "D/M/YYYY")} ถึงวันที่ { toBuddhistYear( moment(state.to).subtract(1, "days"), "D/M/YYYY")}</p>
                    <p>ข้อมูลบัญชี: {doctor && state.doctor.bankInfo ? state.doctor.bankInfo : '(ยังไม่ได้อัพเดท)'}</p>
                    <Paper style={{marginTop: "2rem", maxWidth: "800px"}}>
                        <Table style={{minWidth: "650px"}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">ศูนย์</TableCell>
                                <TableCell align="center">จำนวนเคส</TableCell>
                                <TableCell align="center">รวม DF รังสี</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {state.data.map( (x,key) => {
                                s_case += x.cases
                                s_df += x.totalDF
                                return (
                                    <TableRow key={key}>
                                        <TableCell>{x.xrayCenter}</TableCell>
                                        <TableCell align="right">{x.cases}</TableCell>
                                        <TableCell align="right">{formatNumber(x.totalDF.toFixed(2))}</TableCell>
                                    </TableRow>
                                )})
                            }
                            <TableRow>
                                <TableCell><b>รวมค่า DF รังสีแพทย์</b></TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"><b>{formatNumber(s_df.toFixed(2))}</b></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>หักภาษี ณ ที่จ่าย</TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right">{formatNumber((s_df * -0.03).toFixed(2))}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><b>ยอดเงินโอน</b></TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"><b>{formatNumber((s_df * 0.97).toFixed(2))}</b></TableCell>
                            </TableRow>
                        </TableBody>
                        </Table>
                    </Paper>
                </div>}
            </div>
        );      
    };
};
