import React, {Component} from 'react';
import moment from 'moment';
import { urgency2Color, toBuddhistYear } from './service/utils';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import PhotoIcon from '@material-ui/icons/Photo';
import Chip from '@material-ui/core/Chip';
import HistoryIcon from '@material-ui/icons/History';


const cssFab = {
    marginRight: "1rem"
}


export default class ReadQueue extends Component {
    constructor() {
        super();    
        this.state = {
            queues: [],
            user: ''
        }
    }

    componentDidMount() {
        document.title = 'คิวอ่านภาพ'
        const user = JSON.parse(localStorage.getItem('user'))
        this.props.changeTitle('คิวอ่านภาพ (รังสีแพทย์' + user.firstname + ')')
        this.setState({ user: user })
        fetch(process.env.REACT_APP_API_URL + '/api/orderByDoctor?doctorId=' + user.id + '&isDiag=false&isUploaded=true&isDeleted=false' )
        .then(res => res.json())
        .then(x => {
            this.setState({queues: x.data})
        });
    }

    
    buttonClick = (id) => {
        const data = {
            _id: id,
            isDoctorRead: true,
            doctorReadDate:  new Date().toISOString()
        }
        fetch( process.env.REACT_APP_API_URL + '/api/order/setIsDiag', {
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
            })
            .then(res => res.json())
            .then ( ( x ) => {
            if(x.updated){
                this.props.history.push('/home/readFilm?id=' + id)
            } else {
                alert('เกิดข้อผิดพลาด')
            }
            })
    }

    openHistory = (id) => {
        window.open('/home/history?id=' + id)
    }

    render() {
    const state = this.state

    return (
        <div>
            { state.queues && state.queues.length > 0 && 
                <Paper style={{marginTop: "2rem", maxWidth: "1000px"}}>
                    <Table style={{minWidth: "650px"}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" style={{paddingLeft: "2.1rem"}}>ความด่วน</TableCell>
                            <TableCell align="center">ผู้ป่วย</TableCell>
                            <TableCell align="center">รายการตรวจ</TableCell>
                            <TableCell align="center">วันที่ตรวจ</TableCell>
                            <TableCell align="center">วันรับผล</TableCell>
                            <TableCell align="center">เมนู</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.queues.map(x => {
                            let centerName = x.xrayCenter
                            centerName = 'ศูนย์' + centerName.substr(centerName.indexOf(" ") + 1, centerName.length)
                            const date = moment(x.createDate)
                            return (
                                <TableRow key={x._id}>
                                    <TableCell component="th" scope="row" align="left">
                                        <Chip label={x.urgency} style={{marginLeft: "1rem", backgroundColor: `${urgency2Color(x.urgency)}`}}/>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{display: "block"}}>{x.patient}</div>
                                        <div style={{display: "block", fontSize: "0.8rem", color: "grey"}}>{centerName}</div>
                                    </TableCell>
                                    <TableCell>
                                        {x.option.map( (o, idx) => {
                                            return <div key={o._id} style={{display: "block"}}>{o.serviceName}</div>
                                        })}
                                    </TableCell>
                                    <TableCell align="center">
                                        <div style={{display: "block"}}>{date.format("H:mm")}</div>
                                        <div style={{display: "block"}}>{ toBuddhistYear( date, "D/M/YYYY")}</div>
                                    </TableCell>
                                    <TableCell align="center">
                                        <div style={{display: "block"}}> 
                                            {x.expectReportDate ? toBuddhistYear( moment(x.expectReportDate), "D/M/YYYY") : '-'}
                                        </div>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Fab color="primary" variant="extended" aria-label="like" size="small" style={cssFab} onClick={() => this.buttonClick(x._id)}>
                                            อ่านภาพ
                                        </Fab>
                                        <Fab variant="extended" aria-label="like" size="small" style={cssFab} onClick={() => this.openHistory(x.patientId)}>
                                            <HistoryIcon />
                                            ประวัติ
                                        </Fab>
                                    </TableCell>
                                </TableRow>
                            )})}
                    </TableBody>
                    </Table>
                </Paper>
            }
            { state.queues && state.queues.length === 0 && <h2>ไม่มีภาพรออ่านในคิว</h2> }
        </div>   
    );
  }
}
