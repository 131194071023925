import React, {Component} from 'react';

import Button from '@material-ui/core/Button';
import XrayCenterSelect from './component/XrayCenterSelect';
import TextField from '@material-ui/core/TextField';

const cssTopic = {
    display: 'inline-block',
    marginLeft: '2rem',
    width: '100px'
}
const cssTopicChoice = {
    display: 'inline-block',
    margin: '0 0 1rem 4rem ',
    width: '400px'
}

export default class CopyService extends Component {
    constructor() {
        super();
        this.state = {
            xrayCenter: [],
            from: '',
            to: '',
            serviceSubGroup: ''
       
        }
    }    

    

    componentDidMount() {
        fetch(process.env.REACT_APP_API_URL + `/api/xrayCenter` )
        .then(res => res.json())
        .then(x => {
            let xrayCenter = x.data
            this.setState({xrayCenter: xrayCenter})
        });
        this.props.changeTitle( 'คัดลอกรายการตรวจ' )
        document.title = 'คัดลอกรายการตรวจ'
    }


    onSubmit = () => {
        const state = this.state
        if ( !state.from || !state.to ) {
            alert('กรุณาเลือก จากศูนย์-ไปศูนย์')
            return
        }
        const data = {
            from: state.from,
            to: state.to,
            serviceSubGroup: state.serviceSubGroup
        }
        fetch( process.env.REACT_APP_API_URL + '/api/service/copyFromXrayCenter', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(res => res.json())
          .then ( ( x ) => {
            if(x.ok){
                alert('คัดลอกเรียบร้อย')
                this.props.history.push('/home/service?id=' + state.to)
            } else {
                alert('เกิดข้อผิดพลาด')
                window.reload()
            }
          })
    }

    render() {
    const state = this.state
    return (
        <div>
            {state.xrayCenter && <div>
                <div style={{ display: "flex", height: "4rem" , verticalAlign: "top"}}>
                    <div style={{...cssTopic, height: "4rem"}}><div style={{display: "block", marginTop: "1rem"}}>จากศูนย์</div></div>
                    <div style={cssTopicChoice}>
                    <XrayCenterSelect list={state.xrayCenter} handleSeleted={(e) => this.setState({ from: e.target.value })} value={state.from}/> 
                    </div>
                </div>
                <div style={{ display: "flex", height: "4rem" , verticalAlign: "top"}}>
                    <div style={{...cssTopic, height: "4rem"}}><div style={{display: "block", marginTop: "1rem"}}>ไปยังศูนย์</div></div>
                    <div style={cssTopicChoice}>
                    <XrayCenterSelect list={state.xrayCenter} handleSeleted={(e) => this.setState({ to: e.target.value })} value={state.to}/> 
                    </div>
                </div>
                <div style={{ display: "flex", height: "4rem" , verticalAlign: "top"}}>
                    <div style={{...cssTopic, height: "4rem"}}><div style={{display: "block", marginTop: "1rem"}}>ประเภทย่อย</div></div>
                    <div style={cssTopicChoice}>
                        <TextField
                            style={{backgroundColor: "white"}}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={state.serviceSubGroup}
                            onChange={ (e) => this.setState({ serviceSubGroup:  e.target.value })}
                        />
                    </div>
                </div>
                <Button style={{ margin: "1rem 0 0 2rem" }} variant="contained" color="primary" onClick={this.onSubmit}>บันทึก</Button>
            </div>}
            
        </div>    
    );
  }
}