import React, {Component} from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const cssTopic = {
    display: 'inline-block',
    marginLeft: '2rem',
    width: '100px'
}

const cssTopicChoice = {
    display: 'inline-block',
    margin: '0 0 1rem 4rem ',
    width: '400px'
}

export default class SearchReceiptId extends Component {
    constructor() {
        super();
        this.state = {
            receiptId: '',
            user: ''
        }
    }

    componentDidMount() {
        this.props.changeTitle('ค้นหาใบเสร็จ')
        document.title = 'ค้นหาใบเสร็จ'        
        let user = JSON.parse(localStorage.getItem('user'))
        this.setState({user: user})
    }

    fetchOrder = () => {
        fetch(process.env.REACT_APP_API_URL + '/api/orderByReceiptId?receiptId=' + this.state.receiptId)
        .then(res => res.json())
        .then(x => {
            if (x.ok) {
                let order = x.data
                if (order.length < 1) {
                    alert('รหัสใบเสร็จไม่ถูกต้อง')
                    this.setState({ receiptId: '' })
                    return
                } 
                if (order.length > 1) {
                   alert('เกิดข้อผิดพลาดกรุณาติดต่อแอดมิน ("รหัสใบเสร็จซ้ำ")')
                   return
                } 
                
                order = order[0]
                if (order.isDeleted) {
                    alert('เคสนี้ได้ถูกลบไปแล้ว')
                    this.setState({ receiptId: '' })
                    return
                }
                window.open('/receipt?id=' + order._id)
                
            } else {
                alert('รหัสใบเสร็จไม่ถูกต้อง')
                this.setState({ receiptId: '' })
            }
        });
    }

    render() {
    const state = this.state
      
    return (
        <div>
            <div style={{ verticalAlign: "top", marginBottom: "1rem" }}>
                <div style={{ display: "flex" }}>
                    <div style={{...cssTopic, height: "4rem"}}><div style={{display: "block", marginTop: "1rem"}}>เลขที่ใบเสร็จ</div></div>
                    <div style={cssTopicChoice}>
                        <TextField
                            style={{backgroundColor: "white"}}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={state.receiptId}
                            onChange={ (e) => this.setState({ receiptId: e.target.value }) }
                        />
                    </div>
                </div>
                <Button variant="contained" color="primary" onClick={ this.fetchOrder } style={{margin: "0 0 2rem 2rem"}}>ดึงข้อมูล</Button>
            </div>         
        </div>   
    );
  }
}
