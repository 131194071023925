import React, { Component } from 'react';
import moment from 'moment';
import { urgency2Color, toBuddhistYear } from './service/utils';
import queryString from 'query-string';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import OrderStatusTag from './component/OrderStatusTag';
import Fab from '@material-ui/core/Fab';
import ReceiptIcon from '@material-ui/icons/Receipt';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import { Link } from 'react-router-dom';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';

export default class AllQueue extends Component {
    constructor() {
        super();
        this.state = {
            xrayCenterId: '',
            queues: [],
            page: 0,
            totalQueues: 0,
            filterUndone: false
        }
    }

    componentDidMount() {
        this.props.changeTitle('สถานะคิวงานทั้งหมด')
        document.title = 'สถานะคิวงานทั้งหมด'
        const xrayCenter = JSON.parse(localStorage.getItem('xrayCenter'))

        let page = 0
        const query = queryString.parse(this.props.location.search);
        if (query.page) {
            page = parseInt(query.page)
        }

        this.setState({ xrayCenterId: xrayCenter._id })
        this.fetchQueues(xrayCenter._id, page)
        this.fetchQueuesCount(xrayCenter._id)
    }

    fetchQueuesCount(xrayCenterId) {
        fetch(`/api/uploadQueueCount?xrayCenterId=${xrayCenterId}&isDeleted=false${this.state.filterUndone ? '&isDone=false' : ''}`)
            .then(res => res.json())
            .then(x => {
                this.setState({ totalQueues: x.data })
            });
    }

    fetchQueues(xrayCenterId, page) {
        fetch(`/api/uploadQueue?xrayCenterId=${xrayCenterId}&skip=${20 * page}&limit=20&isDeleted=false&sort=dateDesc${this.state.filterUndone ? '&isDone=false' : ''}`)
            .then(res => res.json())
            .then(x => {
                this.setState({ queues: x.data, page: page })
            });
    }

    applyFilterUndone = (e) => {
        const newState = {
            ...this.state,
            filterUndone: e.target.checked, page: 0,
            queues: [],
            totalQueues: 0
        }
        this.setState(newState)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.filterUndone !== this.state.filterUndone) {
            this.fetchQueues(this.state.xrayCenterId, 0)
            this.fetchQueuesCount(this.state.xrayCenterId)
        }
    }


    buttonClick = (e) => {
        this.props.history.push(e)
    }

    handleChangePage = (event, newPage) => {
        this.fetchQueues(this.state.xrayCenterId, newPage)
        this.props.history.push(`/home/allQueue?page=${newPage}`)
    };


    render() {
        const state = this.state
        return (
            <div>
                <FormGroup>
                    <FormControlLabel control={<Checkbox checked={state.filterUndone} onChange={this.applyFilterUndone} />} label="แสดงเฉพาะงานค้าง" />
                </FormGroup>
                {state.queues &&
                    <Paper style={{ marginTop: "1rem" }}>
                        <Table style={{ minWidth: "650px" }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" style={{ maxWidth: "50px", lineHeight: '1rem' }}>ความด่วน<br />วันรับผล</TableCell>
                                    <TableCell align="center">ผู้ป่วย</TableCell>
                                    <TableCell align="center">รายการตรวจ</TableCell>
                                    <TableCell align="center">รังสีแพทย์</TableCell>
                                    <TableCell align="center">วันตรวจ</TableCell>
                                    <TableCell align="center">วันแพทย์เปิด</TableCell>
                                    <TableCell align="center">สถานะ</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {state.queues.map(x => {
                                    const date = moment(x.createDate)
                                    let doctorReadDate = ''
                                    if (x.doctorReadDate) {
                                        doctorReadDate = moment(x.doctorReadDate)
                                    }
                                    return (
                                        <TableRow key={x._id} onClick={() => this.buttonClick('/home/readFilm?id=' + x._id)}>
                                            <TableCell align="left" >
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                    <Chip size='small' label={x.urgency} style={{ backgroundColor: `${urgency2Color(x.urgency)}` }} />
                                                    {x.expectReportDate ? toBuddhistYear(moment(x.expectReportDate), "D/M/YYYY") : ''}
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <Link to={'/home/readFilm?id=' + x._id} style={{ textDecoration: 'none', color: 'black' }}>
                                                    <div style={{ display: "block", fontWeight: "bold" }}>{x.patient}</div>
                                                    <div style={{ display: "block" }}>HN {x.hn}</div>
                                                    <div style={{ display: "block" }}>Ward {x.ward}</div>
                                                </Link>
                                            </TableCell>
                                            <TableCell>
                                                {x.option.map((o, idx) => {
                                                    return <div key={o._id} style={{ display: "block" }}>{o.serviceName}</div>
                                                })}
                                            </TableCell>
                                            <TableCell>{x.doctor}</TableCell>
                                            <TableCell align="center">
                                                <div style={{ display: "block" }}>{toBuddhistYear(date, "D/M/YYYY")}</div>
                                                <div style={{ display: "block" }}>{date.format("H:mm")}</div>
                                            </TableCell>
                                            <TableCell align="center">
                                                <div style={{ display: "block" }}>{doctorReadDate && toBuddhistYear(doctorReadDate, "D/M/YYYY")}</div>
                                                <div style={{ display: "block" }}>{doctorReadDate && doctorReadDate.format("H:mm")}</div>
                                            </TableCell>
                                            <TableCell align="center">
                                                <OrderStatusTag order={x} />
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[20]}
                                        rowsPerPage={20}
                                        colSpan={0}
                                        count={state.totalQueues}
                                        page={state.page}
                                        SelectProps={{
                                            inputProps: { 'aria-label': 'rows per page' },
                                            native: true,
                                        }}
                                        onChangePage={this.handleChangePage}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </Paper>
                }
            </div>
        );
    }
}
