import React, { Component } from 'react';
import { Route, BrowserRouter } from 'react-router-dom';
import withAuth from './service/withAuth';
import Home from './Home';
import Secret from './Secret';
import Login from './Login';
import Logout from './Logout'
import ReportDiag from './ReportDiag';
import Receipt from './Receipt';
import ReportDFPrint from './ReportDFPrint';
import LineRegister from './LineRegister';
import LineGroupRegister from './LineGroupRegister';
import LineRegisterDone from './LineRegisterDone';

class App extends Component {
  render() {
    return (
        <BrowserRouter>
          <Route path="/line/register" component={withAuth(LineRegister)} />
          <Route path="/line/groupRegister" component={withAuth(LineGroupRegister)} />
          <Route path="/line/registerDone" component={withAuth(LineRegisterDone)} />
          <Route path="/secret" component={withAuth(Secret)} />
          <Route path="/login" component={Login} />
          <Route path="/logout" component={Logout} />
          <Route path="/home" component={withAuth(Home)} />
          <Route exact path="/" component={Login} />
          <Route path="/reportDiag"  component={withAuth(ReportDiag)} />
          <Route path="/receipt"  component={withAuth(Receipt)} />
          <Route path="/reportDFPrint" component={withAuth(ReportDFPrint)} />
        </BrowserRouter>
    );
  }
}

export default App;
        // /* <ul>
        //   <li><Link to="/">Home</Link></li>
        //   <li><Link to="/secret">Secret</Link></li>
        //   <li><Link to="/login">Login</Link></li>
        // </ul> */