import React, { Component } from 'react';
import queryString from 'query-string';
import moment from 'moment';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import PhotoIcon from '@material-ui/icons/Photo';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import { urgency2Color, sortAttachment, toBuddhistYear } from './service/utils';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import UndoIcon from '@material-ui/icons/Undo';
import PrintIcon from '@material-ui/icons/Print';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ReceiptIcon from '@material-ui/icons/Receipt';

import Fab from '@material-ui/core/Fab';
import Memo from './component/Memo';

const cssFab = {
    marginRight: "1rem"
}

const cssRow = {
    verticalAlign: "top",
    marginTop: "0.5rem"
}

const cssTopic = {
    display: 'inline-block',
    width: '100px',
    verticalAlign: "top"
}
const cssTopicChoice = {
    display: 'inline-block'
}

const cssFileLink = {
    color: "black",
    "&:visited": {
        color: "black"
    }
}

export default class ReadFilm extends Component {
    constructor() {
        super();
        this.state = {
            order: '',
            user: '',
            oldDiag: '',
        }
    }


    componentDidMount() {
        const query = queryString.parse(this.props.location.search);
        let user = JSON.parse(localStorage.getItem('user'))
        let title = 'อ่านภาพ'

        title = (user.role === 'user' ? 'ผล' : '') + title
        document.title = title

        fetch(process.env.REACT_APP_API_URL + '/api/orderById?id=' + query.id)
            .then(res => res.json())
            .then(x => {
                let order = x.data
                let oldDiag = ''
                if (order.isReverseCase) {
                    oldDiag = order.diag
                    order.diag = ''
                }
                this.setState({ order: order, oldDiag: oldDiag })
                this.props.changeTitle(title + ' (' + order.patient + ')')
            });

        this.setState({ user: user })
    }

    onLoaded = res => {
        const state = this.state
        fetch(process.env.REACT_APP_API_URL + '/api/orderById?id=' + state.order._id)
            .then(res => res.json())
            .then(x => {
                const order = x.data
                this.setState({ order: order })
            });
    };

    deleteClick = (id) => {
        fetch(process.env.REACT_APP_API_URL + '/api/upload/' + id, {
            method: 'DELETE',
        })
            .then(res => res.json())
            .then((x) => {
                if (x.deleted) {
                    this.setState({ order: x.data })
                } else {
                    alert('เกิดข้อผิดพลาดในการลบ')
                }
            })

    }

    editDiagText = (e) => {
        const order = this.state.order
        order.diag = e.target.value
        this.setState({ order: order })
    }

    onSubmit = (isCommitDiag = false) => {
        const state = this.state
        let data = {
            _id: state.order._id,
            diag: state.order.diag
        }
        if (state.order.isReverseCase) {
            const newDiag = state.order.diag
            data.diag = state.oldDiag + `\n\n << แก้ไขผลเมื่อวันที่ ${toBuddhistYear(moment(), "D/M/YYYY H:mm")} >>\n\n` + newDiag
        }
        fetch(process.env.REACT_APP_API_URL + '/api/order/editDiag', {
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((x) => {
                if (x.updated) {
                    this.setState({ order: x.data })
                    if (!isCommitDiag)
                        alert('บันทึกสำเร็จ')
                } else {
                    alert('เกิดข้อผิดพลาด')
                }
            })
    }

    onCommitDiag = async () => {
        await this.onSubmit(true)
        const state = this.state
        let data = {
            _id: state.order._id,
            isDiag: true,
        }
        if (state.order.isReverseCase) {
            data.isReverseCase = false
        }
        await fetch(process.env.REACT_APP_API_URL + '/api/order/doctorCommitDiag', {
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((x) => {
                if (x.updated) {
                    this.setState({ order: x.data })
                    alert(`ส่งผลอ่านสำเร็จเมื่อ ${toBuddhistYear(moment(x.data.diagDate), 'H:mm D/M/YYYY')}`)
                } else {
                    alert('เกิดข้อผิดพลาด')
                }
            })
    }

    openPage = (link) => {
        window.open(link, "_blank")
    }

    returnToDoctor = (id) => {
        const state = this.state
        const data = {
            _id: state.order._id,
            isDiag: false,
        }
        fetch(process.env.REACT_APP_API_URL + '/api/order/userReturnToDoctor', {
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((x) => {
                if (x.updated) {
                    if (!x.lineNotify)
                        alert('แจ้งเตือนไลน์เกิดข้อผิดพลาด')
                    this.props.history.push('/home/readDoneQueue')
                } else {
                    alert('เกิดข้อผิดพลาด')
                }
            })
    }

    setDone = (id) => {
        const state = this.state
        const data = {
            _id: state.order._id,
            isDone: true,
            doneDate: new Date().toISOString()
        }
        fetch(process.env.REACT_APP_API_URL + '/api/order/setIsDiag', {
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((x) => {
                if (x.updated) {
                    this.props.history.push('/home/readDoneQueue')
                } else {
                    alert('เกิดข้อผิดพลาด')
                }
            })
    }

    render() {
        const state = this.state
        const urgencyColor = urgency2Color(state.order.urgency)
        let attachment = state.order.attachment
        if (attachment) {
            attachment = sortAttachment(attachment)
        }

        return (
            <div style={{ width: '100%' }}>
                <div style={{ display: "flex", flexDirection: "row", width: '100%' }}>
                    {state.order &&
                        <div style={{ minWidth: "500px", borderRight: "1px solid gray", margin: "0 3rem 0 0", display: 'flex', flexDirection: 'column', flex: 1 }}>
                            <h2>
                                <Chip label={state.order.urgency} style={{ backgroundColor: `${urgencyColor}` }} /> {" "}
                                {state.order.patient} (HN {state.order.hn})
                            </h2>
                            <div style={{marginLeft: '1rem'}}>
                                <div style={cssRow}>
                                    <div style={cssTopic}>Ward</div>
                                    <div style={cssTopicChoice}>
                                        {state.order.ward}
                                    </div>
                                </div>
                                <div style={cssRow}>
                                    <div style={cssTopic}>อายุ</div>
                                    <div style={cssTopicChoice}>
                                        {state.order.age ? state.order.age : 'ไม่บันทึก'}
                                    </div>
                                </div>
                                <div style={cssRow}>
                                    <div style={cssTopic}>รายการตรวจ</div>
                                    <div style={cssTopicChoice}>

                                        {state.order.option.map((o) => {
                                            return <div key={o._id} style={{ display: "block" }}>{o.serviceName} (จำนวน {o.amount})</div>
                                        })}
                                    </div>
                                </div>
                                <div style={cssRow}>
                                    <div style={cssTopic}>วันที่ตรวจ</div>
                                    <div style={cssTopicChoice}>
                                        {toBuddhistYear(moment(state.order.createDate), "H:mm D/M/YYYY")}
                                    </div>
                                </div>
                                <div style={cssRow}>
                                    <div style={cssTopic}>วันรับผล</div>
                                    <div style={cssTopicChoice}>
                                        {state.order.expectReportDate ? toBuddhistYear(moment(state.order.expectReportDate), "D/M/YYYY") : "ไม่มีข้อมูล"}
                                    </div>
                                </div>
                                <div style={cssRow}>
                                    <div style={cssTopic}>ศูนย์</div>
                                    <div style={cssTopicChoice}>
                                        {state.order.xrayCenter}
                                    </div>
                                </div>
                                <div style={cssRow}>
                                    <div style={cssTopic}>แพทย์ส่งตรวจ</div>
                                    <div style={cssTopicChoice}>
                                        {state.order.sender}
                                    </div>
                                </div>
                                <div>
                                    <div style={cssRow}>
                                        <div style={cssTopic}>รังสีแพทย์</div>
                                        <div style={{ ...cssTopicChoice, fontWeight: "bold" }}>
                                            {state.order.doctor}
                                        </div>
                                    </div>
                                    <div style={cssRow}>
                                        <div style={cssTopic}>รหัสเคส</div>
                                        <div style={{ ...cssTopicChoice }}>
                                            {state.order._id}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    }
                    <div style={{ minWidth: "500px", verticalAlign: "top", display: 'flex' }}>
                        {state.order.attachment && state.order.attachment.length > 0 && <div>
                            <h2>ไฟล์ในระบบ</h2>
                            <Paper>
                                <Table size="small" aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">ชื่อไฟล์</TableCell>
                                            <TableCell align="center">ผู้อัพโหลด</TableCell>
                                            <TableCell align="center">วันที่</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {state.order.attachment.map(x => {
                                            const temp = x.name
                                            const filename = temp.substr(temp.indexOf('_') + 1, temp.length)
                                            let isFilm = true
                                            const date = moment(x.date)
                                            if (x.type === 'other')
                                                isFilm = false
                                            return (
                                                <TableRow key={x._id}>
                                                    <TableCell>
                                                        {isFilm && <div style={{ display: "inline-block", marginRight: "0.5rem" }}><AccessibilityIcon /></div>}
                                                        {!isFilm && <div style={{ display: "inline-block", marginRight: "0.5rem" }}><PhotoIcon /></div>}
                                                        <div style={{ display: "inline-block", verticalAlign: "top", paddingTop: "0.2rem" }}>
                                                            <a href={'/api/upload?id=' + x._id} style={{ ...cssFileLink }} onClick={() => this.download(x._id)} download={filename}>
                                                                {filename}
                                                            </a>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>{x.uploader.substr(0, 7) + "..."}</TableCell>
                                                    <TableCell align="center">
                                                        <div style={{ display: "block" }}>{date.format("H:mm")}</div>
                                                        <div style={{ display: "block" }}>{toBuddhistYear(date, "D/M/YYYY")}</div>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </Paper>
                            <Memo order={state.order} user={state.user.firstname} userId={state.user.id} />
                        </div>}
                    </div>
                </div>
                <div style={{ display: "block" }}>
                    {(state.order.isDone || (state.order.attachment && state.order.attachment.length > 0)) && <div>
                        <h2 style={{ marginBottom: 0 }}>
                            ผลอ่านภาพ
                            {state.user.role === 'user' &&
                                <div style={{ display: "inline-block", marginLeft: "2rem" }}>
                                    <Fab variant="extended" aria-label="like" size="small" style={cssFab} onClick={() => window.open('/receipt?id=' + state.order._id, "_blank")}>
                                        <ReceiptIcon style={{ marginRight: "0.5rem" }} />
                                        พิมพ์ใบเสร็จ
                                    </Fab>
                                    {state.order.isDiag && <div style={{ display: "inline-block" }}>
                                        <Fab variant="extended" aria-label="like" size="small" style={cssFab} onClick={() => this.openPage('/reportDiag?id=' + state.order._id)}>
                                            <PrintIcon style={{ marginRight: "0.5rem" }} />
                                            พิมพ์ผล
                                        </Fab>
                                    </div>}
                                    {state.order.isDiag && <div style={{ display: "inline-block" }}>
                                        <Fab variant="extended" aria-label="like" size="small" style={cssFab} onClick={() => this.openPage('/reportDiag?id=' + state.order._id + '&lang=en')}>
                                            <PrintIcon style={{ marginRight: "0.5rem" }} />
                                            พิมพ์ผลEN
                                        </Fab>
                                    </div>}
                                    {!state.order.isDone && state.order.isDiag && <div style={{ display: "inline-block" }}>
                                        <Fab variant="extended" aria-label="like" size="small" style={{ ...cssFab, backgroundColor: '#1de9b6' }} onClick={this.setDone}>
                                            <CheckCircleIcon style={{ marginRight: "0.5rem" }} />
                                            ปิดเคส
                                        </Fab>
                                        <Fab variant="extended" aria-label="like" size="small" style={{ ...cssFab, backgroundColor: '#ffeb3b' }} onClick={this.returnToDoctor}>
                                            <UndoIcon style={{ marginRight: "0.5rem" }} />
                                            ส่งกลับรังสีแพทย์
                                        </Fab>
                                    </div>}
                                </div>
                            }
                        </h2>
                        {state.order.isReverseCase && state.user.role === 'doctor' && <div>
                            ผลที่ส่งไปแล้ว <br />
                            <TextField
                                id="outlined-multiline-static"
                                multiline
                                rows="12"
                                margin="normal"
                                variant="outlined"
                                value={state.oldDiag}
                                style={{ width: "1100px" }}
                                disabled={true}
                            />
                            <br />ผลแก้ไข<br />
                        </div>}
                        {<TextField
                            id="outlined-multiline-static"
                            multiline
                            rows="12"
                            margin="normal"
                            variant="outlined"
                            value={state.order.diag}
                            onChange={this.editDiagText}
                            style={{ width: "1100px" }}
                            disabled={state.order.isDiag || state.user.role === 'user'}
                        />}
                        {!state.order.isDiag && state.user.role === 'doctor' && <div style={{ marginTop: "0.8rem" }}>
                            {!state.order.isReverseCase && <Button style={{ marginRight: "0.5rem", display: "inline-block" }} variant="contained" color="primary" onClick={() => this.onSubmit(false)}>บันทึก</Button>}
                            {state.order.diag && state.order.diag.length > 0 && <div style={{ display: "inline-block" }}>
                                <Button variant="contained" color="secondary" onClick={this.onCommitDiag}>ส่งผลอ่าน</Button>
                            </div>}
                        </div>}
                        {state.order.isDiag && <div style={{ marginTop: "0.8rem" }}>
                            ผลอ่านภาพส่งไปเมื่อ {toBuddhistYear(moment(state.order.diagDate), 'H:mm D/M/YYYY')}
                        </div>}
                    </div>}
                    {!state.order.isDone && state.order.attachment && state.order.attachment.length === 0 && <h2>รออัพโหลดไฟล์</h2>}
                </div>
            </div>
        )
    }
}