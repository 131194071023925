import React, {Component} from 'react';
import moment from 'moment';
import { formatNumber } from './service/utils';
import DateFnsUtils from '@date-io/date-fns';
import {  MuiPickersUtilsProvider,  KeyboardDatePicker, } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DownloadExcelButton from './component/DownloadExcelButton';

export default class ReportDFSummary extends Component {
	constructor(props) {
		super(props);
		this.state = {
            data: '', 
            from: moment().format("YYYY-MM-DD"),
            to:  moment().format("YYYY-MM-DD"),
		};
		
    };
    
    componentDidMount(){
        this.props.changeTitle('รายงานสรุป DF รังสีแพทย์')
        document.title = 'รายงานสรุป DF รังสีแพทย์'
    }

	exportFile = () => {
        const state = this.state
        const from = moment(state.from).format('YYYY-MM-DD')
        const to = moment(state.to).add(1,'days').format('YYYY-MM-DD')
        
        if ( parseInt( moment(to).diff(moment(from), 'days' )) > 33 ) {
            alert('ไม่สามารถออกรายงานที่มากกว่า 30 วันได้ กรุณาลดจำนวนวัน')
            return
        }

        fetch(`${process.env.REACT_APP_API_URL}/api/report/dfSummary?from=${from}&to=${to}`)
        .then(res => res.json())
        .then(x => {
            let data = x.data
            data.sort( (a,b) => {
                return a.doctor.firstname.localeCompare(b.doctor.firstname, 'th')
            } )
            let newData = []
            data.map( x => {
                let row = {}
                row.name = `${x.doctor.title}${x.doctor.firstname}  ${x.doctor.lastname}`
                row.df = x.totalDF
                row.whTax = x.totalDF * -0.03
                row.transfer = x.totalDF + row.whTax
                newData.push(row)
                return x
            })
            this.setState({data: newData})
        })
    }
    
	render() { 
        const state = this.state
        let totalDf = 0
        let totalWhTax = 0
        let totalTransfer = 0

        return (
            <div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div style={{marginRight: '2rem'}}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            label="ตั้งแต่วันที่"
                            value={state.from}
                            onChange={(e) => this.setState({from: e})}
                            KeyboardButtonProps={{
                            'aria-label': 'change date',
                            }}
                        />
                    </div>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        label="ถึงวันที่"
                        value={state.to}
                        onChange={(e) => this.setState({to: e})}
                        KeyboardButtonProps={{
                        'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
                <Button style={{ margin: '1rem 0 0 0', display: "block" }} variant="contained" color="primary" onClick={this.exportFile}>ดึงข้อมูล</Button>
                <DownloadExcelButton header={['รังสีแพทย์','ค่า DF รวม','ภาษี ณ ที่จ่าย','ยอดเงินโอน']} data={state.data} dataDefinition={['name', 'df', 'whTax', 'transfer']} filename={`ReportDfSummary`}/>
                {state.data && 
                    <Paper style={{marginTop: "2rem"}}>
                        <Table style={{minWidth: "650px"}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">รังสีแพทย์</TableCell>
                                <TableCell align="center">ค่า DF รวม</TableCell>
                                <TableCell align="center">ภาษี ณ ที่จ่าย</TableCell>
                                <TableCell align="center">ยอดเงินโอน</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {state.data.map(x => {
                                totalDf += x.df
                                totalWhTax += x.whTax
                                totalTransfer += x.transfer
                                return (
                                    <TableRow key={x.name}>
                                        <TableCell>{x.name}</TableCell>
                                        <TableCell align="right">{formatNumber(x.df.toFixed(2))}</TableCell>
                                        <TableCell align="right">{formatNumber(x.whTax.toFixed(2))}</TableCell>
                                        <TableCell align="right">{formatNumber(x.transfer.toFixed(2))}</TableCell>
                                    </TableRow>
                                )})
                            }
                            <TableRow style={{textDecoration: "underline"}}>
                                <TableCell>รวม</TableCell>
                                <TableCell align="right">{formatNumber(totalDf.toFixed(2))}</TableCell>
                                <TableCell align="right">{formatNumber(totalWhTax.toFixed(2))}</TableCell>
                                <TableCell align="right">{formatNumber(totalTransfer.toFixed(2))}</TableCell>
                            </TableRow>
                        </TableBody>
                        </Table>
                    </Paper>
                }
            </div>
        );      
    };
};
