import Chip from '@material-ui/core/Chip';
import React, {Component} from 'react';

export default class RoleTag extends Component {
    
    render(){
        const role = this.props.role
        let label = ''
        let color = ''
        if (role === 'doctor') {
            label = "Doctor"
            color = '#ba68c8'
        } else if (role === 'admin') {
            label = "Admin"
            color = '#c62828'
        } else if (role === 'user') {
            label = "User"
            color = '#90caf9'
        } else if (role === 'office') {
            label = "Office"
            color = '#fff59d'
        } 
        return (
            <Chip label={label} style={{marginLeft: "1rem", backgroundColor: `${color}`}}/>
        )
    }
}
    
