import React, { Component } from 'react';
import moment from 'moment';
import { urgency2Color, toBuddhistYear } from './service/utils';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import BackupIcon from '@material-ui/icons/Backup';
import DeleteIcon from '@material-ui/icons/Delete';
import Chip from '@material-ui/core/Chip';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';

const cssFab = {
    marginRight: "1rem"
}

export default class UploadQueue extends Component {
    constructor() {
        super();
        this.state = {
            xrayCenterId: '',
            queues: [],
            deleteModalOpen: false,
            deleteId: '',
            inputHn: '',
        }

    }

    componentDidMount() {
        this.props.changeTitle('คิวอัพโหลดภาพ')
        document.title = 'คิวอัพโหลดภาพ'
        const xrayCenter = JSON.parse(localStorage.getItem('xrayCenter'))
        this.setState({ xrayCenterId: xrayCenter.id })
        fetch(process.env.REACT_APP_API_URL + '/api/uploadQueue?xrayCenterId=' + xrayCenter._id + '&isDiag=false&isDeleted=false')
            .then(res => res.json())
            .then(x => {
                this.setState({ queues: x.data })
            });

    }


    buttonClick = (e) => {
        this.props.history.push(e)
    }

    deleteOrder = (id) => {
        const state = this.state
        let target = state.queues.filter(x => x._id === state.deleteId)
        target = target[0]

        if (target.hn !== state.inputHn) {
            alert('พิมพ์ HN ไม่ถูกต้อง ไม่สามารถลบเคสได้')
            this.setState({ deleteModalOpen: false, inputHn: '', deleteId: '' })
            return
        }

        const user = JSON.parse(localStorage.getItem('user'))
        const text = `<<ลบโดย:${user.firstname} ${user.lastname},วันที่:${moment().format("D/M/YYYY H:mm")}>>`

        let systemLog = target.systemLog ? target.systemLog : ''
        systemLog += text

        const data = {
            _id: target._id,
            isDeleted: true,
            systemLog: systemLog
        }
        fetch(process.env.REACT_APP_API_URL + '/api/order/setIsDiag', {
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((x) => {
                if (x.updated) {
                    window.location.reload()
                } else {
                    alert('เกิดข้อผิดพลาดในการลบ')
                }
            })
    }

    closeModal = (e) => {
        this.setState({ deleteModalOpen: false })
    }

    onTypeHn = (e) => {
        this.setState({ inputHn: e.target.value })
    }

    render() {
        const state = this.state

        return (
            <div>
                <SimpleModal open={state.deleteModalOpen} onClose={this.closeModal} onConfirm={this.deleteOrder} onTypeHn={this.onTypeHn} inputHn={state.inputHn} />
                {state.queues &&
                    <Paper>
                        <Table style={{ minWidth: "650px" }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" style={{ maxWidth: "50px", lineHeight: '1rem' }}>ความด่วน<br />วันรับผล</TableCell>
                                    <TableCell align="center">ขื่อ-นามสกุล</TableCell>
                                    <TableCell align="center">รายการตรวจ</TableCell>
                                    <TableCell align="center">รังสีแพทย์</TableCell>
                                    <TableCell align="center">วันส่ง</TableCell>
                                    <TableCell align="center">เมนู</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {state.queues.map(x => {
                                    const date = moment(x.createDate)
                                    return (
                                        <TableRow key={x._id}>
                                            <TableCell align="left">
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                    <Chip size='small' label={x.urgency} style={{ backgroundColor: `${urgency2Color(x.urgency)}` }} />
                                                    {x.expectReportDate ? toBuddhistYear(moment(x.expectReportDate), "D/M/YYYY") : ''}
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{ display: "block", fontWeight: "bold" }}>{x.patient}</div>
                                                <div style={{ display: "block" }}>HN {x.hn}</div>
                                                <div style={{ display: "block" }}>Ward {x.ward}</div>
                                            </TableCell>
                                            <TableCell>
                                                {x.option.map((o, idx) => {
                                                    return <div key={o._id} style={{ display: "block" }}>{o.serviceName}</div>
                                                })}
                                            </TableCell>
                                            <TableCell>{x.doctor}</TableCell>
                                            <TableCell align="center">
                                                <div style={{ display: "block" }}>{toBuddhistYear(date, "D/M/YYYY")}</div>
                                                <div style={{ display: "block" }}>{date.format("H:mm")}</div>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Fab variant="extended" aria-label="like" size="small" style={cssFab} onClick={() => window.open('/receipt?id=' + x._id, "_blank")}>
                                                    <ReceiptIcon style={{ marginRight: "0.5rem" }} />
                                                    ใบเสร็จ
                                                </Fab>
                                                <Fab color="primary" variant="extended" aria-label="like" size="small" style={cssFab} onClick={() => this.buttonClick('/home/upload?id=' + x._id)}>
                                                    <BackupIcon style={{ marginRight: "0.5rem" }} />
                                                    อัพโหลด
                                                </Fab>
                                                {/* <Fab variant="extended" aria-label="like" size="small" style={cssFab} onClick={() => this.deleteOrder(x._id)}> */}
                                                <Fab variant="extended" aria-label="like" size="small" style={cssFab} onClick={() => this.setState({ deleteModalOpen: true, deleteId: x._id, inputHn: '' })}>
                                                    <DeleteIcon style={{ marginRight: "0.5rem" }} />
                                                    ลบ
                                                </Fab>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </Paper>
                }
            </div>
        );
    }
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    textField: {
        display: 'block',
    },
}));

function SimpleModal(props) {
    const classes = useStyles();
    const [hn] = React.useState('');

    return (
        <div>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={props.open ? props.open : false}
                onClose={props.onClose}
            >
                <div style={{ top: "50%", left: "50%", transform: `translate(-50%, -50%)` }} className={classes.paper}>
                    <h2 id="simple-modal-title">ยืนยันการลบเคสด้วยการพิมพ์ HN</h2>
                    <TextField
                        id="outlined-full-width"
                        label="HN"
                        className={classes.textField}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={props.inputHn}
                        onChange={props.onTypeHn}
                        variant="outlined"
                    />
                    <center>
                        <Fab variant="extended" aria-label="like" size="small" style={{ ...cssFab, marginTop: "1rem" }} color="secondary" onClick={() => props.onConfirm(hn)}>
                            <DeleteIcon style={{ marginRight: "0.5rem" }} />
                            ลบ
                        </Fab>
                    </center>
                </div>
            </Modal>
        </div>
    );
}