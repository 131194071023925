import Fab from '@material-ui/core/Fab';
import TextField from '@material-ui/core/TextField';
import React, { Component } from 'react';
import moment from 'moment';
import { Box, Paper } from '@material-ui/core';

export default class Memo extends Component {

    constructor() {
        super();
        this.state = {
            order: '',
            memoText: '',
        }
    }
    componentDidMount() {
        this.setState({ order: this.props.order })
    }

    sendMemo = () => {
        const data = {
            _id: this.state.order._id,
            text: this.state.memoText,
            user: this.props.user,
            userId: this.props.userId,
            date: moment().format(),
        }
        fetch(process.env.REACT_APP_API_URL + '/api/order/addMemo', {
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((x) => {
                if (x.updated) {
                    this.setState({ order: x.data, memoText: '' })
                } else {
                    alert('เกิดข้อผิดพลาดในการเพิ่มเมโม่')
                }
            })
    }

    render() {
        const state = this.state

        return (
            <Box style={{ marginTop: '1rem' }}>
                <h2>เมโม่</h2>
                <Paper>
                    <Box p={2}>
                        {/* CONVERSATION */}
                        <div style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                            {state.order.memo && state.order.memo.map((o, idx) => {
                                const isMine = o.userId === this.props.userId
                                let css = {
                                    backgroundColor: "white",
                                    borderRadius: '15px 15px 15px 0',
                                    border: '1px solid #2F4F4F',
                                }
                                if (isMine) {
                                    css = {
                                        backgroundColor: "#86d97b",
                                        borderRadius: '15px 15px 0 15px',
                                        border: '1px solid #2F4F4F',
                                    }
                                }
                                return (
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }} key={o._id}>
                                        {isMine && <div style={{ flex: 1 }} />}
                                        <div style={{
                                            flexDirection: "row", display: "flex",
                                            textAlign: 'right', padding: '0 1rem', ...css
                                        }} >
                                            <div align="left" >
                                                <div>{o.user}</div>
                                                <div style={{ fontSize: "0.7rem" }}>{o.date ? moment(o.date).format('H:mm D/M') : 'No Date'}</div>
                                            </div>
                                            <div style={{ display: "inline-block", verticalAlign: "top" }}>
                                                {o.text}
                                            </div>
                                        </div>
                                        {!isMine && <div style={{ flex: 1 }} />}
                                    </div>
                                )
                            })}
                        </div>
                        {/* NEW MESSAGE */}
                        {!state.order.isDiag &&
                            <div style={{ display: 'flex' }}>
                                <TextField
                                    style={{ backgroundColor: "white", flex: 1 }}
                                    id="outlined-basic"
                                    margin="dense"
                                    variant="outlined"
                                    value={state.memoText}
                                    onChange={(e) => this.setState({ memoText: e.target.value })}
                                />
                                <Fab variant="extended" aria-label="like" size="small" style={{ margin: "0.6rem 0 0 0.5rem" }} onClick={this.sendMemo} color="primary">
                                    ส่ง
                                </Fab>
                            </div>
                        }
                    </Box>
                </Paper>
            </Box>
        )
    }
}