import React, {Component} from 'react';
import moment from 'moment';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { toBuddhistYear } from './service/utils';

const cssRow = {
    margin: '0 0 1rem 0',
    verticalAlign: "top"
}


const cssTopic = {
    display: 'inline-block',
    marginLeft: '2rem',
    width: '100px'
}

const cssTopicChoice = {
    display: 'inline-block',
    margin: '0 0 1rem 4rem ',
    width: '400px'
}

export default class DeletePatient extends Component {
    constructor() {
        super();
        this.state = {
            patient: '',
            fetched: false,
            patientId: '',
            user: ''
        }
    }

    componentDidMount() {
        this.props.changeTitle('ลบผู้ป่วย')
        document.title = 'ลบผู้ป่วย'        
        let user = JSON.parse(localStorage.getItem('user'))
        this.setState({user: user})
    }

    buttonClick = (e) => {
        this.props.history.push(e)
    }

    fetchOrder = () => {
        fetch(process.env.REACT_APP_API_URL + '/api/patient?id=' + this.state.patientId)
        .then(res => res.json())
        .then(x => {
            if (x.ok) {
                this.setState({ patient: x.data, fetched: true })
            } else {
                alert('รหัสผู้ป่วยไม่ถูกต้อง')
                this.setState({ patientId: '' })
            }
        });
    }

    delete = () => {
        const data = {
            _id: this.state.patient._id,
            active: false
        }
        fetch( process.env.REACT_APP_API_URL + '/api/patient', {
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then ( ( x ) => {
            if(x.updated){
                alert('ลบผู้ป่วยเรียบร้อย')
                this.props.history.push('/home')
            } else {
                alert('เกิดข้อผิดพลาด กรุณาติดต่อแอดมิน')
            }
        })
    }

    render() {
    const state = this.state
      
    return (
        <div>
            {!state.fetched && <div style={{ verticalAlign: "top", marginBottom: "1rem" }}>
                <div style={{ display: "block", margin: "1rem 0 1rem 2rem", color: 'red'}}><b>คำเตือน</b> การลบผู้ป่วยเป็นการกระทำที่ไม่สามารถย้อนกลับได้ จะส่งผลให้ผู้ป่วยคนนั้นไม่สามารถค้นหาได้ กรุณาตรวจสอบให้แน่ใจก่อนทำการลบ</div>
                <div style={{ display: "flex" }}>
                    <div style={{...cssTopic, height: "4rem"}}><div style={{display: "block", marginTop: "1rem"}}>รหัสผู้ป่วย</div></div>
                    <div style={cssTopicChoice}>
                        <TextField
                            style={{backgroundColor: "white"}}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={state.patientId}
                            onChange={ (e) => this.setState({ patientId: e.target.value }) }
                        />
                    </div>
                </div>
                <Button variant="contained" color="primary" onClick={ this.fetchOrder } style={{margin: "0 0 2rem 2rem"}}>ดึงข้อมูล</Button>
            </div>}
            {state.fetched &&  <div style={{ verticalAlign: "top", marginTop: "2rem" }}>
                <div style={cssRow}>
                    <div style={{...cssTopic, fontWeight: "bold"}}>HN</div>
                    <div style={{...cssTopicChoice}}>
                        {state.patient.hn}
                    </div>
                </div>
                <div style={cssRow}>
                    <div style={{...cssTopic, fontWeight: "bold"}}>ชื่อผู้ป่วย</div>
                    <div style={{...cssTopicChoice}}>
                        {state.patient.firstname + ' ' + state.patient.lastname}
                    </div>
                </div>
                <div style={cssRow}>
                    <div style={{...cssTopic, fontWeight: "bold"}}>Ward</div>
                    <div style={{...cssTopicChoice}}>
                        {state.patient.ward}
                    </div>
                </div>
                <div style={cssRow}>
                    <div style={{...cssTopic, fontWeight: "bold"}}>วันที่แก้ไขล่าสุด</div>
                    <div style={{...cssTopicChoice}}>
                        { toBuddhistYear( moment(state.patient.createDate), 'D/M/YYYY H:mm')}
                    </div>
                </div>
                <div style={cssRow}>
                    <div style={cssTopic}>
                        <Button variant="contained" color="secondary" onClick={ this.delete } style={{marginBottom: "1rem"}}>ลบผู้ป่วย</Button>
                    </div>
                </div>
                
            </div>}
                

        </div>   
    );
  }
}
