import React, {Component} from 'react';
import queryString from 'query-string';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';
import { getDateString } from './service/utils';

const cssTopic = {
    display: 'inline-block',
    marginLeft: '2rem',
    width: '100px'
}
const cssTopicChoice = {
    display: 'inline-block',
    margin: '0 0 1rem 4rem ',
    width: '600px'
}

export default class Center extends Component {
    constructor() {
        super();
        this.state = {
            xrayCenter: {
                name: '',
                address: '',
                code: '',
                active: true
            },
            createNew: true,
            lineGroup: null
        }
    }    

    componentDidMount() {
        const query = queryString.parse(this.props.location.search); 
        if (this.props.location.search) {
            fetch(process.env.REACT_APP_API_URL + '/api/xrayCenterById?id=' + query.id)
            .then(res => res.json())
            .then(x => {
                this.setState({ xrayCenter: x.data , createNew: false})
                this.props.changeTitle(x.data.name)
                document.title = x.data.name
            });

            fetch(`${process.env.REACT_APP_API_URL}/api/line/checkGroup?centerId=${query.id}`)
            .then(res => res.json())
            .then(x => {
                console.log(x)
                if (x.ok) {
                    this.setState({lineGroup: x.data})
                }
            });
        } else {
            this.props.changeTitle('เพิ่มศูนย์ใหม่')
            document.title = 'เพิ่มศูนย์ใหม่'
        }

    }


    onSubmit = () => {
        const state = this.state
        const {ward, __v, ...data} = state.xrayCenter
        let method = 'PATCH'
        if (state.createNew) {
            method = 'POST'
        }
        fetch( process.env.REACT_APP_API_URL + '/api/xrayCenter', {
            method: method,
            body: JSON.stringify(data),
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(res => res.json())
          .then ( ( x ) => {
            if(x.updated){
                alert('บันทึกเรียบร้อย')
                this.setState({ xrayCenter: x.data })
            } else if (x.ok) {
                alert('เพิ่มศูนย์ใหม่เรียบร้อย')
                this.props.history.push('/home/allCenter')
            } else {
                alert('เกิดข้อผิดพลาด')
            }
          })
    }

    deleteLine = () => {
        const data = {
            _id: this.state.lineGroup._id,
            active: false
        }
        fetch( `${process.env.REACT_APP_API_URL}/api/line/group`, {
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(res => res.json())
          .then ( ( x ) => {
              if( x.updated ){
                alert('ลบไลน์แล้ว')
                window.location.reload()
              } else {
                alert('เกิดข้อผิดพลาดในการลบไลน์')
              }
          })
    }

    render() {
    const state = this.state
    return (
        <div>
            {!state.createNew && <h3>แก้ไขข้อมูล</h3>}
            <div style={{ display: "flex", height: "4rem" , verticalAlign: "top"}}>
                <div style={{...cssTopic, height: "4rem"}}><div style={{display: "block", marginTop: "1rem"}}>ชื่อศูนย์</div></div>
                <div style={cssTopicChoice}>
                    <TextField
                        style={{backgroundColor: "white"}}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        value={state.xrayCenter.name}
                        onChange={ (e) => this.setState({ xrayCenter: {...state.xrayCenter, name: e.target.value } })}
                    />
                </div>
            </div>
            <div style={{ display: "flex", height: "4rem" , verticalAlign: "top"}}>
                <div style={{...cssTopic, height: "4rem"}}><div style={{display: "block", marginTop: "1rem"}}>ชื่อศูนย์ EN</div></div>
                <div style={cssTopicChoice}>
                    <TextField
                        style={{backgroundColor: "white"}}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        value={state.xrayCenter.nameEn}
                        onChange={ (e) => this.setState({ xrayCenter: {...state.xrayCenter, nameEn: e.target.value } })}
                    />
                </div>
            </div>
            <div style={{ display: "flex", height: "14rem" , verticalAlign: "top"}}>
                <div style={{...cssTopic, height: "11rem"}}><div style={{display: "block", marginTop: "1rem"}}>ที่อยู่</div></div>
                <div style={cssTopicChoice}>
                    <TextField
                        style={{backgroundColor: "white"}}
                        multiline
                        rows="10"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        value={state.xrayCenter.address}
                        onChange={ (e) => this.setState({ xrayCenter: {...state.xrayCenter, address: e.target.value } })}
                    />
                </div>
            </div>
            <div style={{ display: "flex", height: "14rem" , verticalAlign: "top"}}>
                <div style={{...cssTopic, height: "11rem"}}><div style={{display: "block", marginTop: "1rem"}}>ที่อยู่ EN</div></div>
                <div style={cssTopicChoice}>
                    <TextField
                        style={{backgroundColor: "white"}}
                        multiline
                        rows="10"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        value={state.xrayCenter.addressEn}
                        onChange={ (e) => this.setState({ xrayCenter: {...state.xrayCenter, addressEn: e.target.value } })}
                    />
                </div>
            </div>
            <div style={{ display: "flex", height: "4rem" , verticalAlign: "top"}}>
                <div style={{...cssTopic, height: "4rem"}}><div style={{display: "block", marginTop: "1rem"}}>ชื่อย่อ</div></div>
                <div style={cssTopicChoice}>
                    <TextField
                        style={{backgroundColor: "white"}}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        value={state.xrayCenter.code}
                        onChange={ (e) => this.setState({ xrayCenter: {...state.xrayCenter, code: e.target.value } }) }
                    />
                </div>
            </div>
            <div style={{ display: "flex", height: "4rem" , verticalAlign: "top"}}>
                <div style={{...cssTopic, height: "4rem"}}><div style={{display: "block", marginTop: "1rem"}}>สถานะการบริการ</div></div>
                <div style={cssTopicChoice}>
                    <FormControlLabel
                        control={
                        <Switch
                            checked={Boolean(state.xrayCenter.active)}
                            onChange={(e) => this.setState({ xrayCenter: {...state.xrayCenter, active: e.target.checked } }) }
                            color="primary"
                        />
                        }
                        label="เปิด"
                        style={{marginTop: "0.5rem"}}
                    />
                </div>
            </div>
            <h3>การเชื่อมต่อไลน์กลุ่ม</h3>
            <div style={{ display: "flex", verticalAlign: "top"}}>
                <div style={{...cssTopic}}>สถานะ</div>
                <div style={cssTopicChoice}>
                    { state.lineGroup ? 
                        <React.Fragment>
                            เชื่อมต่อไลน์แล้วเมื่อวันที่ {getDateString( new Date(state.lineGroup.date), true)}
                            <Paper style={{padding: '1rem', marginTop: '0.5rem'}}>
                                <Button style={{ margin: 10 }} variant="contained" color="secondary" onClick={this.deleteLine}>ลบไลน์กลุ่ม</Button>
                                <br/>*ไลน์เก่าจะไม่ได้รับการแจ้งเตือนหลังจากถูกลบ
                                <br/>**หากต้องการรับการแจ้งเตือนใหม่ ต้องให้ผู้ใช้ทำการลงทะเบียนอีกครั้ง
                            </Paper>
                        </React.Fragment>
                        :  'ยังไม่ได้เชื่อมต่อ' 
                    }
                </div>
            </div>
            
            <Button style={{ margin: 10 }} variant="contained" color="primary" onClick={this.onSubmit}>บันทึก</Button>
        </div>    
    );
  }
}