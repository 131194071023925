import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import RoleTag from './component/RoleTag';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';


import XrayCenterSelect from './component/XrayCenterSelect';
import LineIcon from './component/LineIcon';
import LineTestMessageDialog from './component/LineTestMessageDialog';


const cssFab = {
    marginRight: "1rem"
}

const cssRow = {
    margin: '0 0 1rem 0',
    verticalAlign: "top"
}

const cssTopic = {
    display: 'inline-block',
    marginLeft: '2rem',
    width: '100px',
    verticalAlign: "top"
}
const cssTopicChoice = {
    display: 'inline-block'
}

const PAGE_SIZE = 15

export default class AllUser extends Component {
    constructor() {
        super();
        this.state = {
            users: [],
            slicedUsers:[],
            roleFilter: 'user',
            xrayCenter: [],
            chosenCenter: '',
            lineMessageReceiver: null,
            currentPage: 0
        }
    }

    componentDidMount() {
        this.props.changeTitle('ผู้ใช้ทั้งหมด')
        document.title = 'ผู้ใช้ทั้งหมด'        
        fetch(process.env.REACT_APP_API_URL + `/api/xrayCenter?active=true` )
        .then(res => res.json())
        .then(x => {
            let xrayCenter = x.data
            this.setState({xrayCenter: xrayCenter})
        });
    }

    sliceUsers = (newPage) => {
        // slice this page
        const state = this.state
        const start = newPage * PAGE_SIZE
        const end = (newPage + 1) * PAGE_SIZE > state.users.length ? state.users.length : (newPage + 1) * PAGE_SIZE + 1 
        let slicedUsers = state.users.slice(start, end)

        // check line status
        let ids = slicedUsers.map( u => {
            return u._id
        })
        ids = {userIds: ids}
        const qs = require('qs')
        ids = qs.stringify(ids)
        fetch(`${process.env.REACT_APP_API_URL}/api/line/checkUser?${ids}`)
        .then(res => res.json())
        .then(x => {
            if (x.ok && x.data) {
                const lineInfo = x.data
                slicedUsers = slicedUsers.map( u => {
                    let temp = lineInfo.find( e => e.userId === u._id )
                    if (temp) {
                        u.lineId = temp.lineUserId
                    } else {
                        u.lineId = null
                    }
                    return u
                })
                this.setState({slicedUsers: slicedUsers, currentPage: newPage})
            }
        });
        
    }

    fetchUsers = (query) => {
        fetch(`${process.env.REACT_APP_API_URL}/api/user?${query}` )
        .then(res => res.json())
        .then(x => {
            this.setState({ users: x.data })
            this.sliceUsers(0)
        });
    }

    buttonClick = (e) => {
        this.props.history.push(e)
    }

    changeRoleFilter = (e) => {
        const filter = e.target.value
        if (filter === 'user') {
            this.setState({ chosenCenter: '', users: [] })
        } else {
            this.fetchUsers(`role=${filter}`)
        }
        this.setState({roleFilter: filter})

    }

    handleSeleted = (e) => {
        const xrayCenter = e.target.value
        this.fetchUsers(`role=user&xrayCenter=${xrayCenter}`)
        this.setState({ chosenCenter: xrayCenter })
    }

    handleLineIconClick = (user, lineId) => {
        let receiver = {
            name: user.firstname + ' ' + user.lastname,
            lineType: 'user',

            userId: user._id,            
            lineUserId: lineId,

            xrayCenterId: null,
            lineGroupId: null
        }
        this.setState({lineMessageReceiver: receiver})
    }

    handleChangePage = (event, newPage) => {
        this.sliceUsers(newPage)
     };

    render() {
    const state = this.state
      
    return (
        <div>
            <LineTestMessageDialog lineMessageReceiver={state.lineMessageReceiver} onClose={() => this.setState({lineMessageReceiver: null})}/>
            <Button style={{ margin: "0 0 2rem 0" }} variant="contained" color="secondary" onClick={() => this.buttonClick('/home/user')}>เพิ่มผู้ใช้</Button>
            <div style={cssRow}>
                <div style={cssTopic}><div style={{marginTop: "0.6rem"}}>ตัวกรอง</div></div>
                <div style={cssTopicChoice}>
                <RadioGroup aria-label="position" onChange={this.changeRoleFilter} row >
                    <FormControlLabel
                        value="user"
                        control={<Radio color="primary" />}
                        label="User"
                        labelPlacement="end"
                        checked={state.roleFilter === "user"}
                    />
                    <FormControlLabel
                        value="doctor"
                        control={<Radio color="primary" />}
                        label="Doctor"
                        labelPlacement="end"
                        checked={state.roleFilter === "doctor"}
                    />
                    <FormControlLabel
                        value="office"
                        control={<Radio color="primary" />}
                        label="Office"
                        labelPlacement="end"
                        checked={state.roleFilter === "office"}
                    />
                    <FormControlLabel
                        value="admin"
                        control={<Radio color="primary" />}
                        label="Admin"
                        labelPlacement="end"
                        checked={state.roleFilter === "admin"}
                    />
                </RadioGroup>
                { state.roleFilter === "user" && <XrayCenterSelect list={state.xrayCenter} handleSeleted={this.handleSeleted} value={state.chosenCenter}/> }
                </div>
            </div>
           
            { state.slicedUsers.length !== 0 && 
                <Paper style={{marginTop: "2rem"}}>
                    <Table style={{minWidth: "650px"}} aria-label="simple table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell  align="center" style={{maxWidth: "50px"}}>ประเภท</TableCell>
                            <TableCell  align="center">ชื่อผู้ใช้</TableCell>
                            <TableCell  align="center">ชื่อ</TableCell>
                            <TableCell align="center">นามสกุล</TableCell>
                            <TableCell align="center">สถานะ</TableCell>
                            <TableCell align="center">เมนู</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.slicedUsers.map(x => {
                        return (
                            <TableRow key={x._id}>
                                <TableCell align="center"><RoleTag role={x.role}/></TableCell>
                                <TableCell align="center">{x.email}</TableCell>
                                <TableCell>{x.firstname}</TableCell>
                                <TableCell>{x.lastname}</TableCell>
                                <TableCell align="center">
                                    <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                        >
                                            <Grid item>
                                                {x.active ? 
                                                    <Chip label="พร้อมใช้งาน" style={{backgroundColor: `#1de9b6`}}/> :
                                                    <Chip label="ปิด"/>
                                                }
                                            </Grid>
                                            <Grid item>
                                                {x.active && <LineIcon receiverLineId={x.lineId} onClick={(lineId) => this.handleLineIconClick(x, lineId)}/>}                                                
                                            </Grid>
                                    </Grid>                                
                                </TableCell>
                                <TableCell align="center">
                                    <Fab variant="extended" aria-label="like" size="small" style={cssFab} onClick={() => this.buttonClick('/home/user?id=' + x._id)}>
                                        <EditIcon/>แก้ไข
                                    </Fab>
                                </TableCell>
                            </TableRow>
                        )})}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[PAGE_SIZE]}
                                rowsPerPage={PAGE_SIZE}
                                colSpan={0}
                                count={state.users.length}
                                page={state.currentPage}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onChangePage={this.handleChangePage}
                            />
                        </TableRow>
                    </TableFooter>
                    </Table>
                </Paper>
            }
        </div>   
    );
  }
}