import React, {Component} from 'react';
import moment from 'moment';
import _ from 'lodash'
import { formatNumber } from './service/utils';
import DateFnsUtils from '@date-io/date-fns';
import {  MuiPickersUtilsProvider,  KeyboardDatePicker, } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

export default class ReportCgd extends Component {
	constructor(props) {
		super(props);
		this.state = {
            from: moment().format("YYYY-MM-DD"),
            to:  moment().format("YYYY-MM-DD"),
            data: '',
		};
    };
    
    componentDidMount(){
        this.props.changeTitle('รายงานสิทธิ์กรมบัญชีกลาง')
        document.title = 'รายงานสิทธิ์กรมบัญชีกลาง'
        
    }

    getData = ()=> {
        const state = this.state
        const from = moment(state.from).format('YYYY-MM-DD')
        const to = moment(state.to).add(1,'days').format('YYYY-MM-DD')
        
        if ( parseInt( moment(to).diff(moment(from), 'days' )) > 50 ) {
            alert('ไม่สามารถออกรายงานที่มากกว่า 30 วันได้ กรุณาลดจำนวนวัน')
            return
        }

        fetch(process.env.REACT_APP_API_URL + '/api/report/cgd?isDeleted=false&from=' + from + '&to=' + to)
        .then(res => res.json())
        .then(x => {
            let data = x.data
            data = _.chain(data).groupBy("xrayCenterId").map((value, key) => ({ xrayCenter: key, orders: value })).value()
            data.map( x => {
                x.cases = x.orders.length
                x.xrayCenter = x.orders[0].xrayCenter
                let totalCgd = 0
                x.orders.map( o => {
                    o.option.map( oo => {
                        totalCgd += oo.price * oo.amount
                        return oo
                    })
                    return o
                })
                x.totalCgd = totalCgd
                delete x.orders
                return x
            })
            this.setState({data: data})
        })


    }


	render() { 
        const state = this.state
        let s_case = 0
        let s_cash = 0
        return (
            <div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div style={{marginRight: '2rem'}}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label="ตั้งแต่วันที่"
                            value={state.from}
                            onChange={(e) => this.setState({from: e})}
                            KeyboardButtonProps={{
                            'aria-label': 'change date',
                            }}
                        />
                    </div>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="ถึงวันที่"
                        value={state.to}
                        onChange={(e) => this.setState({to: e})}
                        KeyboardButtonProps={{
                        'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
                <Button style={{ margin: '1rem 0 0 0', display: "block" }} variant="contained" color="primary" onClick={this.getData}>ดึงข้อมูล</Button>
                {state.data && <div>
                    <hr/>
                    <Paper style={{marginTop: "2rem", maxWidth: "800px"}}>
                        <Table style={{minWidth: "650px"}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">ศูนย์</TableCell>
                                <TableCell align="center">จำนวนเคสกรมบัญชีกลาง</TableCell>
                                <TableCell align="center">รวมเงินสด</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {state.data.map( (x,key) => {
                                s_case += x.cases
                                s_cash += x.totalCgd
                                return (
                                    <TableRow key={key}>
                                        <TableCell>{x.xrayCenter}</TableCell>
                                        <TableCell align="right">{x.cases}</TableCell>
                                        <TableCell align="right">{formatNumber(x.totalCgd)}</TableCell>
                                    </TableRow>
                                )})
                            }
                            <TableRow>
                                <TableCell><b>รวม</b></TableCell>
                                <TableCell align="right"><b>{s_case}</b></TableCell>
                                <TableCell align="right"><b>{formatNumber(s_cash)}</b></TableCell>
                            </TableRow>
                        </TableBody>
                        </Table>
                    </Paper>
                </div>}
            </div>
        );      
    };
};
