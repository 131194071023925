import React, {Component} from 'react';
import moment from 'moment';
import { urgency2Color, toBuddhistYear } from './service/utils';
import _ from 'lodash'

import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import PhotoIcon from '@material-ui/icons/Photo';
import Chip from '@material-ui/core/Chip';
import HistoryIcon from '@material-ui/icons/History';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';

const cssFab = {
    marginRight: "1rem"
}


export default class ReadHistory extends Component {
    constructor() {
        super();    
        this.state = {
            queues: [],
            user: '',
            page: 0,
            totalQueues: 0,
            keyword: '',
            searchMode: false,
            searchResult: [],
        }
        this.orderHistorySearch = _.debounce(this.orderHistorySearch, 500)
    }

    componentDidMount() {
        document.title = 'ประวัติอ่านภาพ'
        const user = JSON.parse(localStorage.getItem('user'))
        this.props.changeTitle('ประวัติอ่านภาพ (รังสีแพทย์' + user.firstname + ')')
        this.setState({ user: user, page: 0 })

        this.fetchQueues(user.id, 0)

        fetch(`/api/orderByDoctorCount?doctorId=${user.id}&isDiag=true&isDeleted=false` )
        .then(res => res.json())
        .then(x => {
            this.setState({totalQueues: x.data})
        });
    }

    fetchQueues ( userId, skip ) {
        fetch(`/api/orderByDoctor?doctorId=${userId}&isDiag=true&isDeleted=false&sort=diagDate&skip=${skip}&limit=20` )
        .then(res => res.json())
        .then(x => {
            this.setState({queues: x.data})
        });

    }

    handleChangePage = (event, newPage) => {
        
        const state = this.state
        if (!state.searchMode) {
            this.fetchQueues( state.user.id, 20*newPage )
            this.setState({page: newPage})
        } else {
            const data = state.searchResult.slice( 20*state.page, 20*(state.page + 1) )
            this.setState({page: newPage, queues: data})
        }
     };

    orderHistorySearch (keyword) {
        fetch(process.env.REACT_APP_API_URL + `/api/orderSearchByPatient?keyword=${keyword}&doctorId=${this.state.user.id}` )
        .then(res => res.json())
        .then(x => {
            const data = x.data
            this.setState({searchResult: data, totalQueues: data.length, page: 0, queues: data.slice(0,20)})
        });
    }

    handleSearch = (e) => {
        const keyword = e.target.value
        const state = this.state

        if (keyword.length <= 3 && state.searchMode) {
            this.componentDidMount()
            this.setState({searchMode: false})
        } else if (keyword === 'นาย ' || keyword === 'นาง ') {
            //simply do nothing
        } else if (keyword.length > 3) {
            this.orderHistorySearch(keyword)
            this.setState({searchMode: true})
        } 
        this.setState({keyword: keyword})
    }

    
    buttonClick = (id) => {
        this.props.history.push('/home/readFilm?id=' + id)
    }

    render() {
    const state = this.state

    return (
        <div>
            <TextField
                id="outlined-full-width"
                label="ค้นหาผู้ป่วย"
                margin="normal"
                placeholder="ค้นหาผู้ป่วยด้วยคีย์เวิร์ด ชื่อ / นามสกุล / HN ตั้งแต่ 4 ตัวอักษรขึ้นไป"
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
                value={state.keyword}
                onChange={this.handleSearch}
                variant="outlined"
            />
            { state.queues && state.queues.length > 0 && 
                <Paper style={{marginTop: "2rem", maxWidth: "1200px"}}>
                    <Table style={{minWidth: "650px"}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" style={{paddingLeft: "2.1rem"}}>ความด่วน</TableCell>
                            <TableCell align="center">ผู้ป่วย</TableCell>
                            <TableCell align="center">รายการตรวจ</TableCell>
                            <TableCell align="center">วันที่อ่าน</TableCell>
                            <TableCell align="center">DF</TableCell>
                            <TableCell align="center">เมนู</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.queues.map(x => {
                            let centerName = x.xrayCenter
                            centerName = 'ศูนย์' + centerName.substr(centerName.indexOf(" ") + 1, centerName.length)
                            const date = moment(x.diagDate)
                            let df = 0
                            x.option.map ( o => {
                                df += parseInt(o.dfDoctor)
                                return o
                            })
                            return (
                                <TableRow key={x._id}>
                                    <TableCell component="th" scope="row" align="left">
                                        <Chip label={x.urgency} style={{marginLeft: "1rem", backgroundColor: `${urgency2Color(x.urgency)}`}}/>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{display: "block"}}>{x.patient}</div>
                                        <div style={{display: "block", fontSize: "0.8rem", color: "grey"}}>{centerName}</div>
                                    </TableCell>
                                    <TableCell>
                                        {x.option.map( (o, idx) => {
                                            return <div key={o._id} style={{display: "block"}}>{o.serviceName}</div>
                                        })}
                                    </TableCell>
                                    <TableCell align="center">
                                        <div style={{display: "block"}}>{date.format("H:mm")}</div>
                                        <div style={{display: "block"}}>{ toBuddhistYear( date, "D/M/YYYY")}</div>
                                    </TableCell>
                                    <TableCell align="center">
                                        {df + ' บาท'} 
                                    </TableCell>
                                    <TableCell align="center">
                                        <Fab variant="extended" aria-label="like" size="small" style={cssFab} onClick={() => this.buttonClick(x._id)}>
                                            <PhotoIcon style={{marginRight: "0.5rem"}}/>
                                            ผลอ่านภาพ
                                        </Fab>
                                        <Fab variant="extended" aria-label="like" size="small" style={cssFab} onClick={() => window.open('/home/history?id=' + x.patientId)}>
                                            <HistoryIcon />
                                            ประวัติ
                                        </Fab>
                                    </TableCell>
                                </TableRow>
                            )})}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[20]}
                                rowsPerPage={20}
                                colSpan={0}
                                count={state.totalQueues}
                                page={state.page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onChangePage={this.handleChangePage}
                            />
                        </TableRow>
                    </TableFooter>
                    </Table>
                </Paper>
            }
            { state.queues && state.queues.length === 0 && <h2>ไม่มีภาพรออ่านในคิว</h2> }
        </div>   
    );
  }
}
