import React, {Component} from 'react';
import queryString from 'query-string';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import Radio from '@material-ui/core/Radio';
import Paper from '@material-ui/core/Paper';
import RadioGroup from '@material-ui/core/RadioGroup';
import XrayCenterSelect from './component/XrayCenterSelect';
import { getDateString } from './service/utils';

const cssTopic = {
    display: 'inline-block',
    marginLeft: '2rem',
    width: '100px'
}
const cssTopicChoice = {
    display: 'inline-block',
    margin: '0 0 1rem 4rem ',
    width: '400px'
}

export default class User extends Component {
    constructor() {
        super();
        this.state = {
            user: {
                title: '',
                titleEn: '',
                role: 'user',
                xrayCenter: '',
                email: '',
                firstname: '',
                firstnameEn: '',
                lastname: '',
                lastnameEn: '',
                license: '',
                theEmail: '',
                bankInfo: '',
                active: true
            },
            createNew: true,
            xrayCenter: [],
            lineUser: null,
        }
    }    

    

    componentDidMount() {
        const query = queryString.parse(this.props.location.search); 
        const userId = query.id
        if (this.props.location.search) {
            fetch(process.env.REACT_APP_API_URL + '/api/userById?_id=' + userId)
            .then(res => res.json())
            .then(x => {
                const user = x.data
                this.setState({ user: user , createNew: false})
                this.props.changeTitle('ผู้ใช้ ' + user.firstname + ' ' + user.lastname)
                document.title = 'ผู้ใช้ ' + user.firstname + ' ' + user.lastname
            });

            const qs = require('qs')
            const ids = qs.stringify({userIds: [userId]})
            fetch(`${process.env.REACT_APP_API_URL}/api/line/checkUser?${ids}`)
            .then(res => res.json())
            .then(x => {
                if (x.ok) {
                    let lineUser = null
                    if (x.data && x.data.length > 0) {
                        lineUser = x.data[0]
                    }
                    this.setState({lineUser: lineUser})
                }
            });
    
        } else {
            this.props.changeTitle('เพิ่มผู้ใช้ใหม่')
            document.title = 'เพิ่มผู้ใช้ใหม่'
        }
        fetch(process.env.REACT_APP_API_URL + `/api/xrayCenter?active=true` )
        .then(res => res.json())
        .then(x => {
            let xrayCenter = x.data
            this.setState({xrayCenter: xrayCenter})
        });
    }


    onSubmit = () => {
        const state = this.state
        if (!state.createNew) {
            const { __v, password, ...data} = state.user
            fetch( process.env.REACT_APP_API_URL + '/api/user', {
                method: 'PATCH',
                body: JSON.stringify(data),
                headers: {
                'Content-Type': 'application/json'
                }
            })
            .then(res => res.json())
            .then ( ( x ) => {
                if(x.updated){
                    alert('บันทึกเรียบร้อย')
                    this.props.history.push('/home/allUser')
                } else {
                    alert('เกิดข้อผิดพลาด')
                }
            })
        } else {
            let data = state.user
            if (data.role !== 'user') {
                delete data["xrayCenter"]
            }
            data.email = data.email.toLowerCase()
            data.email = data.email.trim()
            data.password = ''
            fetch( process.env.REACT_APP_API_URL + '/api/register', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                'Content-Type': 'application/json'
                }
            })
            .then(res => res.json())
            .then ( ( x ) => {
                if (x.ok) {
                    alert('เพิ่มผู้ใช้ใหม่เรียบร้อย')
                    this.props.history.push('/home/allUser')
                } else {
                    alert('เกิดข้อผิดพลาด')
                }
            })
        }
    }

    resetPassword = () => {
        const data = {
            _id: this.state.user._id,
            password: this.state.user.email + '%034r3d5'
        }
        fetch( process.env.REACT_APP_API_URL + '/api/changePassword', {
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(res => res.json())
          .then ( ( x ) => {
              if( x.ok ){
                alert('เปลี่ยนพาสเวิร์ดแล้ว')
                this.props.history.push('/home/allUser')
              } else {
                alert('เกิดข้อผิดพลาดในการแก้ไขพาสเวิร์ด')
              }
          })
    }

    deleteLineUser = () => {
        const data = {
            _id: this.state.lineUser._id,
            active: false
        }
        fetch( `${process.env.REACT_APP_API_URL}/api/line/user`, {
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(res => res.json())
          .then ( ( x ) => {
              if( x.updated ){
                alert('ลบไลน์แล้ว')
                window.location.reload()
              } else {
                alert('เกิดข้อผิดพลาดในการลบไลน์')
              }
          })
    }

    render() {
    const state = this.state
    return (
        <div>
            {!state.createNew && <h2>แก้ไขข้อมูล</h2>}
            <div style={{ display: "flex", height: "4rem" , verticalAlign: "top"}}>
                <div style={{...cssTopic, height: "4rem"}}><div style={{display: "block", marginTop: "1rem"}}>ชื่อผู้ใช้</div></div>
                <div style={cssTopicChoice}>
                    <TextField
                        style={{backgroundColor: "white"}}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        value={state.user.email}
                        onChange={ (e) => this.setState({ user: {...state.user, email: e.target.value } })}
                    />
                </div>
            </div>
            <div style={{ display: "flex", height: "3rem" , verticalAlign: "top"}}>
                <div style={{...cssTopic}}><div style={{display: "block", marginTop: "0.7rem"}}>ประเภทผู้ใช้</div></div>
                <div style={cssTopicChoice}>
                    <RadioGroup aria-label="position" onChange={(e) => this.setState({ user: {...state.user, role: e.target.value } })} row >
                        <FormControlLabel
                            value="user"
                            control={<Radio color="primary" />}
                            label="User"
                            labelPlacement="end"
                            checked={state.user.role === "user"}
                        />
                        <FormControlLabel
                            value="doctor"
                            control={<Radio color="primary" />}
                            label="Doctor"
                            labelPlacement="end"
                            checked={state.user.role === "doctor"}
                        />
                        <FormControlLabel
                            value="office"
                            control={<Radio color="primary" />}
                            label="Office"
                            labelPlacement="end"
                            checked={state.user.role === "office"}
                        />
                        <FormControlLabel
                            value="admin"
                            control={<Radio color="primary" />}
                            label="Admin"
                            labelPlacement="end"
                            checked={state.user.role === "admin"}
                        />
                    </RadioGroup>
                </div>
            </div>
            { state.user.role === "user" && state.xrayCenter && 
                <div style={{ display: "flex", height: "4rem" , verticalAlign: "top"}}>
                    <div style={{...cssTopic, height: "4rem"}}><div style={{display: "block", marginTop: "1rem"}}>ศูนย์</div></div>
                    <div style={cssTopicChoice}>
                        <XrayCenterSelect list={state.xrayCenter} handleSeleted={(e) => this.setState({ user: {...state.user, xrayCenter: e.target.value } })} value={state.user.xrayCenter}/> 
                    </div>
                </div>
            }
            <div style={{ display: "flex", height: "4rem" , verticalAlign: "top"}}>
                <div style={{...cssTopic, height: "4rem"}}><div style={{display: "block", marginTop: "1rem"}}>คำนำหน้า</div></div>
                <div style={cssTopicChoice}>
                    <TextField
                        style={{backgroundColor: "white"}}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        value={state.user.title || ''}
                        onChange={ (e) => this.setState({ user: {...state.user, title: e.target.value } })}
                    />
                </div>
            </div>
            <div style={{ display: "flex", height: "4rem" , verticalAlign: "top"}}>
                <div style={{...cssTopic, height: "4rem"}}><div style={{display: "block", marginTop: ".5rem"}}>คำนำหน้า<br/>ภาษาอังกฤษ</div></div>
                <div style={cssTopicChoice}>
                    <TextField
                        style={{backgroundColor: "white"}}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        value={state.user.titleEn ? state.user.titleEn : ''}
                        onChange={ (e) => this.setState({ user: {...state.user, titleEn: e.target.value } })}
                    />
                </div>
            </div>
            <div style={{ display: "flex", height: "4rem" , verticalAlign: "top"}}>
                <div style={{...cssTopic, height: "4rem"}}><div style={{display: "block", marginTop: "1rem"}}>ชื่อ</div></div>
                <div style={cssTopicChoice}>
                    <TextField
                        style={{backgroundColor: "white"}}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        value={state.user.firstname}
                        onChange={ (e) => this.setState({ user: {...state.user, firstname: e.target.value } })}
                    />
                </div>
            </div>
            <div style={{ display: "flex", height: "4rem" , verticalAlign: "top"}}>
                <div style={{...cssTopic, height: "4rem"}}><div style={{display: "block", marginTop: ".5rem"}}>ชื่อ<br/>ภาษาอังกฤษ</div></div>
                <div style={cssTopicChoice}>
                    <TextField
                        style={{backgroundColor: "white"}}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        value={state.user.firstnameEn || ''}
                        onChange={ (e) => this.setState({ user: {...state.user, firstnameEn: e.target.value } })}
                    />
                </div>
            </div>
            <div style={{ display: "flex", height: "4rem" , verticalAlign: "top"}}>
                <div style={{...cssTopic, height: "4rem"}}><div style={{display: "block", marginTop: "1rem"}}>นามสกุล</div></div>
                <div style={cssTopicChoice}>
                    <TextField
                        style={{backgroundColor: "white"}}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        value={state.user.lastname || ''}
                        onChange={ (e) => this.setState({ user: {...state.user, lastname: e.target.value } })}
                    />
                </div>
            </div>
            <div style={{ display: "flex", height: "4rem" , verticalAlign: "top"}}>
                <div style={{...cssTopic, height: "4rem"}}><div style={{display: "block", marginTop: ".5rem"}}>นามสกุล<br/>ภาษาอังกฤษ</div></div>
                <div style={cssTopicChoice}>
                    <TextField
                        style={{backgroundColor: "white"}}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        value={state.user.lastnameEn ? state.user.lastnameEn : ''}
                        onChange={ (e) => this.setState({ user: {...state.user, lastnameEn: e.target.value } })}
                    />
                </div>
            </div>
            {state.user.role === 'doctor' &&
                <React.Fragment>
                    <div style={{ display: "flex", height: "4rem" , verticalAlign: "top"}}>
                        <div style={{...cssTopic, height: "4rem"}}><div style={{display: "block", marginTop: "1rem"}}>เลข ว.</div></div>
                        <div style={cssTopicChoice}>
                            <TextField
                                style={{backgroundColor: "white"}}
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                value={state.user.license || ''}
                                onChange={ (e) => this.setState({ user: {...state.user, license: e.target.value } })}
                            />
                        </div>
                    </div>
                    <div style={{ display: "flex", height: "4rem" , verticalAlign: "top"}}>
                        <div style={{...cssTopic, height: "4rem"}}><div style={{display: "block", marginTop: "1rem"}}>ข้อมูลบัญชี</div></div>
                        <div style={cssTopicChoice}>
                            <TextField
                                style={{backgroundColor: "white"}}
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                value={state.user.bankInfo || ''}
                                onChange={ (e) => this.setState({ user: {...state.user, bankInfo: e.target.value } })}
                            />
                        </div>
                    </div>
                </React.Fragment>
            }
            <div style={{ display: "flex", height: "4rem" , verticalAlign: "top"}}>
                <div style={{...cssTopic, height: "4rem"}}><div style={{display: "block", marginTop: "1rem"}}>E-mail</div></div>
                <div style={cssTopicChoice}>
                    <TextField
                        style={{backgroundColor: "white"}}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        value={state.user.theEmail || ''}
                        onChange={ (e) => this.setState({ user: {...state.user, theEmail: e.target.value } })}
                    />
                </div>
            </div>
   
            <div style={{ display: "flex", height: "4rem" , verticalAlign: "top"}}>
                <div style={{...cssTopic, height: "4rem"}}><div style={{display: "block", marginTop: "1rem"}}>สถานะผู้ใช้</div></div>
                <div style={cssTopicChoice}>
                    <FormControlLabel
                        control={
                        <Switch
                            checked={Boolean(state.user.active)}
                            onChange={(e) => this.setState({ user: {...state.user, active: e.target.checked } }) }
                            color="primary"
                        />
                        }
                        label="เปิด"
                        style={{marginTop: "0.5rem"}}
                    />
                </div>
            </div>
            <div style={{ display: "flex", verticalAlign: "top"}}>
                <div style={{...cssTopic}}>การเชื่อมต่อไลน์</div>
                <div style={cssTopicChoice}>
                    { state.lineUser ? 
                        <React.Fragment>
                            เชื่อมต่อไลน์แล้วเมื่อวันที่ {getDateString( new Date(state.lineUser.date), true)}
                            <Paper style={{padding: '1rem', marginTop: '0.5rem'}}>
                                <Button style={{ margin: 10 }} variant="contained" color="secondary" onClick={this.deleteLineUser}>ลบไลน์</Button>
                                <br/>*ไลน์เก่าจะไม่ได้รับการแจ้งเตือนหลังจากถูกลบ
                                <br/>**หากต้องการรับการแจ้งเตือนใหม่ ต้องให้ผู้ใช้ทำการลงทะเบียนอีกครั้ง
                            </Paper>
                        </React.Fragment>
                        :  'ยังไม่ได้เชื่อมต่อ' 
                    }
                </div>
            </div>
            <div style={{ display: "flex", height: "4rem" , verticalAlign: "top"}}>
                <div style={{...cssTopic, height: "4rem"}}><div style={{display: "block", marginTop: "1rem"}}>พาสเวิร์ด</div></div>
                <div style={cssTopicChoice}>
                    <Button style={{ margin: 10 }} variant="contained" color="secondary" onClick={this.resetPassword}>รีเซ็ตพาสเวิร์ด</Button>
                    *จะเป็นพาสเวิร์ดเริ่มต้นคือ ชื่อผู้ใช้%034r3d5
                </div>
            </div>
            <Button style={{ margin: 10 }} variant="contained" color="primary" onClick={this.onSubmit}>บันทึก</Button>
        </div>    
    );
  }
}