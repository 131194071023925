import React, {Component} from 'react';
import queryString from 'query-string';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ServiceGroupSelect from './component/ServiceGroupSelect';

const cssTopic = {
    display: 'inline-block',
    marginLeft: '2rem',
    width: '100px'
}
const cssTopicChoice = {
    display: 'inline-block',
    margin: '0 0 1rem 4rem ',
    width: '400px'
}

export default class EditService extends Component {
    constructor() {
        super();
        this.state = {
            service: {
                serviceName: '',
                code: '',
                serviceGroup: '',
                price: '',
                xrayCenter: ''
            },
            createNew: false
        }
    }    

    

    componentDidMount() {
        const query = queryString.parse(this.props.location.search); 
        if (query.id) {
            fetch(process.env.REACT_APP_API_URL + '/api/serviceById?_id=' + query.id)
            .then(res => res.json())
            .then(x => {
                const service = x.data[0]
                this.setState({ service: service })
                this.props.changeTitle( 'แก้ไขรายการตรวจ ' + service.serviceName )
                document.title = 'แก้ไขรายการตรวจ ' + service.serviceName
            });
        } else if (query.xrayCenter) {
            this.setState({ createNew: true , service: {...this.state.service, xrayCenter: query.xrayCenter }})
            this.props.changeTitle( 'เพิ่มรายการตรวจ' )
            document.title = 'เพิ่มรายการตรวจ'
        }
    }


    onSubmit = () => {
        const state = this.state
        const {__v, ...data} = state.service
        let method = 'PATCH'
        if (state.createNew) {
            method = 'POST'
        }
        fetch( process.env.REACT_APP_API_URL + '/api/service', {
            method: method,
            body: JSON.stringify(data),
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(res => res.json())
          .then ( ( x ) => {
            if(x.updated){
                alert('บันทึกเรียบร้อย')
                this.props.history.push('/home/service?id=' + data.xrayCenter)
            } else if (x.ok) {
                alert('เพิ่มรายการตรวจเรียบร้อย')
                this.props.history.push('/home/service?id=' + data.xrayCenter)
            } else {
                alert('เกิดข้อผิดพลาด')
            }
          })
    }

    render() {
    const state = this.state
    return (
        <div>
            {!state.createNew && <h2>แก้ไขข้อมูล</h2>}
            <div style={{ display: "flex", height: "4rem" , verticalAlign: "top"}}>
                <div style={{...cssTopic, height: "4rem"}}><div style={{display: "block", marginTop: "1rem"}}>ชื่อรายการตรวจ</div></div>
                <div style={cssTopicChoice}>
                    <TextField
                        style={{backgroundColor: "white"}}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        value={state.service.serviceName}
                        onChange={ (e) => this.setState({ service: {...state.service, serviceName: e.target.value } })}
                    />
                </div>
            </div>
            <div style={{ display: "flex", height: "4rem" , verticalAlign: "top"}}>
                <div style={{...cssTopic, height: "4rem"}}><div style={{display: "block", marginTop: "1rem"}}>รหัสกรมบัญชีกลาง</div></div>
                <div style={cssTopicChoice}>
                    <TextField
                        style={{backgroundColor: "white"}}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        value={state.service.code}
                        onChange={ (e) => this.setState({ service: {...state.service, code: e.target.value } })}
                    />
                </div>
            </div>
            <div style={{ display: "flex", height: "4rem" , verticalAlign: "top"}}>
                <div style={{...cssTopic, height: "4rem"}}><div style={{display: "block", marginTop: "1rem"}}>ราคา</div></div>
                <div style={cssTopicChoice}>
                    <TextField
                        style={{backgroundColor: "white"}}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        value={state.service.price}
                        onChange={ (e) => this.setState({ service: {...state.service, price: e.target.value } })}
                    />
                </div>
            </div>
            <div style={{ display: "flex", height: "4rem" , verticalAlign: "top"}}>
                <div style={{...cssTopic, height: "4rem"}}><div style={{display: "block", marginTop: "1rem"}}>ประเภท</div></div>
                <div style={cssTopicChoice}>
                    <ServiceGroupSelect handleSeleted={ (e) => this.setState({ service: {...state.service, serviceGroup: e.target.value } })} value={state.service.serviceGroup}/>
                </div>
            </div>
            <div style={{ display: "flex", height: "4rem" , verticalAlign: "top"}}>
                <div style={{...cssTopic, height: "4rem"}}><div style={{display: "block", marginTop: "1rem"}}>ประเภทย่อย</div></div>
                <div style={cssTopicChoice}>
                    <TextField
                        style={{backgroundColor: "white"}}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        value={state.service.serviceSubGroup}
                        onChange={ (e) => this.setState({ service: {...state.service, serviceSubGroup: e.target.value } })}
                    />
                </div>
            </div>
            <Button style={{ margin: 10 }} variant="contained" color="primary" onClick={this.onSubmit}>บันทึก</Button>
        </div>    
    );
  }
}