import React, { Component } from 'react';
import queryString from 'query-string';
import moment from 'moment';
import { FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import AutoComplete from './component/AutoComplete';

import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import Chip from '@material-ui/core/Chip';
import PhotoIcon from '@material-ui/icons/Photo';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import SendIcon from '@material-ui/icons/Send';
import { urgency2Color, sortAttachment, toBuddhistYear } from './service/utils';
import Memo from './component/Memo';
import OrderStatusTag from './component/OrderStatusTag';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const cssDetail = {
    margin: '1rem 2rem'
}

const cssRow = {
    margin: '0 0 1rem 0',
    verticalAlign: "top"
}

const cssTopic = {
    display: 'inline-block',
    marginLeft: '2rem',
    width: '100px',
    verticalAlign: "top"
}
const cssTopicChoice = {
    display: 'inline-block'
}

const cssFab = {
    marginRight: "1rem"
}

const cssFileLink = {
    color: "black",
    "&:visited": {
        color: "black"
    }
}

export default class Upload extends Component {
    constructor() {
        super();
        this.state = {
            order: '',
            editDoctor: false,
            xrayCenter: '',
            user: ''
        }
        this.myRef = React.createRef();
    }


    componentDidMount() {
        document.title = 'อัพโหลดภาพ'
        const query = queryString.parse(this.props.location.search);
        let xrayCenter = JSON.parse(localStorage.getItem('xrayCenter'))

        fetch(process.env.REACT_APP_API_URL + '/api/orderById?id=' + query.id)
            .then(res => res.json())
            .then(x => {
                const order = x.data
                this.setState({ order: order })
                this.props.changeTitle('อัพโหลดภาพ (' + order.patient + ')')
            });

        const user = JSON.parse(localStorage.getItem('user'))
        this.setState({ xrayCenter: xrayCenter, creator: user.firstname + ' ' + user.lastname, creatorId: user.id, user: user })

    }

    onLoaded = res => {
        const state = this.state
        fetch(process.env.REACT_APP_API_URL + '/api/orderById?id=' + state.order._id)
            .then(res => res.json())
            .then(x => {
                const order = x.data
                this.setState({ order: order })
            });
    };

    deleteClick = (id) => {
        fetch(process.env.REACT_APP_API_URL + '/api/upload/' + id, {
            method: 'DELETE',
        })
            .then(res => res.json())
            .then((x) => {
                if (x.deleted) {
                    this.setState({ order: x.data })
                } else {
                    alert('เกิดข้อผิดพลาดในการลบ')
                }
            })

    }

    editDoctor = () => {
        this.setState({ editDoctor: true })
    }

    setDoctor = (d) => {
        const data = {
            _id: this.state.order._id,
            doctor: d.label,
            doctorId: d._id
        }
        fetch(process.env.REACT_APP_API_URL + '/api/order/editDoctor', {
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((x) => {
                if (x.updated) {
                    this.setState({ order: x.data, editDoctor: false })
                } else {
                    alert('เกิดข้อผิดพลาดในการแก้ไข รังสีแพทย์')
                }
            })
    }

    sendToDoctor = () => {
        const state = this.state
        const data = {
            _id: state.order._id,
            isUploaded: true,
        }
        fetch(process.env.REACT_APP_API_URL + '/api/order/send', {
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((x) => {
                if (x.updated) {
                    this.setState({ order: x.data })
                    this.props.history.push('/home/uploadQueue')
                } else {
                    alert('เกิดข้อผิดพลาด')
                }
            })
    }

    setDone = () => {
        const state = this.state
        const data = {
            _id: state.order._id,
            isUploaded: true,
            isDoctorRead: true,
            isDiag: true,
            isDone: true,
            doctorReadDate: new Date().toISOString(),
            diagDate: new Date().toISOString(),
            doneDate: new Date().toISOString(),
            diag: "อ่านผลที่ศูนย์"
        }
        fetch(process.env.REACT_APP_API_URL + '/api/order/setIsDiag', {
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((x) => {
                if (x.updated) {
                    this.props.history.push('/home/readFilm?id=' + state.order._id)
                } else {
                    alert('เกิดข้อผิดพลาด')
                }
            })
    }

    render() {
        const state = this.state
        const urgencyColor = urgency2Color(state.order.urgency)

        let attachment = state.order.attachment
        if (attachment) {
            attachment = sortAttachment(attachment)
        }
        return (
            <div>
                {state.order && <div style={{ display: 'inline-block', width: "480px", borderRight: "1px solid gray", marginRight: "3rem" }}>
                    <div>
                        <h2>
                            ข้อมูลผู้ป่วย
                            <OrderStatusTag order={state.order} />
                        </h2>
                        <div style={cssDetail}>
                            {state.order.patient} | HN {state.order.hn}
                            {state.order.age ? ' | อายุ ' + state.order.age : ' | อายุ -'}{' | สิทธิ์ '}{state.order.payment}
                        </div>
                        <div>
                            <div style={cssTopic}>Ward</div>
                            <div style={cssTopicChoice}>
                                {state.order.ward}
                            </div>
                        </div>
                    </div>
                    <div>
                        <h2>รายการตรวจ<Chip label={state.order.urgency} style={{ marginLeft: "1rem", backgroundColor: `${urgencyColor}` }} /></h2>
                        <div style={cssRow}>
                            <div style={cssTopic}>รายการตรวจ</div>
                            <div style={cssTopicChoice}>

                                {state.order.option.map((o) => {
                                    return <div key={o._id} style={{ display: "block" }}>{o.serviceName} (จำนวน {o.amount})</div>
                                })}
                            </div>
                        </div>
                        <div style={cssRow}>
                            <div style={cssTopic}>วันที่ตรวจ</div>
                            <div style={cssTopicChoice}>
                                {toBuddhistYear(moment(state.order.createDate), "H:mm D/M/YYYY")}
                            </div>
                        </div>
                        <div style={cssRow}>
                            <div style={cssTopic}>วันรับผล</div>
                            <div style={cssTopicChoice}>
                                {state.order.expectReportDate ? toBuddhistYear(moment(state.order.expectReportDate), "D/M/YYYY") : "ไม่มีข้อมูล"}
                            </div>
                        </div>
                        <div style={cssRow}>
                            <div style={cssTopic}>แพทย์ส่งตรวจ</div>
                            <div style={cssTopicChoice}>
                                {state.order.sender}
                            </div>
                        </div>
                        <div style={cssRow}>
                            <div style={{ ...cssTopic, verticalAlign: "center" }}>รังสีแพทย์</div>
                            <div style={cssTopicChoice}>
                                {!state.editDoctor && <div>
                                    {!state.order.isUploaded &&
                                        <Fab variant="extended" aria-label="like" size="small" style={cssFab} onClick={this.editDoctor}>
                                            <EditIcon /> แก้ไข
                                        </Fab>
                                    }

                                    {state.order.doctor}
                                </div>}
                                {state.editDoctor &&
                                    <AutoComplete endPoint={"/api/doctor"} onChange={(x) => this.setDoctor(x)} width="300px" />
                                }

                            </div>
                        </div>
                    </div>
                    <h2>อัพโหลดภาพ</h2>
                    <div style={{ flex: 1, paddingRight: '1rem' }}>
                        <FilePond allowRevert={false} allowMultiple={true} maxFiles={5}
                            labelIdle='ลากไฟล์มาวาง ไม่เกินทีละ 5 ไฟล์ <span class="filepond--label-action">เลือกไฟล์ </span>'
                            server={{
                                url: '/api/upload',
                                process: {
                                    onload: this.onLoaded,
                                    ondata: (formData) => {
                                        for (var pair of formData.entries()) {
                                            console.log(pair[0] + ', ' + pair[1]);
                                        }
                                        formData.append('creator', state.creator);
                                        formData.append('creatorId', state.creatorId);
                                        formData.append('_id', state.order._id);
                                        const filename = formData.getAll('filepond')[1].name
                                        formData.append('filename', filename);
                                        return formData;
                                    },
                                },
                            }}

                        />

                        {!state.order.isUploaded &&
                            <div>
                                <Fab variant="extended" aria-label="like" size="small" style={cssFab} onClick={this.sendToDoctor} color="primary">
                                    <SendIcon style={{ margin: "0 0.2rem" }} /> ส่งให้รังสีแพทย์
                                </Fab>
                                <Fab variant="extended" aria-label="like" size="small" style={{ ...cssFab, backgroundColor: '#1de9b6' }} onClick={this.setDone}>
                                    <CheckCircleIcon style={{ marginRight: "0.5rem" }} />
                                    แพทย์อ่านที่ศูนย์
                                </Fab>
                            </div>
                        }
                    </div>
                </div>}
                <div style={{ display: "inline-block", verticalAlign: "top" }}>
                    {state.order.attachment && state.order.attachment.length > 0 && <div>
                        <h2>ไฟล์ในระบบ</h2>
                        <Paper style={{ marginTop: "2rem" }}>
                            <Table style={{ width: "700px" }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">ชื่อไฟล์</TableCell>
                                        <TableCell align="center">ผู้อัพโหลด</TableCell>
                                        <TableCell align="center">วันที่</TableCell>
                                        <TableCell align="center">เมนู</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {state.order.attachment.map(x => {
                                        const temp = x.name
                                        const filename = temp.substr(temp.indexOf('_') + 1, temp.length)
                                        let isFilm = true
                                        if (x.type === 'other')
                                            isFilm = false
                                        return (
                                            <TableRow key={x._id}>
                                                <TableCell>
                                                    {isFilm && <div style={{ display: "inline-block", marginRight: "0.5rem" }}><AccessibilityIcon /></div>}
                                                    {!isFilm && <div style={{ display: "inline-block", marginRight: "0.5rem" }}><PhotoIcon /></div>}
                                                    <div style={{ display: "inline-block", verticalAlign: "top", paddingTop: "0.2rem" }}>
                                                        <a href={process.env.REACT_APP_API_URL + '/api/upload?id=' + x._id} style={{ ...cssFileLink }}>
                                                            {filename}
                                                        </a>
                                                    </div>
                                                </TableCell>
                                                <TableCell>{x.uploader.substr(0, 7) + "..."}</TableCell>
                                                <TableCell align="center">{toBuddhistYear(moment(x.date), 'H:mmน. D/M/YYYY')}</TableCell>
                                                <TableCell align="center">
                                                    <Fab variant="extended" aria-label="like" size="small" style={cssFab} onClick={() => this.deleteClick(x._id)}>
                                                        <DeleteIcon />
                                                        ลบไฟล์
                                                    </Fab>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </Paper>
                    </div>}
                    {!state.order.attachment && <h2>ไม่พบไฟล์ในระบบ</h2>}
                    {state.order && <Memo order={state.order} user={state.user.firstname} userId={state.user.id} />}
                </div>
            </div>
        )
    }
}