import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

export default function LineRegisterDone(props) {
    return (
  
          <Grid container  direction="column" justify="center" alignItems="center" spacing={3} style={{ minHeight: '100vh' }}>
              <Typography align="center" style={{marginTop: "2rem"}}>
                  ลงทะเบียนสำเร็จ<br/>กรุณาปิดหน้าต่าง
              </Typography>
          </Grid>
  
    );
  }