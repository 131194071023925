import React, {Component} from 'react';
import moment from 'moment';
import AutoComplete from './component/AutoComplete';
import _ from 'lodash'
import { formatNumber } from './service/utils';
import DateFnsUtils from '@date-io/date-fns';
import {  MuiPickersUtilsProvider,  KeyboardDatePicker, } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { createExcel } from './service/createExcel';

export default class ReportMed extends Component {
	constructor(props) {
		super(props);
		this.state = {
            data: '', 
            from: moment().format("YYYY-MM-DD"),
            to:  moment().format("YYYY-MM-DD"),
            xrayCenter: '',
            user: '',
            xrayCenterAll: '',
		};
		this.exportFile = this.exportFile.bind(this);
    };
    
    componentDidMount(){
        this.props.changeTitle('รายงานยา')
        document.title = 'รายงานยา'

        let xrayCenter = JSON.parse(localStorage.getItem('xrayCenter'))
        const user = JSON.parse(localStorage.getItem('user'))
        this.setState({ xrayCenter: xrayCenter, user: user })

        if (user.role === 'admin' || user.role === 'office') {
            fetch(process.env.REACT_APP_API_URL + '/api/xrayCenter?active=true')
            .then(res => res.json())
            .then(y => {
                let data = y.data
                data.map ( x => {
                    x.label = x.name
                    return x
                })
                this.setState({xrayCenterAll: data})
            });
        }
        
    }

	exportFile = () => {
        const state = this.state
        const from = moment(state.from).format('YYYY-MM-DD')
        const to = moment(state.to).add(1,'days').format('YYYY-MM-DD')
        
        if ( parseInt( moment(to).diff(moment(from), 'days' )) > 185 ) {
            alert('ไม่สามารถออกรายงานที่มากกว่า 180 วันได้ กรุณาลดจำนวนวัน')
            return
        }

                if (!this.state.xrayCenter) {
            alert('กรุณาระบุศูนย์')
            return
        }

        fetch(process.env.REACT_APP_API_URL + '/api/orderByDate?isDeleted=false&isDone=true&from=' + from + '&to=' + to + '&xrayCenterId=' + this.state.xrayCenter._id)
        .then(res => res.json())
        .then(x => {
            let medOrder = []
            let output = []
            x.data.map( o => {
                const option = o.option
                option.map ( oo => {
                    if (oo.serviceGroup === 'MED') {
                        medOrder.push(oo)
                    }
                    return oo
                })
                return o
            })
            medOrder = _.groupBy(medOrder, 'code')
            Object.keys(medOrder).map ( key => {
                const option = medOrder[key]

                const code = key
                let serviceName = option[0].serviceName
                let amount = 0
                let totalPrice = 0

                option.map( oo => {
                    amount += oo.amount
                    totalPrice += oo.amount * oo.price
                    return oo
                })

                output.push({ serviceName: serviceName, code: code, amount: amount, totalPrice: totalPrice })
                return key
            })

            this.setState({data: output})
        })

    }
    
    exportExcel = () => {
        let header = []
        let rows = []
        header = [
            'ตั้งแต่วันที่','ถึงวันที่','ศูนย์','ชื่อยา', 'รหัส', 'จำนวน',
            'รวมเงิน',
        ]
        rows = []
        this.state.data.map(x => {
            let temp = [
                moment(this.state.from).format("D/M/YYYY"),
                moment(this.state.to).format("D/M/YYYY"),
                this.state.xrayCenter.name,
                x.serviceName,
                x.code,
                x.amount,
                formatNumber(x.totalPrice)
            ]
            rows.push(temp)
            return x

        })
        createExcel(header, rows, `ReportMED_${this.state.xrayCenter.name}_${moment(this.state.from).format("YYYY-MM-DD")}_${moment(this.state.to).format("YYYY-MM-DD")}`)
    }
    
	render() { 
        const state = this.state
        return (
            <div>
                { (state.user.role === 'admin' || state.user.role === 'office') && 
                <div style={{width: "500px", marginBottom: "1rem"}}>
                    <div style={{marginBottom: "0.5rem"}}>เลือกศูนย์</div>
                    {state.xrayCenterAll && <AutoComplete list={state.xrayCenterAll} placeholder="ค้นหาศูนย์" onChange={ (x) => this.setState({xrayCenter: x})}/>}
                </div>}
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div style={{marginRight: '2rem'}}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            label="ตั้งแต่วันที่"
                            value={state.from}
                            onChange={(e) => this.setState({from: e})}
                            KeyboardButtonProps={{
                            'aria-label': 'change date',
                            }}
                        />
                    </div>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        label="ถึงวันที่"
                        value={state.to}
                        onChange={(e) => this.setState({to: e})}
                        KeyboardButtonProps={{
                        'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
                <Button style={{ margin: '1rem 0 0 0', display: "block" }} variant="contained" color="primary" onClick={this.exportFile}>ดึงข้อมูล</Button>
                {state.data && 
                    <Paper style={{marginTop: "2rem", maxWidth: "800px"}}>
                        <Table style={{minWidth: "650px"}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">ชื่อยา</TableCell>
                                <TableCell align="center">รหัส</TableCell>
                                <TableCell align="center">จำนวน</TableCell>
                                <TableCell align="center">รวมเงิน</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {state.data.map(x => {
                                return (
                                    <TableRow key={x.code}>
                                        <TableCell>{x.serviceName}</TableCell>
                                        <TableCell>{x.code}</TableCell>
                                        <TableCell align="right">{x.amount}</TableCell>
                                        <TableCell align="right">{formatNumber(x.totalPrice)}</TableCell>
                                    </TableRow>
                                )})
                            }
                        </TableBody>
                        </Table>
                    </Paper>
                }

                {state.data && 
                    <Button style={{ margin: '1rem 0 0 0', display: "block" }} variant="contained" color="primary" onClick={this.exportExcel}>ดึงข้อมูล Excel</Button>
                }

            </div>
        );      
    };
};
