import React, {Component} from 'react';
import moment from 'moment';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const cssTopic = {
    display: 'inline-block',
    marginLeft: '2rem',
    width: '100px'
}

const cssTopicChoice = {
    display: 'inline-block',
    margin: '0 0 1rem 4rem ',
    width: '400px'
}

export default class EditCase extends Component {
    constructor() {
        super();
        this.state = {
            orderId: '',
        }
    }

    componentDidMount() {
        this.props.changeTitle('แก้ไขส่งตรวจ')
        document.title = 'แก้ไขส่งตรวจ'
    }

    fetchOrder = () => {
        const state = this.state
        fetch(process.env.REACT_APP_API_URL + '/api/orderById?id=' + this.state.orderId)
        .then(res => res.json())
        .then(x => {
            if (x.ok) {
                x = x.data
                if ( moment(x.createDate) < moment().subtract(6, "months") ) {
                    alert('เคสเก่ากว่า 6 เดือน ไม่สามารถแก้ไขได้')
                    this.props.history.push('/home/allQueue')
                } else {
                    this.props.history.push('/home/sendXray?orderId=' + state.orderId)
                }
                
            } else {
                alert('รหัสเคสไม่ถูกต้อง')
                this.setState({orderId: ''})
            }
        });
    }

    render() {
    const state = this.state
      
    return (
        <div>
            <div style={{ verticalAlign: "top", marginBottom: "1rem" }}>
                <div style={{ display: "flex" }}>
                    <div style={{...cssTopic, height: "4rem"}}><div style={{display: "block", marginTop: "1rem"}}>รหัสเคส</div></div>
                    <div style={cssTopicChoice}>
                        <TextField
                            style={{backgroundColor: "white"}}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={state.orderId}
                            onChange={ (e) => this.setState({ orderId: e.target.value }) }
                        />
                    </div>
                </div>
                <Button variant="contained" color="primary" onClick={ this.fetchOrder } style={{margin: "0 0 2rem 2rem"}}>ดึงข้อมูล</Button>
            </div>         
        </div>   
    );
  }
}
