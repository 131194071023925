import React, {useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';


export default function AutoComplete({autoClear = false,  list, endPoint, onChange, width, style, placeholder}) {

  const [items, setItems] = useState([]);
  const [value, setValue] = useState('')

  useEffect( () => {
    if (endPoint){
      fetch(process.env.REACT_APP_API_URL + endPoint)
      .then(res => res.json())
      .then(x => {
          let doctors = x.data
          doctors.map( d => d.label = d.title + ' ' + d.firstname + ' ' + d.lastname)
          setItems(doctors)            
      });
    } else {
      setItems(list)
    }
  }, [])

  const handleChange = (e, value, reason) => {
    if (value) {
      onChange(value);
    }
  }

  const handleInputChange = (e, value, reason) => {
    if (reason !== 'reset')  {
      setValue(value)
      return
    }

    // reason is 'reset'
    if (autoClear) {
      setValue('')
    } else {
      setValue(value)
    }
  }



  return (
    <Autocomplete
      options={items}
      getOptionLabel={(a) => a.label}
      style={{ width: `${ width ? width : '400px'}`, style }}
      renderInput={(params) => <TextField {...params} label={placeholder} variant="outlined" margin="dense"/>}
      onChange={handleChange}
      onInputChange={handleInputChange}
      inputValue={value}
    />
  );
}