import React, {Component} from 'react';
import queryString from 'query-string';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const cssTopic = {
    display: 'inline-block',
    marginLeft: '2rem',
    width: '100px'
}

const cssTopicChoice = {
    display: 'inline-block',
    margin: '0 0 1rem 4rem ',
    width: '400px'
}

export default class Ward extends Component {
    constructor() {
        super();
        this.state = {
            xrayCenter: '',
            addMode: false,
            addWardName: ''
        }
    }

    componentDidMount() {
        this.props.changeTitle('ศูนย์ทั้งหมด')
        document.title = 'ศูนย์ทั้งหมด'        
        const query = queryString.parse(this.props.location.search); 
        if (this.props.location.search) {
            fetch(process.env.REACT_APP_API_URL + '/api/xrayCenterById?id=' + query.id)
            .then(res => res.json())
            .then(x => {
                let xrayCenter = x.data
                xrayCenter.ward.sort( (a,b) => {
                    return a.name.localeCompare(b.name, 'th')
                } )
                this.setState({ xrayCenter: xrayCenter })
                this.props.changeTitle('Ward ทั้งหมดของ' + xrayCenter.name)
                document.title = 'Ward ทั้งหมดของ' + xrayCenter.name
            });
        } 
    }

    buttonClick = (e) => {
        this.props.history.push(e)
    }

    changeActiveStatus = (e) => {
        let ward = this.state.xrayCenter.ward
        let theWard = ward.filter( x => x._id === e._id)
        theWard = theWard[0]
        theWard.active = !theWard.active
        theWard.wardId = theWard._id
        theWard._id = this.state.xrayCenter._id
        fetch( process.env.REACT_APP_API_URL + '/api/xrayCenter/editWard', {
            method: 'PATCH',
            body: JSON.stringify(theWard),
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(res => res.json())
          .then ( ( x ) => {
            if(x.updated){
                alert('บันทึกเรียบร้อย')
                let xrayCenter = x.data
                xrayCenter.ward.sort( (a,b) => {
                    return a.name.localeCompare(b.name, 'th')
                } )
                this.setState({ xrayCenter: xrayCenter })
            } else {
                alert('เกิดข้อผิดพลาด')
            }
          })
    }

    addWard = () => {
        const data = {
            _id: this.state.xrayCenter._id,
            name: this.state.addWardName
        }
        fetch( process.env.REACT_APP_API_URL + '/api/xrayCenter/addWard', {
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(res => res.json())
          .then ( ( x ) => {
            if(x.updated){
                alert('บันทึกเรียบร้อย')
                let xrayCenter = x.data
                xrayCenter.ward.sort( (a,b) => {
                    return a.name.localeCompare(b.name, 'th')
                } )
                this.setState({ xrayCenter: xrayCenter, addWard: false, addWardName: '' })
            } else {
                alert('เกิดข้อผิดพลาด')
            }
          })
    }

    render() {
    const state = this.state
      
    return (
        <div>
            {!state.addMode && <div>
                <Button variant="contained" color="secondary" onClick={ () => this.setState({addMode: true}) } style={{marginBottom: "1rem"}}>เพิ่ม Ward</Button>
            </div>}
            { state.addMode && 
                <div style={{ verticalAlign: "top", borderBottom: '1px solid #888', marginBottom: "1rem" }}>
                    <div style={{ display: "flex" }}>
                        <div style={{...cssTopic, height: "4rem"}}><div style={{display: "block", marginTop: "1rem"}}>ชื่อ Ward</div></div>
                        <div style={cssTopicChoice}>
                            <TextField
                                style={{backgroundColor: "white"}}
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                value={state.addWardName}
                                onChange={ (e) => this.setState({ addWardName: e.target.value }) }
                            />
                        </div>
                    </div>
                    <Button variant="contained" color="primary" onClick={ this.addWard } style={{margin: "0 0 2rem 2rem"}}>บันทึก</Button>
                </div>
            }
            { state.xrayCenter && 
                <Paper style={{marginTop: "2rem", maxWidth: "400px"}} >
                    <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell  align="center">Ward</TableCell>
                            <TableCell align="center" style={{maxWidth: "50px"}}>สถานะ</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.xrayCenter.ward.map(x => {
                        return (
                            <TableRow key={x._id}>
                                <TableCell>{x.name}</TableCell>
                                <TableCell align="center" style={{maxWidth: "100px"}}>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            checked={Boolean(x.active)}
                                            onChange={ () => this.changeActiveStatus(x) }
                                            color="primary"
                                        />
                                        }
                                        label="เปิด"
                                        style={{ height: "1rem" }}
                                    />
                                </TableCell>
                            </TableRow>
                        )})}
                    </TableBody>
                    </Table>
                </Paper>
            }
        </div>   
    );
  }
}
