import React, {Component} from 'react';
import moment from 'moment';
import AutoComplete from './component/AutoComplete';
import DateFnsUtils from '@date-io/date-fns';
import {  MuiPickersUtilsProvider,  KeyboardDatePicker, } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import { createExcel } from './service/createExcel';

const MAX_PERIOD =  40

export default class ReportDF extends Component {
	constructor(props) {
		super(props);
		this.state = {
            from: moment().format("YYYY-MM-DD"),
            to:  moment().format("YYYY-MM-DD"),
            doctor: '',
            data: '',
            isDoctor: true,
		};
    };
    
    componentDidMount(){
        this.props.changeTitle('รายงาน DF รังสีแพทย์')
        document.title = 'รายงาน DF รังสีแพทย์'
        
        let user = JSON.parse(localStorage.getItem('user'))
        if (user.role !== 'doctor') {
            this.setState({ isDoctor: false })
        }
    }

    fromDateChange = (e) => {
        const state = this.state
        const from = moment(e)
        let to = moment(state.to).add(1,'days')
        if ( to.diff(from, 'days' ) > MAX_PERIOD ) {
            to = from.clone().endOf('month')
            this.setState({from: e, to: to.format('YYYY-MM-DD')})
        } else {
            this.setState({from: e})
        }
        
    }

    getData = (type) => {
        const state = this.state
        const from = moment(state.from).format('YYYY-MM-DD')
        const to = moment(state.to).add(1,'days').format('YYYY-MM-DD')
        
        if ( parseInt( moment(to).diff(moment(from), 'days' )) > MAX_PERIOD ) {
            alert('ไม่สามารถออกรายงานที่มากกว่า 30 วันได้ กรุณาลดจำนวนวัน')
            return
        }

        let doctorId;
        // DOCTOR MODE
        if (state.isDoctor) {
            const user = JSON.parse(localStorage.getItem('user'))
            doctorId = user.id

        // ADMIN MODE
        } else {
            if (!state.doctor) {
                alert('กรุณาระบุรังสีแพทย์')
                return
            }
            doctorId = state.doctor._id
        }

        if (type === 'summary') {
            window.open(`/reportDFPrint?doctor=${doctorId}&from=${from}&to=${to}`)

        } else if (type === 'excel') {
            fetch(`${process.env.REACT_APP_API_URL}/api/orderByDate?isDeleted=false&from=${from}&to=${to}&doctorId=${doctorId}`)
            .then(res => res.json())
            .then(x => {
                let data = x.data
                if (data.length === 0) {
                    alert('ไม่พบข้อมูลในช่วงวันที่ดังกล่าว')
                    return
                }
                let header = []
                let rows = []

                // DOCTOR
                if (state.isDoctor) {
                    header = [
                        'วันที่ตรวจ', 'HN', 'ผู้ป่วย',
                        'รายการ', 'DFรังสี',
                        'ศูนย์', 'ความเร่งด่วน', 'วันที่ขอรับผล', 'วันที่ส่งผลกลับ',
                    ]
                    rows = []
                    data.map (a => {
                        a.option.map( b => {
                            let temp = [
                                moment(a.createDate).format("D/M/YYYY"),
                                a.hn,
                                a.patient,
                                b.serviceName,
                                b.dfDoctor,
                                a.xrayCenter,
                                a.urgency,
                                moment(a.expectReportDate).format("D/M/YYYY"),
                                moment(a.diagDate).format("D/M/YYYY"),
                            ]
                            rows.push(temp)
                            return b
                        })
                        return a
                    })

                // ADMIN
                } else {
                    header = [
                        'วันที่ตรวจ', 'HN', 'ผู้ป่วย',
                        'รายการ', 'จำนวน', 'ราคารวม', 'DFรังสี',
                        'ศูนย์', 'ความเร่งด่วน', 'วันที่ขอรับผล', 'วันที่ส่งผลกลับ',
                    ]
                    rows = []
                    data.map (a => {
                        a.option.map( b => {
                            let temp = [
                                moment(a.createDate).format("D/M/YYYY"),
                                a.hn,
                                a.patient,
                                
                                b.serviceName,
                                b.amount,
                                parseInt(b.amount) * parseInt(b.price),
                                b.dfDoctor,
    
                                a.xrayCenter,

                                a.urgency,
                                moment(a.expectReportDate).format("D/M/YYYY"),
                                moment(a.diagDate).format("D/M/YYYY"),
                            ]
                            rows.push(temp)
                            return b
                        })
                        return a
                    })
                }
                createExcel(header, rows, `ReportDF_${state.isDoctor ? "" : state.doctor.firstname}_${from}_${to}`)
            })
        }
    }

	render() { 
        const state = this.state
        return (
            <div>
                { !state.isDoctor &&
                    <div style={{width: "500px"}}><AutoComplete endPoint={"/api/doctor"} placeholder="ค้นหา รังสีแพทย์" onChange={ (x) => this.setState({doctor: x})}/></div>
                }                
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div style={{marginRight: '2rem'}}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label="ตั้งแต่วันที่"
                            value={state.from}
                            onChange={this.fromDateChange}
                            KeyboardButtonProps={{
                            'aria-label': 'change date',
                            }}
                        />
                    </div>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="ถึงวันที่"
                        value={state.to}
                        onChange={(e) => this.setState({to: e})}
                        KeyboardButtonProps={{
                        'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>

                <Button style={{ margin: '1rem 0 0 0', display: "block" }} variant="contained" color="primary" onClick={ () => this.getData ('summary')}>ดึงข้อมูลสรุป</Button>
                <Button style={{ margin: '1rem 0 0 0', display: "block" }} variant="contained" color="primary" onClick={ () => this.getData ('excel')}>ดึงข้อมูล Excel</Button>

            </div>
        );      
    };
};
