import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getDateString } from '../service/utils';

export default function LineTestMessageDialog({ onClose, lineMessageReceiver }) {

    const [text, setText] = useState()

    useEffect(() => {
        if (!lineMessageReceiver)
            return
        setText(`ข้อความทดสอบจากระบบ Perfect-Med\nส่งเมื่อ ${getDateString(new Date(), true)}`)
    }, [lineMessageReceiver])

    const handleSubmit = () => {
        if (text.length === 0) {
            alert('กรุณาใส่ข้อความ')
            return
        }

        if (!lineMessageReceiver) {
            return
        }

        const sender = JSON.parse(localStorage.getItem('user'))

        let data = {
            text: text,
            type: 'push-test',
            sender: {
                name: sender.firstname + ' ' + sender.lastname,
                userId: sender.id
            },
            receiver: lineMessageReceiver
            
        }

        fetch( process.env.REACT_APP_API_URL + '/api/line/pushMessage', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(res => res.json())
          .then ( ( x ) => {
            if(!x.ok) {
                alert('เกิดข้อผิดพลาด กรุณาติดต่อแอดมิน')
            }
            handleClose()
          }).catch((err) => {
            alert('เกิดข้อผิดพลาด กรุณาติดต่อแอดมิน')
            handleClose()
          })
    }

    const handleClose = () => {
        setText()
        onClose()
    }

    return (
        <Dialog open={lineMessageReceiver !== null} onClose={onClose} aria-labelledby="form-dialog-title">
            <div style={{ minWidth: "500px", height: "0" }} />
            <DialogTitle id="form-dialog-title">ทดสอบส่งข้อความทางไลน์</DialogTitle>
            <DialogContent style={{marginTop: "-1.5rem"}}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="message"
                    label="ข้อความ"
                    type="text"
                    multiline
                    fullWidth
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    style={{marginBottom: "1rem"}}
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    ยกเลิก
          </Button>
                <Button onClick={handleSubmit} color="primary">
                    ส่งข้อความ
          </Button>
            </DialogActions>
        </Dialog>
    );
}

