import React, {Component} from 'react';
import moment from 'moment';
import { urgency2Color, toBuddhistYear } from './service/utils';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Chip from '@material-ui/core/Chip';

const cssFab = {
    marginRight: "1rem"
}


export default class ReadDoneQueue extends Component {
    constructor() {
        super();    
        this.state = {
            xrayCenterId: '',
            queues: [],
        }

    }

    componentDidMount() {
        this.props.changeTitle('คิวผลอ่านภาพ')
        document.title = 'คิวผลอ่านภาพ'
        const xrayCenter = JSON.parse(localStorage.getItem('xrayCenter'))
        this.setState({ xrayCenterId: xrayCenter.id })
        fetch(process.env.REACT_APP_API_URL + '/api/uploadQueue?xrayCenterId=' + xrayCenter._id + '&isDiag=true&isDeleted=false&isDone=false')
        .then(res => res.json())
        .then(x => {
            this.setState({queues: x.data})
        });
    }

    
    buttonClick = (e) => {
        this.props.history.push(e)
    }

    render() {
    const state = this.state
    return (
        <div>
            { state.queues && 
                <Paper>
                    <Table style={{minWidth: "650px"}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{maxWidth: "50px"}}>ความด่วน</TableCell>
                            <TableCell align="center" style={{maxWidth: "50px"}}>HN</TableCell>
                            <TableCell align="center">ขื่อ-นามสกุล</TableCell>
                            <TableCell align="center">รายการตรวจ</TableCell>
                            <TableCell align="center">รังสีแพทย์</TableCell>
                            <TableCell align="center">วันที่ตรวจ</TableCell>
                            <TableCell align="center">วันที่อ่าน</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.queues.map(x => {
                        return (
                            <TableRow key={x._id} onClick={() => this.buttonClick('/home/readFilm?id=' + x._id)}>
                                <TableCell align="left">
                                    <Chip label={x.urgency} style={{marginLeft: "1rem", backgroundColor: `${urgency2Color(x.urgency)}`}}/>
                                </TableCell>
                                <TableCell>
                                    {x.hn}
                                </TableCell>
                                <TableCell>{x.patient}</TableCell>
                                <TableCell>
                                    {x.option.map( (o, idx) => {
                                        return <div key={o._id} style={{display: "block"}}>{o.serviceName}</div>
                                    })}
                                </TableCell>
                                <TableCell>{x.doctor}</TableCell>
                                <TableCell align="center">
                                    <div style={{display: "block"}}>{ toBuddhistYear( moment(x.createDate), "D/M/YYYY")}</div>
                                    <div style={{display: "block"}}>{ moment(x.createDate).format("H:mm") }</div>
                                </TableCell>
                                <TableCell align="center">
                                    <div style={{display: "block"}}>{ toBuddhistYear( moment(x.diagDate), "D/M/YYYY")}</div>
                                    <div style={{display: "block"}}>{ moment(x.diagDate).format("H:mm") }</div>
                                </TableCell>
                            </TableRow>
                        )})}
                    </TableBody>
                    </Table>
                </Paper>
            }
        </div>   
    );
  }
}
