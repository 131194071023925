import React, { useEffect, useState } from 'react';
import icon from '../asset/line-icon.png'

export default function LineIcon({userId, centerId, receiverLineId, onClick}) {
    const [lineId, setLineId] = useState()

    // if receiverLineId is provided then no API CALL necessary
    useEffect(()=> {
        if (!receiverLineId)
            return

        setLineId(receiverLineId)
    }, [receiverLineId])

    useEffect(()=> {
        if (!userId)
            return

        fetch(`${process.env.REACT_APP_API_URL}/api/line/checkUser?userId=${userId}`)
            .then(res => res.json())
            .then(x => {
                if (x.ok && x.data && x.data.lineUserId) {
                    setLineId(x.data.lineUserId)
                }
            });
    }, [userId])

    useEffect(()=> {
        if (!centerId)
            return

        fetch(`${process.env.REACT_APP_API_URL}/api/line/checkGroup?centerId=${centerId}`)
            .then(res => res.json())
            .then(x => {
                if (x.ok && x.data && x.data.lineGroupId) {
                    setLineId(x.data.lineGroupId)
                }
            });
    }, [centerId])

    return (
        <React.Fragment>
            { lineId ? 
                <a style={{cursor: "pointer"}}>
                    <img src={icon} alt="Line registered" style={{height: '30px', padding:"0 0.5rem"}} onClick={() => onClick(lineId)}/>
                </a>
                :
                <React.Fragment/>
            }
        </React.Fragment>
    )
}