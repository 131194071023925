
import React, {Component} from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

export default class ChangePassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
      newPassword: '',
      newPasswordConfirm: ''
		};
  };
    
  componentDidMount(){
      this.props.changeTitle('เปลี่ยนพาสเวิร์ด')
      document.title = 'เปลี่ยนพาสเวิร์ด'
  }   

  submit = () => {
    const state = this.state
    if (state.newPassword.length < 6) {
      alert('พาสเวิร์ดต้องมีความยาวมากกว่า 6 ตัวอักษร')
      return
    }
    if (state.newPassword !== state.newPasswordConfirm) {
      alert('พาสเวิร์ดทั้งสองช่องไม่ตรงกัน กรุณาพิมพ์ใหม่')
      return
    }
    const data = {
      _id: JSON.parse(localStorage.getItem('user')).id,
      password: state.newPassword
    }
    console.log(data)
    fetch( process.env.REACT_APP_API_URL + '/api/changePassword', {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(res => res.json())
    .then ( ( x ) => {
        if( x.ok ){
          alert('เปลี่ยนพาสเวิร์ดแล้ว กรุณาล๊อกอินใหม่')
          this.props.history.push('/logout')
        } else {
          alert('เกิดข้อผิดพลาดในการแก้ไขพาสเวิร์ด')
        }
    })
  }

	render() { 
    const state = this.state
    return (
        <div>
          * พาสเวิร์ดต้องมีความยาวมากกว่า 6 ตัวอักษร
          <div style={{width: "300px", display: "block"}}>
            <TextField
              id="outlined-full-width"
              type="password"
              label="พาสเวิร์ดใหม่"
              margin="normal"
              fullWidth
              InputLabelProps={{
                  shrink: true,
              }}
              value={state.newPassword}
              onChange={(e) => this.setState({newPassword: e.target.value})}
              variant="outlined"
            />
          </div>
          <div style={{width: "300px", display: "block"}}>
            <TextField
              id="outlined-full-width"
              type="password"
              label="พิมพ์พาสเวิร์ดใหม่อีกครั้ง"
              margin="normal"
              fullWidth
              InputLabelProps={{
                  shrink: true,
              }}
              value={state.newPasswordConfirm}
              onChange={(e) => this.setState({newPasswordConfirm: e.target.value})}
              variant="outlined"
            />
          </div>
          <Button style={{ margin: '1rem 0 0 0', display: "block" }} variant="contained" color="primary" onClick={this.submit}>เปลี่ยนพาสเวิร์ด</Button>
        </div>
    );      
  };
};
