import React, {Component} from 'react';
import queryString from 'query-string';
import moment from 'moment';
import './report.css'
import { toBuddhistYear, ageToEn } from './service/utils';
var HtmlToReactParser = require('html-to-react').Parser;

const cssBlockHead = {
    fontWeight: "bold",
    display: "inline-block",
    width: "200px",
    verticalAlign: "top"
}

const cssBlock = {
    display: "inline-block"
}

export default class ReportDiag extends Component {
    constructor() {
        super();
        this.state = {
            order: '',
            xrayCenter: '',
            doctor: '',
            lang: 'th',
            sender: '',
        }
    }    


    componentDidMount() {
        const query = queryString.parse(this.props.location.search);         

        if (query.lang === 'en') {
            this.setState({lang: 'en'})
        }

        fetch(process.env.REACT_APP_API_URL + '/api/orderById?id=' + query.id)
        .then(res => res.json())
        .then(x => {
            let order = x.data
            let diagOriginal = order.diag
            let diag = diagOriginal.split("<< แก้ไขผลเมื่อวันที่")

            if (diag.length > 1) {
                diag[0] = "<< ผลอ่านครั้งแรก >>\n\n" + diag[0]
                for (let i = 1; i < diag.length; i++) {
                    diag[i] = "<< ผลอ่านวันที่" + diag[i]
                }
            }
            
            order.diag = diag
            this.setState({order: order})

            document.title = order.patient
            fetch(process.env.REACT_APP_API_URL + '/api/xrayCenterById?id=' + order.xrayCenterId)
            .then(res => res.json())
            .then(y => {
                this.setState({xrayCenter: y.data})
            });
            fetch(process.env.REACT_APP_API_URL + '/api/doctorDetail?id=' + order.doctorId)
            .then(res => res.json())
            .then(y => {
                this.setState({doctor: y.data})
            });

            fetch(process.env.REACT_APP_API_URL + '/api/sender?_id=' + order.senderId)
            .then(res => res.json())
            .then(y => {
                this.setState({sender: y.data[0]})
            });

        });
        

    }


    render() {
        const state = this.state
        let address = ''
        if (state.xrayCenter) {
            if (state.lang === 'en') {
                address = state.xrayCenter.addressEn
            } else {
                address = state.xrayCenter.address
            }
            if ( address && address.indexOf('%%') > -1) {
                // format:  address %% receipt_header, receipt_header isn't shown in reportDiag
                address = address.substr(0, address.indexOf('%%'))  
            }
        } 
        var htmlToReactParser = new HtmlToReactParser();
        return (
            <div>
                {state.order && 
                    state.order.diag.map ( (d, idx) => {

                        if (idx !== state.order.diag.length-1) {
                            return ''
                        }

                        return (                       
                            <div className="paper" key={idx}>
                            {state.order.isDiag && 
                                <table>
                                    <thead><tr><td>
                                        <div className="header-space" style={{borderBottom: "1px solid black", paddingBottom: "1rem", marginBottom: "1rem"}}>
                                            <center style={{marginBottom: "1rem"}}>
                                                <b>{state.lang === 'en' ? state.xrayCenter.nameEn : state.xrayCenter.name}</b><br/>
                                                {htmlToReactParser.parse(address)}
                                            </center>
                                            <div>
                                                <div style={{...cssBlockHead }}>{state.lang === 'en' ? 'Name' : 'ชื่อผู้ป่วย'}</div>
                                                <div style={{...cssBlock, width: "500px"}}>
                                                    {state.order.patient}
                                                    <b style={{marginLeft: "2rem"}}>HN</b> {state.order.hn ? state.order.hn : '-'} <br/>
                                                    <b>{state.lang === 'en' ? 'Age' : 'อายุ'}</b> 
                                                    { state.order.age ? ageToEn(state.lang, state.order.age) : '-' }
                                                    <b style={{marginLeft: "2rem"}}>Ward</b> {state.order.ward ? state.order.ward : '-'}
                                                </div>
                                            </div>
                                            <div>
                                                <div style={{...cssBlockHead}}>{state.lang === 'en' ? 'Date' : 'วันที่ตรวจ'}</div>
                                                <div style={{...cssBlock}}>
                                                    { state.lang === 'en' ? 
                                                        moment(state.order.createDate).format('D/M/YYYY H:mm')
                                                        : toBuddhistYear( moment(state.order.createDate), 'D/M/YYYY H:mm')}
                                                </div>
                                            </div>                    
                                            <div>
                                                <div style={{...cssBlockHead}}>{state.lang === 'en' ? 'Referring Physician' : 'แพทย์ส่งตรวจ'}</div>
                                                <div style={{...cssBlock}}>{state.lang === 'en' ? state.sender.nameEn : state.order.sender}</div>
                                            </div>
                                            <div >
                                                <div style={{...cssBlockHead}}>{state.lang === 'en' ? 'Radiologist' : 'รังสีแพทย์'}</div>
                                                <div style={{...cssBlock}}>
                                                    { state.lang === 'en' ? 
                                                        ((state.doctor.titleEn ? state.doctor.titleEn : '') + ' ' + (state.doctor.firstnameEn ? state.doctor.firstnameEn : '') + ' ' + (state.doctor.lastnameEn ? state.doctor.lastnameEn : ''))
                                                        : state.order.doctor }
                                                    { state.doctor.license ? ' ว.' + state.doctor.license : ''}
                                                </div>
                                            </div>
                                            <div>
                                                <div style={{...cssBlockHead}}>{state.lang === 'en' ? 'Radiologist Read Date' : 'วันที่รังสีแพทย์ส่งผลกลับ'}</div>
                                                <div style={{...cssBlock}}>
                                                    { state.lang === 'en' ? 
                                                        moment(state.order.diagDate).format('D/M/YYYY H:mm')
                                                        : toBuddhistYear( moment(state.order.diagDate), 'D/M/YYYY H:mm')}
                                                </div>
                                            </div> 
                                        </div>
                                    </td></tr></thead>
                                    <tbody><tr><td>
                                    <div className="content">
                                        
                                        <div style={{borderBottom: '1px solid black', paddingBottom: "1rem", marginBottom: "1rem"}}>
                                            <div style={{...cssBlockHead}}>{state.lang === 'en' ? 'Examination' : 'รายการตรวจ'}</div>
                                            <div style={{...cssBlock}}>
                                                { state.order && state.order.option.map( (o) => {
                                                    return <div key={o._id} style={{display: "block"}}>{o.serviceName}{o.code ? ' (' + o.code + ')' : ''}</div>
                                                })}
                                            </div>
                                        </div>
                                        
                                        <span style={{whiteSpace: "pre-line"}} className="diagResult">
                                            { d }
                                        </span>
                                    </div>
                                    </td></tr></tbody>
                                </table>}
                        </div>
                        )
                    }
                    
                    )
                
                }
            </div>
            
        )
    }
}