import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(0),
      minWidth: 120,
      width: "100px"
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

export default function ServiceGroupSelect(props) {
    const classes = useStyles();
  
    const [labelWidth] = React.useState(0);

    return (
      <div>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            onChange={props.handleSeleted}
            labelWidth={labelWidth}
            value={props.value}
          >
            <MenuItem value='CT' selected={true}>CT</MenuItem>
            <MenuItem value='MRI' selected={true}>MRI</MenuItem>
            <MenuItem value='MED' selected={true}>MED</MenuItem>
            <MenuItem value='ETC' selected={true}>ETC</MenuItem>
          </Select>
        </FormControl>
      </div>
    );
  }