import React, {Component} from 'react';
import queryString from 'query-string';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';

export default class Service extends Component {
    constructor() {
        super();
        this.state = {
            xrayCenter: '',
            service: [],
            addMode: false,
            addWardName: ''
        }
    }

    componentDidMount() {       
        const query = queryString.parse(this.props.location.search); 
        if (this.props.location.search) {
            fetch(process.env.REACT_APP_API_URL + '/api/xrayCenterById?id=' + query.id)
            .then(res => res.json())
            .then(x => {
                let xrayCenter = x.data
                this.setState({ xrayCenter: xrayCenter })
                this.props.changeTitle('รายการตรวจของ' + xrayCenter.name)
                document.title = 'รายการตรวจของ' + xrayCenter.name
                fetch(process.env.REACT_APP_API_URL + '/api/service?_id=' + xrayCenter._id)
                .then(res => res.json())
                .then(s => {
                    let service = s.data
                    this.setState({ service: service })                   
                });
            });
        } 
    }

    buttonClick = (e) => {
        this.props.history.push(e)
    }

    changeActiveStatus = (e) => {
        let service = this.state.service.filter( x => x._id === e._id)
        service = service[0]
        const data = {
            _id: service._id,
            active: !service.active
        }
        fetch( process.env.REACT_APP_API_URL + '/api/service', {
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(res => res.json())
          .then ( ( x ) => {
            if(x.updated){
                fetch(process.env.REACT_APP_API_URL + '/api/service?_id=' + this.state.xrayCenter._id)
                .then(res => res.json())
                .then(s => {
                    let service = s.data
                    this.setState({ service: service })                   
                });
            } else {
                alert('เกิดข้อผิดพลาด')
            }
          })
    }

    importCgdService = () => {
        const data = {
            _id: this.state.xrayCenter._id,
        }
        console.log(data)
        fetch( process.env.REACT_APP_API_URL + '/api/service/importCgd', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(res => res.json())
          .then ( ( x ) => {
            if(x.ok){
                alert('นำเข้าข้อมูลเรียบร้อย')
                window.location.reload()
            } else {
                alert('เกิดข้อผิดพลาด')
            }
          })
    }

    render() {
    const state = this.state
      
    return (
        <div>
            { state.xrayCenter &&
                <Button variant="contained" color="secondary" onClick={ () => this.buttonClick('/home/editService?xrayCenter=' + state.xrayCenter._id) } style={{marginBottom: "1rem", marginRight: "2rem"}}>เพิ่มรายการตรวจ</Button>}
            { state.service && 
                <Paper style={{marginTop: "2rem"}}>
                    <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell  align="center">รายการตรวจ</TableCell>
                            <TableCell align="center">รหัสกรมบัญชี</TableCell>
                            <TableCell align="center">ประเภท</TableCell>
                            <TableCell align="center">ประเภทย่อย</TableCell>
                            <TableCell align="center">ราคา</TableCell>
                            <TableCell align="center" style={{maxWidth: "50px"}}>สถานะ</TableCell>
                            <TableCell align="center" style={{maxWidth: "50px"}}>เมนู</TableCell>
                            
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.service.map(x => {
                        return (
                            <TableRow key={x._id}>
                                <TableCell>{x.serviceName}</TableCell>
                                <TableCell>{x.code}</TableCell>
                                <TableCell>{x.serviceGroup}</TableCell>
                                <TableCell>{x.serviceSubGroup}</TableCell>
                                <TableCell align="right">{x.price}</TableCell>
                                <TableCell align="center" style={{maxWidth: "100px"}}>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            checked={Boolean(x.active)}
                                            onChange={ () => this.changeActiveStatus(x) }
                                            color="primary"
                                        />
                                        }
                                        label="เปิด"
                                        style={{ height: "1rem" }}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <Fab variant="extended" aria-label="like" size="small"  onClick={() => this.buttonClick('/home/editService?id=' + x._id)}>
                                        แก้ไข
                                    </Fab>
                                </TableCell>
                            </TableRow>
                        )})}
                    </TableBody>
                    </Table>
                </Paper>
            }
        </div>   
    );
  }
}
