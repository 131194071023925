import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import logo from './asset/logo.png'
import './index.css';

const cssLogin = {
  width: "300px",
  textAlign: "center",
  backgroundColor: "white",
  margin: "auto auto",
  padding: "3rem 1rem",
  borderRadius: "2rem",
  verticalAlign: "middle",
  boxShadow: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)"
}

export default class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email : '',
      password: '',
    };
  }

  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value
    });
  }

  componentDidMount (){
    document.title = "Perfect-Med"
  }

  onSubmit = (event) => {
    event.preventDefault();
    const data = {
      email: this.state.email.toLowerCase(),
      password: this.state.password
    }
    fetch( process.env.REACT_APP_API_URL + '/api/authenticate', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(res => {
      if (res.status === 200) {
        return res.json()
      } else {
        const error = new Error(res.error);
        throw error;
      }
    }).then ( async ( data ) => {
      localStorage.setItem('user', JSON.stringify(data.user));
      if (data.user.role === 'user'){
        await fetch(process.env.REACT_APP_API_URL + '/api/xrayCenterById?id=' + data.user.xrayCenter_id)
        .then(res => res.json())
        .then(x => {
          const y = x.data
          localStorage.setItem('xrayCenter', JSON.stringify(y));
        });
      }
      var {from} = this.props.location.state || {from: {pathname: '/home'}}
      this.props.history.push(from) 
    }

    )
    .catch(err => {
      console.error(err);
      alert('Error logging in please try again');
    });
  }

 

  render() {
    return (
      <div style={{backgroundColor: "#354152", width: "100%", height: "100vh", alignItems: "center", display: "flex"}}>
        <div style={ cssLogin }>
            <img alt="Perfect-Med Logo" src={logo} style={{width: "220px"}} />
            <div>
              <TextField
                label="Username"
                margin="normal"
                value={this.state.email}
                onChange={this.handleInputChange}
                name="email"
                required
              />
            </div>

            <div style={{marginBottom: "1rem"}}>
              <TextField
                label="Password"
                margin="normal"
                value={this.state.password}
                onChange={this.handleInputChange}
                name="password"
                type="password"
                required
              />
            </div>
            <Button variant="contained" color="primary" onClick={this.onSubmit} style={{width: "200px", marginTop: "1rem"}}>ล๊อกอิน</Button>
        </div>
      </div>
    );
  }
}