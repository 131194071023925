import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

export default function withAuth(ComponentToProtect) {
  return class extends Component {
    constructor() {
      super();
      this.state = {
        loading: true,
        redirect: false,
      };
    }

    componentDidMount() {

      fetch(process.env.REACT_APP_API_URL + '/api/checkToken')
        .then(res => {
          if (res.status === 200) {
            this.setState({ loading: false });
          } else if (res.status === 401) {
            const location = {
              pathname: '/login',
              state: {from: this.props.location.pathname + this.props.location.search}
            }
            this.props.history.push(location)
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch(err => {
          console.error(err);
          this.setState({ loading: false, redirect: true });
        });
    }


    render() {
      const { loading, redirect } = this.state;
      
      if (loading) {
        return null;
      }
      if (redirect) {
        return <Redirect to="/login" />;
      }
      return (
        <React.Fragment>
          <ComponentToProtect {...this.props} />
        </React.Fragment>
      );
    }
  }
}