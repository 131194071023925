import XLSX from 'xlsx';
import React, {Component} from 'react';
import moment from 'moment';
import AutoComplete from './component/AutoComplete';
import DateFnsUtils from '@date-io/date-fns';
import {  MuiPickersUtilsProvider,  KeyboardDatePicker, } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default class ExcelExport extends Component {
	constructor(props) {
		super(props);
		this.state = {
            data: [], 
            from: moment().format("YYYY-MM-DD"),
            to:  moment().format("YYYY-MM-DD"),
            xrayCenter: '',
            user: '',
            xrayCenterAll: '',
            filter: 'All',
		};
		this.exportFile = this.exportFile.bind(this);
    };
    
    componentDidMount(){
        this.props.changeTitle('รายงานศูนย์')
        document.title = 'รายงานศูนย์'

        let xrayCenter = JSON.parse(localStorage.getItem('xrayCenter'))
        const user = JSON.parse(localStorage.getItem('user'))
        this.setState({ xrayCenter: xrayCenter, user: user })

        if (user.role === 'admin' || user.role === 'office') {
            fetch(process.env.REACT_APP_API_URL + '/api/xrayCenter?active=true')
            .then(res => res.json())
            .then(y => {
                let data = y.data
                data.map ( x => {
                    x.label = x.name
                    return x
                })
                this.setState({xrayCenterAll: data})
            });
        }
        
    }

    changeFilter = (e) => {
        const filter = e.target.value
        this.setState({ filter: filter })
    }

    calculateColWidth (data) {
        let objectMaxLength = []; 
        for (let i = 0; i < data.length; i++) {
            let value = Object.values(data[i]);
            for (let j = 0; j < value.length; j++) {
                if (typeof value[j] == "number") {
                    objectMaxLength[j] = 10;
                } else if (value[j]) {
                    objectMaxLength[j] =
                        objectMaxLength[j] >= value[j].length
                        ? objectMaxLength[j]
                        : value[j].length;
                }
            }
        }

        let wscols = []
        for (let i = 0; i < objectMaxLength.length; i++) {
            wscols.push({width: objectMaxLength[i]})
        }
        return wscols
    }

    applyFilter (data) {
        let output = []
        const filter = this.state.filter
        if (filter === 'All') {
            return data
        }

        data.map ( x => {
            let isCT = true
            x.option.map ( oo => {
                if (oo.serviceGroup === 'MRI') {
                    isCT = false
                }
                return oo
            })
            if (isCT) {
                if (filter === 'CT') {
                    output.push(x)
                }                
            } else {
                // isMRI
                if (filter === 'MRI') {
                    output.push(x)
                }
            }
            return x
        })
        return output
    }

    exportOffice = (from, to) => {
        fetch(process.env.REACT_APP_API_URL + `/api/orderByDate?isDeleted=false&from=${from}&to=${to}&xrayCenterId=${this.state.xrayCenter._id}&isDone=true`)
        .then(res => res.json())
        .then(x => {
            const data = this.applyFilter(x.data)
            let rows = []
            rows.push([
                'วันที่',
                'HN',
                'ผู้ป่วย',
                'อายุ',
                'Ward',
                'สิทธิ์',

                'รายการ',
                'ประเภท',
                'ประเภทย่อย',
                'จำนวน',
                'ราคาต่อหน่วย',
                'ราคารวม',

                'รังสีแพทย์',
                'DFรังสี',
                'แพทย์ส่ง',
                'DFส่ง',
                'เลขที่บิล',
                'ศูนย์',

                'วันที่ขอรับผล',
                'วันที่รังสีแพทย์ส่งผลกลับ',
            ])
            data.map (a => {
                a.option.map( b => {
                    let temp = [
                        moment(a.createDate).format("D/M/YYYY"),
                        a.hn,
                        a.patient,
                        a.age,
                        a.ward,
                        a.payment,
                        
                        b.serviceName,
                        b.serviceGroup,
                        b.serviceSubGroup,
                        b.amount,
                        b.price,
                        parseInt(b.amount) * parseInt(b.price),
                        
                        a.doctor,
                        b.dfDoctor,
                        a.sender,
                        b.dfSender,
                        a.receiptId,
                        a.xrayCenter,

                        moment(a.expectReportDate).format("D/M/YYYY"),
                        moment(a.diagDate).format("D/M/YYYY"),
                    ]
                    rows.push(temp)
                    return b
                })
                return a
            })
            const ws = XLSX.utils.aoa_to_sheet(rows);
            ws["!cols"] = this.calculateColWidth(rows);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Report");
            XLSX.writeFile(wb, `report_${from}_${to}.xlsx`)
        })
    };

    exportAdmin = (from, to) => {
        fetch(process.env.REACT_APP_API_URL + '/api/orderByDate?isDeleted=false&from=' + from + '&to=' + to + '&xrayCenterId=' + this.state.xrayCenter._id)
        .then(res => res.json())
        .then(x => {
            const data = this.applyFilter(x.data)
            let rows = []
            rows.push([
                'วันที่',
                'HN',
                'ผู้ป่วย',
                'อายุ',
                'Ward',
                'ประเภทสิทธิ์',
                'สิทธิ์',

                'รายการ',
                'ประเภท',
                'ประเภทย่อย',
                'จำนวน',
                'ราคาต่อหน่วย',
                'ราคารวม',

                'รังสีแพทย์',
                'DFรังสี',
                'แพทย์ส่ง',
                'DFส่ง',
                'สถานะ',
                'เลขที่บิล',
                'ศูนย์',

                'ความด่วน',

                'วันที่ขอรับผล',
                'วันที่รังสีแพทย์ส่งผลกลับ',
            ])
            data.map (a => {
                let stage = ''
                if (a.isDone) {
                    stage = "ปิดเคส"
                } else if (a.isDiag) {
                    stage = "รอพิมพ์ผล"
                } else if (a.isDoctorRead) {
                    stage = "แพทย์อ่านอยู่"
                } else if (a.isUploaded) {
                    stage = "รอแพทย์เปิด"
                } else {
                    stage = "รออัพโหลด"    
                }
                a.option.map( b => {
                    let temp = [
                        moment(a.createDate).format("D/M/YYYY"),
                        a.hn,
                        a.patient,
                        a.age,
                        a.ward,
                        a.paymentType === 'cgd' ? 'ราคากรมบัญชีกลาง' : 'ราคาตามสัญญา',
                        a.payment,
                        
                        b.serviceName,
                        b.serviceGroup,
                        b.serviceSubGroup,
                        b.amount,
                        b.price,
                        parseInt(b.amount) * parseInt(b.price),
                        
                        a.doctor,
                        b.dfDoctor,
                        a.sender,
                        b.dfSender,
                        stage,
                        a.receiptId,
                        a.xrayCenter,

                        a.urgency,

                        moment(a.expectReportDate).format("D/M/YYYY"),
                        moment(a.diagDate).format("D/M/YYYY"),
                    ]
                    rows.push(temp)
                    return b
                })
                return a
            })
            const ws = XLSX.utils.aoa_to_sheet(rows);
            ws["!cols"] = this.calculateColWidth(rows);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Report");
            XLSX.writeFile(wb, `report_${from}_${to}.xlsx`)
        })
    }

	async exportFile () {
        const state = this.state
        const from = moment(state.from).format('YYYY-MM-DD')
        const to = moment(state.to).add(1,'days').format('YYYY-MM-DD')
        
        if ( parseInt( moment(to).diff(moment(from), 'days' )) > 185 ) {
            alert('ไม่สามารถออกรายงานที่มากกว่า 180 วันได้ กรุณาลดจำนวนวัน')
            return
        }

        if (!this.state.xrayCenter) {
            alert('กรุณาระบุศูนย์')
            return
        }

        // OFFICE
        if (this.state.user.role === 'office') {
            this.exportOffice(from, to);

        // USER, ADMIN
        } else {
            this.exportAdmin(from, to);
        }
    }
    

	render() { 
        const state = this.state
        return (
            <div>
                { (state.user.role === 'admin' || state.user.role === 'office') && 
                <div style={{width: "500px", marginBottom: "1rem"}}>
                    <div style={{marginBottom: "0.5rem"}}>เลือกศูนย์</div>
                    {state.xrayCenterAll && <AutoComplete list={state.xrayCenterAll} placeholder="ค้นหาศูนย์" onChange={ (x) => this.setState({xrayCenter: x})}/>}
                </div>}
                <RadioGroup aria-label="position" onChange={this.changeFilter} row >
                    <FormControlLabel
                        value="All"
                        control={<Radio color="primary" />}
                        label="ทั้งหมด"
                        labelPlacement="end"
                        checked={state.filter === "All"}
                    />
                    <FormControlLabel
                        value="CT"
                        control={<Radio color="primary" />}
                        label="CT"
                        labelPlacement="end"
                        checked={state.filter === "CT"}
                    />
                    <FormControlLabel
                        value="MRI"
                        control={<Radio color="primary" />}
                        label="MRI"
                        labelPlacement="end"
                        checked={state.filter === "MRI"}
                    />
                </RadioGroup>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div style={{marginRight: '2rem'}}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label="ตั้งแต่วันที่"
                            value={state.from}
                            onChange={(e) => this.setState({from: e})}
                            KeyboardButtonProps={{
                            'aria-label': 'change date',
                            }}
                        />
                    </div>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="ถึงวันที่"
                        value={state.to}
                        onChange={(e) => this.setState({to: e})}
                        KeyboardButtonProps={{
                        'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
                <Button style={{ margin: '1rem 0 0 0', display: "block" }} variant="contained" color="primary" onClick={this.exportFile}>ดาวน์โหลดรายงาน</Button>
            </div>
        );      
    };
};
