import React, {Component} from 'react';
import queryString from 'query-string';
import moment from 'moment';

import DateFnsUtils from '@date-io/date-fns';
import {  MuiPickersUtilsProvider,  KeyboardDatePicker, } from '@material-ui/pickers';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AutoComplete from './component/AutoComplete';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';

const cssFab = {
    marginRight: "1rem",
    padding: "0 1rem"
}

const cssDetail = {
    margin: '1rem 2rem'
}

const cssRow = { 
    display: 'flex', 
    alignItems: 'center'
}

const cssTopic = {
    marginLeft: '2rem',
    width: '100px'
}
const cssTopicChoice = {
    width: '400px'
}

const cssAddService = {
    // display: 'flex-row',
    marginRight: "1rem",
}

export default class SendXray extends Component {
    constructor() {
        super();
        this.state = {
            xrayCenter: '',
            patient: '',
            urgency: 'Normal',
            payment: '',
            paymentEtc: '',
            paymentType: 'contract',

            creator: '',
            creatorId: '',
            order: [],
            services: '',
            chosenService: '',
            price: 0,
            amount: 1,
            dfSender: '',
            dfDoctor: '',
            sender: '',
            senderId: '',
            doctor: '',
            ward: '',
            etcDetail: false,
            etcDetailText: '',
            allSender: [],
            loading: false,
            expectReportDate: null,
            
            oldOrder: '',
            editSender: false,
            editDoctor: false,
            editPayment: false,
            editMode: false,
        }
    }    

    componentDidMount() {
        let xrayCenter = JSON.parse(localStorage.getItem('xrayCenter'))
        const user = JSON.parse(localStorage.getItem('user'))

        xrayCenter.ward.filter ( x => x.active )
        xrayCenter.ward.map ( x => x.label = x.name )
        this.setState({ xrayCenter: xrayCenter, creator: user.firstname + ' ' + user.lastname, creatorId: user.id })      
        const query = queryString.parse(this.props.location.search);  

        if (query.id) {
            //new order
            document.title = 'ส่งตรวจ'
            fetch(process.env.REACT_APP_API_URL + '/api/patient?id=' + query.id)
            .then(res => res.json())
            .then(x => {
                const patient = x.data
                this.setState({ patient: x.data, editSender: true, editDoctor: true, editPayment: true })
                this.props.changeTitle('ส่งตรวจ (คุณ' + patient.firstname + ' ' + patient.lastname + ')')
            });
        } else {
            //edit order
            document.title = 'แก้ไขส่งตรวจ'
            fetch(process.env.REACT_APP_API_URL + '/api/orderById?id=' + query.orderId)
            .then(res => res.json())
            .then(x => {
                console.log(x.data)
                const order = x.data
                this.setState({
                    editMode: true, oldOrder: order,
                    ward: order.ward, 
                    sender: order.sender, senderId: order.senderId, doctor: order.doctor, 
                    urgency: order.urgency, order: order.option, payment: order.payment ,
                    expectReportDate: order.expectReportDate
                })

                fetch(process.env.REACT_APP_API_URL + '/api/patient?id=' + order.patientId)
                .then(res => res.json())
                .then(x => {
                    const patient = x.data
                    this.setState({ patient: patient })
                    this.props.changeTitle('แก้ไขส่งตรวจ (คุณ' + patient.firstname + ' ' + patient.lastname + ')')
                });
                
            });
        }
        

        fetch(process.env.REACT_APP_API_URL + '/api/sender?xrayCenterId=' + xrayCenter._id + '&active=true')
        .then(res => res.json())
        .then(x => {
            let sender = x.data
            if (sender.length > 0) {
                sender.map( a => {
                    if (a.active) {
                        a.label = a.name
                    } 
                    return a
                })
            }
            this.setState({ allSender: sender })
        });
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleAutoService = x => {
        const price = x.price ? x.price : 0
        if (x.serviceGroup === "ETC") {
            this.setState({ chosenService: x, price: price, etcDetail: true })
        } else {
            this.setState({ chosenService: x, price: price })
        }
    }
 
    handleAutoSender = x => {
        this.setState({ sender: x.name, senderId: x._id })
    }

    handlePayment = (e) => {
        const payment = e.target.value
        if (payment === 'ชำระเงินสด' || payment === 'อื่นๆ' ) {
            fetch(process.env.REACT_APP_API_URL + `/api/service?_id=5dd4a64b26e7ca329472f890&active=true`)
            .then(res => res.json())
            .then(x => {
                let services = x.data
                services.map ( i => i.label = i.serviceName )
                this.setState({ services: services })
            });
        } else {
            fetch(process.env.REACT_APP_API_URL + `/api/service?_id=${this.state.xrayCenter._id}&active=true`)
            .then(res => res.json())
            .then(x => {
                let services = x.data
                services.map ( i => i.label = i.serviceName )
                this.setState({ services: services })
            });
        }
        this.setState({ payment: payment })
    }

    onSubmit = () => {
        const state = this.state
        let isError = false

        if (!state.doctor) {
            alert('ไม่สามารถบันทึกได้ กรุณาระบุแพทย์รังสี')
            isError = true
        }
        if (!state.sender) {
            alert('ไม่สามารถบันทึกได้ กรุณาระบุแพทย์ส่ง')
            isError = true
        }
        if (!state.payment) {
            alert('ไม่สามารถบันทึกได้ กรุณาระบุสิทธิ์')
            isError = true
        }
        if (!state.patient.ward) {
            alert('ไม่มีข้อมูล Ward กรุณาแก้ไขข้อมูล Ward ผู้ป่วย')
            isError = true
        }
        if (!state.expectReportDate) {
            alert('ระบวันรับผล')
            isError = true
        }
        state.order.map(x => {
            if ( isNaN(parseInt(x.amount)) || isNaN(parseInt(x.dfSender)) || isNaN(parseInt(x.dfDoctor))){
                alert('ไม่สามารถบันทึกได้ จำนวนหรือค่าDFต้องเป็นตัวเลขเท่านั้น')
                isError = true
            }
            if ( parseInt(x.amount) < 1 ){
                alert('ไม่สามารถบันทึกได้ จำนวนต้องมีค่ามากกว่า 1')
                isError = true
            }
            if ( parseInt(x.dfSender) < 0 ){
                alert('ไม่สามารถบันทึกได้ ค่า DF ส่ง ต้องมากกว่า 0')
                isError = true
            }
            if ( parseInt(x.dfDoctor) < 0 ){
                alert('ไม่สามารถบันทึกได้ ค่า DF รังสี ต้องมากกว่า 0')
                isError = true
            }
            return x
        })
        if (isError){
            return
        }

        let payment = state.payment
        if (payment === 'อื่นๆ' || payment === 'เบิกอื่นๆ') {
            payment = state.paymentEtc
        }
        
        const title = state.patient.title
        let age = state.patient.age
        age = age ? age + ' ' + state.patient.ageUnit : ''

        this.setState({ loading: true })
        if (!state.editMode) {
            //create new order
            const data = {
                doctor: state.doctor.label,
                doctorId: state.doctor._id,
                sender: state.sender,
                senderId: state.senderId,
                option: state.order,
                hn: state.patient.hn,
                patient: (title ? title + ' ' : '') + state.patient.firstname + ' ' + state.patient.lastname,
                patientId: state.patient._id,
                age: age,
                ward: state.patient.ward,
                wardId: state.patient.wardId,
                urgency: state.urgency,
                payment: payment,
                paymentType: state.paymentType,
                creator: state.creator,
                creatorId: state.creatorId,
                xrayCenter: state.xrayCenter.name,
                xrayCenterId: state.xrayCenter._id,
                receiptId: state.xrayCenter.code + moment().format('YYMMDDHHmm'),
                expectReportDate: state.expectReportDate,
            }
            fetch( `${process.env.REACT_APP_API_URL}/api/order`, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                  'Content-Type': 'application/json'
                }
              })
              .then(res => res.json())
              .then ( ( x ) => {
                if(x.ok){
                  this.props.history.push('/home/uploadQueue')
                } else {
                  alert('เกิดข้อผิดพลาด')
                }
              })
        } else {
            // edit
            let data = {
                _id: state.oldOrder._id,
                hn: state.patient.hn,
                patient: (title ? title + ' ' : '') + state.patient.firstname + ' ' + state.patient.lastname,
                patientId: state.patient._id,
                age: age,
                ward: state.patient.ward,
                wardId: state.patient.wardId,
                urgency: state.urgency,
                creator: state.creator,
                creatorId: state.creatorId,
                expectReportDate: state.expectReportDate,
            }
            if (state.editDoctor) {
                data = {...data, 
                    doctor: state.doctor.label,
                    doctorId: state.doctor._id,
                }
            }

            if (state.editSender) {
                data = {...data, 
                    sender: state.sender,
                    senderId: state.senderId,
                }
            }

            if (state.editPayment) {
                data = {...data, 
                    option: state.order,
                    payment: payment,
                    paymentType: state.paymentType,
                }
            }

            fetch( process.env.REACT_APP_API_URL + '/api/order/setIsDiag', {
                method: 'PATCH',
                body: JSON.stringify(data),
                headers: {
                  'Content-Type': 'application/json'
                }
              })
              .then(res => res.json())
              .then ( ( x ) => {
                if(x.updated){
                    this.props.history.push('/home/readFilm?id=' + x.data._id)
                } else {
                    alert('เกิดข้อผิดพลาด')
                }
              })
        }
       
        

    }

    addService = () => {
        const state = this.state

        if (isNaN(parseInt(state.dfSender)) || isNaN(parseInt(state.dfDoctor))){
            alert('จำนวนหรือค่าDFต้องเป็นตัวเลขเท่านั้น')
            return
        }

        if (parseInt(state.price) * parseInt(state.amount) <= parseInt(state.dfSender) + parseInt(state.dfDoctor)) {
            alert('ค่า DF แพทย์ส่ง และ DF รังสี ต้องรวมกันน้อยกว่าค่าตรวจ')
            return
        }

        let serviceName = ''
        if (state.etcDetailText) {
            serviceName = state.chosenService.serviceName + ' (' + state.etcDetailText + ')'
        } else {
            serviceName = state.chosenService.serviceName
        }
        
        let element = {
            serviceId: state.chosenService._id,
            serviceName: serviceName,
            serviceGroup: state.chosenService.serviceGroup,
            serviceSubGroup: state.chosenService.serviceSubGroup,
            code: state.chosenService.code,
            price: state.price ? state.price : 0,
            amount: state.amount,
            dfSender: state.dfSender,
            dfDoctor: state.dfDoctor,
        }
        let currentOrder = [...state.order]
        currentOrder.push(element)
        this.setState({ chosenService: '', order: currentOrder, price: 0, amount: 1, dfSender: '', dfDoctor: '', etcDetail: false, etcDetailText: '' })
    }

    deleteService = (e) => {
        let order = [...this.state.order]
        order = order.filter( x => x.serviceId !== e )
        this.setState({ order: order })
    }

    render() {
    const state = this.state
    return (
        <div>       
            <div>
                <h2>
                    ข้อมูลผู้ป่วย
                </h2>
                <div style={cssDetail}>
                    {state.patient.title + ' ' + state.patient.firstname + ' ' + state.patient.lastname} 
                    (HN {state.patient.hn ? state.patient.hn : '-' })
                    { state.patient.age && <div style={{display: "inline-block"}}>
                        {' '} อายุ {state.patient.age + ' ' + state.patient.ageUnit}
                    </div>}
                </div>
                <div style={cssDetail}>
                    Ward {state.patient.ward} 
                </div>
            </div>
            <div>
                <h2>รายละเอียดส่งตัว</h2>
                <div style={cssRow}>
                    <div style={cssTopic}>แพทย์ส่ง</div>
                    <div style={cssTopicChoice}>
                        {state.allSender.length > 0 && state.editSender && <AutoComplete list={state.allSender} onChange={this.handleAutoSender}/>}
                        {!state.editSender && <div>
                            <Fab variant="extended" aria-label="like" size="small" style={cssFab} onClick={() => this.setState({editSender: true})}>
                                <EditIcon /> แก้ไข
                            </Fab>
                            {state.sender}
                        </div>}
                    </div>
                </div>
                <div style={cssRow}>
                    <div style={cssTopic}>รังสีแพทย์</div>
                    <div style={cssTopicChoice}>
                        {state.xrayCenter && state.editDoctor && 
                            <AutoComplete endPoint={"/api/doctor"}  onChange={ (x) => this.setState({doctor: x})}/>
                        }
                        {!state.editDoctor && <div>
                            {!state.oldOrder.isDiag && 
                            <Fab variant="extended" aria-label="like" size="small" style={cssFab} onClick={() => this.setState({editDoctor: true})}>
                                <EditIcon /> แก้ไข
                            </Fab>}
                            {state.doctor}
                        </div>}
                    </div>
                </div>
                <div style={cssRow}>
                    <div style={cssTopic}>วันรับผล</div>
                    <div style={cssTopicChoice}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                value={state.expectReportDate}
                                onChange={(e) => this.setState({expectReportDate: e})}
                                disablePast
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </div>
                <div style={cssRow}>
                    <div style={cssTopic}>ความด่วน</div>
                    <div style={{...cssTopicChoice, width: '500px'}}>
                        <RadioGroup aria-label="position" name="urgency" value={state.urgency} onChange={this.handleChange} row>
                            <FormControlLabel
                                value="Normal"
                                control={<Radio color="primary" />}
                                label="Normal"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="Urgency"
                                control={<Radio color="primary" />}
                                label="Urgency"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="Emergency"
                                control={<Radio color="primary" />}
                                label="Emergency"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="Fast-Track"
                                control={<Radio color="primary" />}
                                label="Fast-Track"
                                labelPlacement="end"
                            />
                        </RadioGroup>
                    </div>
                </div>
                <div style={{...cssRow, alignItems: 'top'}}>
                    <div style={{...cssTopic, verticalAlign: "top", marginTop: "0.7rem"}}>สิทธิ์</div>
                    <div style={{...cssTopicChoice, width:"50%"}}>
                        {state.editPayment && <div>
                            <div style={{marginBottom: "1rem"}}>
                                <Fab variant="extended" aria-label="like" size="small" style={cssFab} onClick={() => this.setState({paymentType: 'contract'})} color={state.paymentType === 'contract' ? 'primary' : 'default'} disabled={Boolean(state.payment)}> 
                                    ราคาตามสัญญา
                                </Fab>
                                <Fab variant="extended" aria-label="like" size="small" style={cssFab} onClick={() => this.setState({paymentType: 'cgd'})} color={state.paymentType === 'cgd' ? 'primary' : 'default'} disabled={Boolean(state.payment)}>
                                    ราคากรมบัญชีกลาง
                                </Fab>    
                            </div>                        
                            {state.paymentType === 'contract' && <RadioGroup aria-label="position" onChange={this.handlePayment} row>
                                <FormControlLabel
                                    value="เบิกได้ IPD"
                                    control={<Radio color="primary" />}
                                    label="เบิกได้ IPD"
                                    labelPlacement="end"
                                    checked={state.payment === "เบิกได้ IPD"}
                                    disabled={Boolean(state.payment)}
                                />
                                <FormControlLabel
                                    value="เบิกได้ OPD"
                                    control={<Radio color="primary" />}
                                    label="เบิกได้ OPD"
                                    labelPlacement="end"
                                    checked={state.payment === "เบิกได้ OPD"}
                                    disabled={Boolean(state.payment)}
                                />
                                <FormControlLabel
                                    value="บัตรทอง"
                                    control={<Radio color="primary" />}
                                    label="บัตรทอง"
                                    labelPlacement="end"
                                    checked={state.payment === "บัตรทอง"}
                                    disabled={Boolean(state.payment)}
                                />
                                <FormControlLabel
                                    value="ประกันสังคม"
                                    control={<Radio color="primary" />}
                                    label="ประกันสังคม"
                                    labelPlacement="end"
                                    checked={state.payment === "ประกันสังคม"}
                                    disabled={Boolean(state.payment)}
                                />
                                <FormControlLabel
                                    value="พรบ"
                                    control={<Radio color="primary" />}
                                    label="พรบ"
                                    labelPlacement="end"
                                    checked={state.payment === "พรบ"}
                                    disabled={Boolean(state.payment)}
                                />
                                <FormControlLabel
                                    value="เบิกราชการ"
                                    control={<Radio color="primary" />}
                                    label="เบิกราชการ"
                                    labelPlacement="end"
                                    checked={state.payment === "เบิกราชการ"}
                                    disabled={Boolean(state.payment)}
                                />
                                <FormControlLabel
                                    value="เงินสดเรียกเก็บ"
                                    control={<Radio color="primary" />}
                                    label="เงินสดเรียกเก็บ"
                                    labelPlacement="end"
                                    checked={state.payment === "เงินสดเรียกเก็บ"}
                                    disabled={Boolean(state.payment)}
                                />
                                <FormControlLabel
                                    value="เรียกเก็บ"
                                    control={<Radio color="primary" />}
                                    label="เรียกเก็บ"
                                    labelPlacement="end"
                                    checked={state.payment === "เรียกเก็บ"}
                                    disabled={Boolean(state.payment)}
                                />
                                <FormControlLabel
                                    value="อนุเคราะห์"
                                    control={<Radio color="primary" />}
                                    label="อนุเคราะห์"
                                    labelPlacement="end"
                                    checked={state.payment === "อนุเคราะห์"}
                                    disabled={Boolean(state.payment)}
                                />
                                <FormControlLabel
                                    value="อปท."
                                    control={<Radio color="primary" />}
                                    label="อปท."
                                    labelPlacement="end"
                                    checked={state.payment === "อปท."}
                                    disabled={Boolean(state.payment)}
                                />
                                <FormControlLabel
                                    value="กทม."
                                    control={<Radio color="primary" />}
                                    label="กทม."
                                    labelPlacement="end"
                                    checked={state.payment === "กทม."}
                                    disabled={Boolean(state.payment)}
                                />
                                <FormControlLabel
                                    value="ต้นสังกัด"
                                    control={<Radio color="primary" />}
                                    label="ต้นสังกัด"
                                    labelPlacement="end"
                                    checked={state.payment === "ต้นสังกัด"}
                                    disabled={Boolean(state.payment)}
                                />
                                <FormControlLabel
                                    value="บริษัทคู่สัญญา"
                                    control={<Radio color="primary" />}
                                    label="บริษัทคู่สัญญา"
                                    labelPlacement="end"
                                    checked={state.payment === "บริษัทคู่สัญญา"}
                                    disabled={Boolean(state.payment)}
                                />
                                <FormControlLabel
                                    value="เบิกอื่นๆ"
                                    control={<Radio color="primary" />}
                                    label="อื่นๆ"
                                    labelPlacement="end"
                                    checked={state.payment === "เบิกอื่นๆ"}
                                    disabled={Boolean(state.payment)}
                                />
                                { state.payment === "เบิกอื่นๆ" && <TextField
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={state.paymentEtc}
                                    onChange={(e) => this.setState({ paymentEtc: e.target.value})}
                                    style={{display: "inline-block"}}
                                />}
                            </RadioGroup>}
                            {state.paymentType === 'cgd' &&<RadioGroup aria-label="position" onChange={this.handlePayment} row>
                                <FormControlLabel
                                    value="ชำระเงินสด"
                                    control={<Radio color="primary" />}
                                    label="ชำระเงินสด"
                                    labelPlacement="end"
                                    checked={state.payment === "ชำระเงินสด"}
                                    disabled={Boolean(state.payment)}
                                />
                                <FormControlLabel
                                    value="อื่นๆ"
                                    control={<Radio color="primary" />}
                                    label="อื่นๆ"
                                    labelPlacement="end"
                                    checked={state.payment === "อื่นๆ"}
                                    disabled={Boolean(state.payment)}
                                />
                                { state.payment === "อื่นๆ" && <TextField
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={state.paymentEtc}
                                    onChange={(e) => this.setState({ paymentEtc: e.target.value})}
                                    style={{display: "inline-block"}}
                                />}
                            </RadioGroup>}
                        </div>}
                        {!state.editPayment && <div style={{marginTop: "0.5rem"}}>
                            <Fab variant="extended" aria-label="like" size="small" style={cssFab} onClick={() => this.setState({editPayment: true, order: [], paymentType: "contract", payment: ""})}>
                                <EditIcon /> แก้ไข
                            </Fab>
                            {state.payment}
                        </div>}
                    </div>
                </div>
                <h2 style={{marginBottom: 0}}>รายการตรวจ</h2>
                <div style={{marginBottom: "1rem"}}>* กรุณาเลือกสิทธิ์ก่อนกรอกรายการตรวจ</div>
                <div style={{display: "block", width: "850px"}}>
                    
                    {state.services && 
                        <div>
                            <div style={{ display: "flex", width: "100%", marginLeft: "2rem"}}>
                                
                                <div style={{ ...cssAddService, width: "46%"}}>
                                    <AutoComplete list={state.services} onChange={this.handleAutoService}/>
                                </div>
                                
                                <div style={{ ...cssAddService, width: "15%"}}>
                                    <TextField
                                        style={{backgroundColor: "white"}}
                                        margin="dense"
                                        variant="outlined"
                                        label="ราคาต่อหน่วย"
                                        value={state.price}
                                        onChange={ (e) => this.setState({price: e.target.value })}
                                    />
                                </div>
                                <div style={{ ...cssAddService, width: "8%"}}>
                                    <TextField
                                        style={{backgroundColor: "white"}}
                                        margin="dense"
                                        variant="outlined"
                                        label="จำนวน"
                                        value={state.amount}
                                        onChange={ (e) => this.setState({amount: e.target.value })}
                                    />
                                </div>
                                <div style={{ ...cssAddService, width: "10%"}}>
                                    <TextField
                                        style={{backgroundColor: "white"}}
                                        margin="dense"
                                        variant="outlined"
                                        label="DF ส่ง"   
                                        value={state.dfSender}
                                        onChange={ (e) => this.setState({dfSender: e.target.value })}
                                    />
                                </div>
                                <div style={{ ...cssAddService, width: "10%"}}>
                                    <TextField
                                        style={{backgroundColor: "white"}}
                                        margin="dense"
                                        variant="outlined"
                                        label="DF รังสี"
                                        value={state.dfDoctor}
                                        onChange={ (e) => this.setState({dfDoctor: e.target.value })}
                                    />
                                </div>
                                <Button style={{ margin: 10 }} variant="contained" color="primary" onClick={this.addService}>เพิ่ม</Button>
                                <div className="break"></div>
                                
                            </div>
                            { state.etcDetail && 
                                <div style={{display: "block", marginLeft: "3rem"}}>
                                    <TextField
                                        style={{backgroundColor: "white"}}
                                        id="outlined-basic"
                                        margin="dense"
                                        variant="outlined"
                                        label="รายละเอียด"
                                        value={state.etcDetailText}
                                        onChange={ (e) => this.setState({etcDetailText: e.target.value })}
                                    />
                                </div>
                            }
                        </div>
                    }
                    {state.order.length !== 0 && <Paper style={{margin: "1rem 0 2rem 2rem"}}>
                        <Table style={{width: "100%"}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" style={{maxWidth: "20px"}}>ลำดับ</TableCell>
                                <TableCell>รายการ</TableCell>
                                <TableCell align="right">ราคา</TableCell>
                                <TableCell align="right">จำนวน</TableCell>
                                <TableCell align="right">DF ส่ง</TableCell>
                                <TableCell align="right">DF รังสี</TableCell>
                                <TableCell align="center">ลบ</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {state.order.map( (x,idx) => {                              
                            return (
                                <TableRow key={x.serviceId}>
                                    <TableCell component="th" scope="row" align="center">{idx + 1}</TableCell>
                                    <TableCell>{x.serviceName}</TableCell>
                                    <TableCell align="right">{x.price}</TableCell>
                                    <TableCell align="right">{x.amount}</TableCell>
                                    <TableCell align="right">{x.dfSender}</TableCell>
                                    <TableCell align="right">{x.dfDoctor}</TableCell>
                                    <TableCell align="center">
                                        <IconButton aria-label="delete" value="dssd" onClick={() => this.deleteService(x.serviceId)}>
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )})}
                        </TableBody>
                        </Table>
                    </Paper>}
                </div>
            </div>
            {state.order.length > 0 && 
                <Button style={{ margin: 10 }} variant="contained" color={state.editMode ? "secondary" : "primary"} onClick={this.onSubmit} disabled={state.loading}>
                    {state.editMode ? "แก้ไข" : "บันทึก"}
                </Button>}
        </div>   
    );
  }
}