import React, {Component} from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const cssTopic = {
    display: 'inline-block',
    marginLeft: '2rem',
    width: '100px'
}

const cssTopicChoice = {
    display: 'inline-block',
    margin: '0 0 1rem 4rem ',
    width: '400px'
}

export default class Sender extends Component {
    constructor() {
        super();
        this.state = {
            xrayCenterId: '',
            addMode: false,
            addSenderName: '',
            addSenderNameEn: '',
            sender: []
        }
    }

    componentDidMount() {
            
        const xrayCenter = JSON.parse(localStorage.getItem('xrayCenter'))
        this.setState({ xrayCenterId: xrayCenter._id })
        fetch(process.env.REACT_APP_API_URL + '/api/sender?xrayCenterId=' + xrayCenter._id)
        .then(res => res.json())
        .then(x => {
            let sender = x.data
            sender.map( s => {
                s.editing = false
                return s
            })
            this.setState({ xrayCenterId: xrayCenter._id, sender: sender })
            this.props.changeTitle('แพทย์ส่ง ' + xrayCenter.name)
            document.title = 'แพทย์ส่ง'    
        });
    }

    buttonClick = (e) => {
        this.props.history.push(e)
    }

    changeActiveStatus = (e) => {
        let theSender = this.state.sender.filter( x => x._id === e._id)
        theSender = theSender[0]
        const payload = {
            _id: e._id,
            active: !theSender.active
        }
        fetch( process.env.REACT_APP_API_URL + '/api/sender', {
            method: 'PATCH',
            body: JSON.stringify(payload),
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(res => res.json())
          .then ( ( x ) => {
            if(x.updated){
                alert('บันทึกเรียบร้อย')
                this.componentDidMount()
            } else {
                alert('เกิดข้อผิดพลาด')
            }
          })
    }

    addSender = () => {
        const data = {
            xrayCenterId: this.state.xrayCenterId,
            name: this.state.addSenderName,
            nameEn: this.state.addSenderNameEn
        }
        fetch( process.env.REACT_APP_API_URL + '/api/sender', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(res => res.json())
          .then ( ( x ) => {
            if(x.ok){
                alert('บันทึกเรียบร้อย')
                window.location.reload()
            } else {
                alert('เกิดข้อผิดพลาด')
            }
          })
    }

    onEdit = (id) => {
        let senders = this.state.sender
        senders.map( s => {
            if (s._id === id)
                s.editing = true
            return s
        })
        this.setState({ sender: senders})

    }
    onSave = (id) => {
        let theSender = this.state.sender.filter( x => x._id === id)
        theSender = theSender[0]
        const payload = {
            _id: id,
            name: theSender.name,
            nameEn: theSender.nameEn
        }
        fetch( process.env.REACT_APP_API_URL + '/api/sender', {
            method: 'PATCH',
            body: JSON.stringify(payload),
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(res => res.json())
          .then ( ( x ) => {
            if(x.updated){
                alert('แก้ไขเรียบร้อย')
                this.componentDidMount()
            } else {
                alert('เกิดข้อผิดพลาด')
            }
          })

    }

    onNameChange = (language, id, value) => {
        let senders = this.state.sender
        senders.map( s => {
            if (s._id === id) {
                if (language === 'th') {
                    s.name = value
                } else {
                    // 'en'
                    s.nameEn = value
                }
            }
            return s
        })
        this.setState({ sender: senders })
    }


    render() {
    const state = this.state
      
    return (
        <div>
            {!state.addMode && <div>
                <Button variant="contained" color="primary" onClick={ () => this.setState({addMode: true}) } style={{marginBottom: "1rem"}}>เพิ่มแพทย์ส่ง</Button>
            </div>}
            { state.addMode && 
                <div style={{ verticalAlign: "top", borderBottom: '1px solid #888', marginBottom: "1rem" }}>
                    <div style={{ display: "flex" }}>
                        <div style={{...cssTopic, height: "4rem"}}><div style={{display: "block", marginTop: "1rem"}}>ชื่อแพทย์ส่ง</div></div>
                        <div style={cssTopicChoice}>
                            <TextField
                                style={{backgroundColor: "white"}}
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                value={state.addSenderName}
                                onChange={ (e) => this.setState({ addSenderName: e.target.value }) }
                            />
                        </div>
                    </div>
                    <div style={{ display: "flex" }}>
                        <div style={{...cssTopic, height: "4rem"}}><div style={{display: "block", marginTop: "1rem"}}>ชื่อภาษาอังกฤษ</div></div>
                        <div style={cssTopicChoice}>
                            <TextField
                                style={{backgroundColor: "white"}}
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                value={state.addSenderNameEn}
                                onChange={ (e) => this.setState({ addSenderNameEn: e.target.value }) }
                            />
                        </div>
                    </div>
                    <Button variant="contained" color="secondary" onClick={ this.addSender } style={{margin: "0 0 2rem 2rem"}}>บันทึก</Button>
                </div>
            }
            { state.sender && 
                <Paper style={{marginTop: "2rem", maxWidth: "800px"}} >
                    <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">แพทย์ส่ง</TableCell>
                            <TableCell align="center" style={{minWidth: "200px"}}>ขื่อภาษาอังกฤษ</TableCell>
                            <TableCell align="center" style={{minWidth: "150px"}}>สถานะ</TableCell>
                            <TableCell align="center" style={{minWidth: "100px"}}>เมนู</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.sender.map(x => {
                        return (
                            <TableRow key={x._id}>
                                <TableCell>
                                    {x.editing ? 
                                        <TextField
                                        style={{backgroundColor: "white"}}
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        value={x.name}
                                        onChange={ (e) => this.onNameChange('th', x._id, e.target.value) }
                                        />
                                    : x.name}
                                </TableCell>
                                <TableCell>
                                    {x.editing ? 
                                        <TextField
                                        style={{backgroundColor: "white"}}
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        value={x.nameEn ? x.nameEn : ''}
                                        onChange={ (e) => this.onNameChange('en', x._id, e.target.value) }
                                        />
                                    : x.nameEn}
                                </TableCell>
                                <TableCell align="center" style={{maxWidth: "100px"}}>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            checked={Boolean(x.active)}
                                            onChange={ () => this.changeActiveStatus(x) }
                                            color="primary"
                                        />
                                        }
                                        label={Boolean(x.active) ? 'เปิดอยู่' : 'ปิดอยู่'}
                                        style={{ height: "1rem" }}
                                    />
                                </TableCell>
                                <TableCell align="center" style={{maxWidth: "100px"}}>
                                    {x.editing ? 
                                        <Button style={{ margin: 10 }} variant="contained" color="secondary" onClick={ () => this.onSave(x._id) }>
                                            บันทึก
                                        </Button>
                                        :
                                        <Button style={{ margin: 10 }} variant="contained" color="primary" onClick={ () => this.onEdit(x._id) }>
                                            แก้ไข
                                        </Button>              
                                    }
    
                                </TableCell>
                            </TableRow>
                        )})}
                    </TableBody>
                    </Table>
                </Paper>
            }
        </div>   
    );
  }
}
