import React, {Component} from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import LineIcon from './component/LineIcon';
import LineTestMessageDialog from './component/LineTestMessageDialog';

const cssFab = {
    marginRight: "1rem"
}


export default class AllCenter extends Component {
    constructor() {
        super();
        this.state = {
            keyword: '',
            xrayCenter: [],
            lineMessageReceiver: null
        }
    }

    componentDidMount() {
        this.props.changeTitle('ศูนย์ทั้งหมด')
        document.title = 'ศูนย์ทั้งหมด'        
        fetch(process.env.REACT_APP_API_URL + `/api/xrayCenter` )
        .then(res => res.json())
        .then(x => {
            let xrayCenter = x.data
            xrayCenter = xrayCenter.sort( a => {
                if ( a.active ) {
                    return -1
                } else {
                    return 1
                }
            })
            this.setState({xrayCenter: xrayCenter})
        });
    }

    buttonClick = (e) => {
        this.props.history.push(e)
    }

    handleLineIconClick = (to, lineId) => {
        let receiver = {
            name: to.name,
            lineType: 'group',

            userId: null,            
            lineUserId: null,

            xrayCenterId: to._id,
            lineGroupId: lineId
        }
        this.setState({lineMessageReceiver: receiver})
    }


    render() {
    const state = this.state
      
    return (
        <div>
            <LineTestMessageDialog lineMessageReceiver={state.lineMessageReceiver} onClose={() => this.setState({lineMessageReceiver: null})}/>
            <Button variant="contained" color="secondary" onClick={() => this.buttonClick('/home/center')}>เพิ่มศูนย์</Button>
            { state.xrayCenter.length !== 0 && 
                <Paper style={{marginTop: "2rem"}}>
                    <Table style={{minWidth: "650px"}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell  align="center" style={{maxWidth: "50px"}}>ชื่อย่อ</TableCell>
                            <TableCell  align="center">ชื่อศูนย์</TableCell>
                            <TableCell align="center">สถานะ</TableCell>
                            <TableCell align="center"><EditIcon/>เมนูแก้ไข</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.xrayCenter.map(x => {
                        return (
                            <TableRow key={x._id}>
                                <TableCell align="center">{x.code}</TableCell>
                                <TableCell>{x.name}{x.nameEn ? ` (${x.nameEn})`: '' }</TableCell>
                                <TableCell align="center">
                                    <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                        >
                                            <Grid item>
                                            {x.active ? 
                                                <Chip label="พร้อมใช้งาน" style={{backgroundColor: `#1de9b6`}}/> :
                                                <Chip label="ปิด"/>}
                                            </Grid>
                                            <Grid item>
                                                {x.active && <LineIcon centerId={x._id} onClick={(lineId) => this.handleLineIconClick(x, lineId)}/>}                                                
                                            </Grid>
                                    </Grid>
                                    
                                </TableCell>
                                <TableCell align="center" style={{width: "400px"}}>
                                    <Fab variant="extended" aria-label="like" size="small" style={cssFab} onClick={() => this.buttonClick('/home/ward?id=' + x._id)}>
                                        Ward
                                    </Fab>
                                    <Fab variant="extended" aria-label="like" size="small" style={cssFab} onClick={() => this.buttonClick('/home/service?id=' + x._id)}>
                                        รายการตรวจ
                                    </Fab>
                                    <Fab variant="extended" aria-label="like" size="small" style={cssFab} onClick={() => this.buttonClick('/home/center?id=' + x._id)}>
                                        ข้อมูลศูนย์
                                    </Fab>
                                </TableCell>
                            </TableRow>
                        )})}
                    </TableBody>
                    </Table>
                </Paper>
            }
        </div>   
    );
  }
}
