import React from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(0),
      minWidth: 120,
      width: "500px"
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

export default function XrayCenterSelect(props) {
    const classes = useStyles();
  
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
      setLabelWidth(inputLabel.current.offsetWidth);
    }, []);
  
    return (
      <div>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel ref={inputLabel}>
            เลือกศูนย์
          </InputLabel>
          <Select
            onChange={props.handleSeleted}
            labelWidth={labelWidth}
            value={props.value}
          >
            { props.list.map( (x) => {
                return (
                    <MenuItem key={x._id} value={x._id} selected={true}>{x.name}</MenuItem>
                )
            }) }
          </Select>
        </FormControl>
      </div>
    );
  }