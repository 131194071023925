export function urgency2Color (urgency) {
    let urgencyColor = "#26E2C9"
    if ( urgency === "Urgency") {
        urgencyColor = "#FBB535"
    } else if (urgency === "Emergency") {
        urgencyColor = "#FF473A"
    } else if (urgency === "Fast-Track"){
        urgencyColor = "#9a00c9"
    }
    return urgencyColor
}

export function sortAttachment (attachment) {
    attachment.sort( x => {
        const extension = x.name.substr(x.name.length-3, x.name.length)
        if (extension === 'zip' || extension === 'rar') {
            return -1
        } else {
            return 1
        }
    })
    attachment.map ( x => {
        const extension = x.name.substr(x.name.length-3, x.name.length)
        if (extension === 'zip' || extension === 'rar') {
            x.type = 'film'
        } else {
            x.type = 'other'
        }
        return x
    })
    return attachment
}

export function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }


export function toBuddhistYear (moment, format) {
    var christianYear = moment.format('YYYY')
    var buddhishYear = (parseInt(christianYear) + 543).toString()
    return moment
      .format(format.replace('YYYY', buddhishYear).replace('YY', buddhishYear.substring(2, 4)))
      .replace(christianYear, buddhishYear)
}

export function getDateString(d, includeTime = false) {
    let out = ''
    if (includeTime) {
        out = d.toLocaleDateString('th-TH', { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit'});
    } else {
        out = d.toLocaleDateString('th-TH', { day: 'numeric', month: 'short', year: 'numeric' });
    }
    return out
}

export function ageToEn (lang, age) {
    if (lang === 'en') {
        age = age.replace('ปี', 'years')
        age = age.replace('เดือน', 'months')
        age = age.replace('วัน', 'days')
    }
    return ' ' + age
    
}