import React, {Component} from 'react';
import moment from 'moment';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { toBuddhistYear } from './service/utils';

const cssRow = {
    margin: '0 0 1rem 0',
    verticalAlign: "top"
}


const cssTopic = {
    display: 'inline-block',
    marginLeft: '2rem',
    width: '100px'
}

const cssTopicChoice = {
    display: 'inline-block',
    margin: '0 0 1rem 4rem ',
    width: '400px'
}

export default class ReverseCase extends Component {
    constructor() {
        super();
        this.state = {
            order: '',
            fetched: false,
            orderId: '',
            user: ''
        }
    }

    componentDidMount() {
        this.props.changeTitle('ย้อนปิดเคส')
        document.title = 'ย้อนปิดเคส'        
        let user = JSON.parse(localStorage.getItem('user'))
        this.setState({user: user})
    }

    buttonClick = (e) => {
        this.props.history.push(e)
    }

    fetchOrder = () => {
        const state = this.state
        fetch(process.env.REACT_APP_API_URL + '/api/orderById?id=' + this.state.orderId)
        .then(res => res.json())
        .then(x => {
            if (x.ok) {
                console.log(x.data)
                if (state.user.role === 'user') {
                    if (!x.data.isDone) {
                        alert('กรุณาปิดเคสก่อน')
                        this.setState({orderId: ''})
                        this.props.history.push('/home/readDoneQueue')
                        return
                    }
                } 
                this.setState({order: x.data, fetched: true})
                if(state.user.role === 'user') {
                    document.getElementById('setReverseBtn').style.visibility = 'visible';
                } else {
                    document.getElementById('setReverseBtn').style.visibility = 'hidden';
                }
            } else {
                alert('รหัสเคสไม่ถูกต้อง')
                this.setState({orderId: ''})
            }
        });
    }

    setReverse = () => {
        const state = this.state
        const data = {
            _id: this.state.order._id,
            isReverseCase: true,
            isDone: false,
            isDiag: false,
            isDoctorRead: false,
        }
        if(state.user.role === 'user') {
            fetch( process.env.REACT_APP_API_URL + '/api/order/setIsDiag', {
                method: 'PATCH',
                body: JSON.stringify(data),
                headers: {
                'Content-Type': 'application/json'
                }
            })
            .then(res => res.json())
            .then ( ( x ) => {
                if(x.updated){
                    if (state.user.role === 'user') {
                        this.props.history.push('/home/uploadQueue')
                    } else if (state.user.role === 'doctor') {
                        this.props.history.push('/home/readQueue')
                    }
                    return
                } else {
                    alert('เกิดข้อผิดพลาด')
                }
            })
        }else{
            alert('สำหรับเจ้าหน้าที่เท่านั้น')
        }
    }

    render() {
    const state = this.state
      
    return (
        <div>
            {!state.fetched && <div style={{ verticalAlign: "top", marginBottom: "1rem" }}>
                <div style={{ display: "flex" }}>
                    <div style={{...cssTopic, height: "4rem"}}><div style={{display: "block", marginTop: "1rem"}}>รหัสเคส</div></div>
                    <div style={cssTopicChoice}>
                        <TextField
                            style={{backgroundColor: "white"}}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={state.addWardName}
                            onChange={ (e) => this.setState({ orderId: e.target.value }) }
                        />
                    </div>
                </div>
                <Button variant="contained" color="primary" onClick={ this.fetchOrder } style={{margin: "0 0 2rem 2rem"}}>ดึงข้อมูล</Button>
            </div>}
            {state.fetched &&  <div style={{ verticalAlign: "top", marginTop: "2rem" }}>
                <div style={cssRow}>
                    <div style={{...cssTopic, fontWeight: "bold"}}>HN</div>
                    <div style={{...cssTopicChoice}}>
                        {state.order.hn}
                    </div>
                </div>
                <div style={cssRow}>
                    <div style={{...cssTopic, fontWeight: "bold"}}>ชื่อผู้ป่วย</div>
                    <div style={{...cssTopicChoice}}>
                        {state.order.patient}
                    </div>
                </div>
                <div style={cssRow}>
                    <div style={{...cssTopic, fontWeight: "bold"}}>รังสีแพทย์</div>
                    <div style={{...cssTopicChoice}}>
                        {state.order.doctor}
                    </div>
                </div>
                <div style={cssRow}>
                    <div style={{...cssTopic, fontWeight: "bold"}}>วันที่ตรวจ</div>
                    <div style={{...cssTopicChoice}}>
                        { toBuddhistYear( moment(state.order.createDate), 'D/M/YYYY H:mm')}
                    </div>
                </div>
                <div style={cssRow}>
                    <div style={cssTopic}>
                        <Button id="setReverseBtn" variant="contained" color="secondary" onClick={ this.setReverse } style={{marginBottom: "1rem"}}>ย้อนปิดเคส</Button>
                    </div> 
                </div>
                
            </div>}
                

        </div>   
    );
  }
}
