import React, {useEffect, useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import EditDoctorTeam from './EditDoctorTeam'

const cssFab = {
    marginRight: "1rem"
}

export default function DoctorTeam ({...props}){
    const [teams, setTeams] = useState([])
    const [editingTeam, setEditingTeam] = useState()
    const [openModal, setOpenModal] = useState(false)

    function loadAllDoctorTeam() {
        fetch(process.env.REACT_APP_API_URL + '/api/doctorTeam/all')
            .then(res => res.json())
            .then(x => {
                if (x.ok) {
                    setTeams(x.data)
                }
            });
    }

    useEffect(()=> {
        props.changeTitle('ทีมรังสีแพทย์')
        loadAllDoctorTeam()
    },[])

    const handleCreateClick = () => {
        setOpenModal(true)
        setEditingTeam()
    }

    const handleModalClose = () => {
        setOpenModal(false)
        loadAllDoctorTeam()
    }

    const handleTeamEdit = (id) => {
        let x = teams.filter( t => t._id === id)
        if (x.length !== 1)
            return

        x = x[0]
        setEditingTeam(x)
        setOpenModal(true)
    }

    const changeActiveStatus = (team) => {
        let data = {
            _id: team._id,
            active: !team.active,
        }
        fetch( process.env.REACT_APP_API_URL + '/api/doctorTeam', {
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(res => res.json())
          .then ( ( x ) => {
            if(x.updated || x.ok) {
                loadAllDoctorTeam()
            } else {
                alert('เกิดข้อผิดพลาด')
            }
          })

    }


    return (
        <div>
            <EditDoctorTeam open={openModal} onClose={handleModalClose} doctorTeam={editingTeam}/>
            <Button style={{ margin: "0 0 2rem 0" }} variant="contained" color="secondary" onClick={handleCreateClick}>สร้างทีมใหม่</Button>
                
            <Paper style={{marginTop: "2rem", maxWidth: "800px"}}>
                <Table style={{minWidth: "650px"}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center" style={{maxWidth: "50px"}}>ชื่อทีม</TableCell>
                        <TableCell align="center">รังสีแพทย์</TableCell>
                        <TableCell align="center">สถานะ</TableCell>
                        <TableCell align="center">เมนู</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {teams.map(x => {
                    return (
                        <TableRow key={x._id}>
                            <TableCell align="center">{x.teamName}</TableCell>
                            <TableCell align="center">
                                {x.doctorInfo.map(d => 
                                    <div key={d._id}>{`${d.title} ${d.firstname} ${d.lastname}`}</div>
                                )}
                            
                            </TableCell>
                            <TableCell align="center">
                                 <FormControlLabel
                                    control={
                                    <Switch
                                        checked={Boolean(x.active)}
                                        onChange={ () => changeActiveStatus(x) }
                                        color="primary"
                                    />
                                    }
                                    label="เปิด"
                                    style={{ height: "1rem" }}
                                />
                            </TableCell>
                            <TableCell align="center">
                                <Fab variant="extended" aria-label="like" size="small" style={cssFab} onClick={() => handleTeamEdit(x._id)}>
                                    <EditIcon/>แก้ไข
                                </Fab>
                            </TableCell>
                        </TableRow>
                    )})}
                </TableBody>
                </Table>
            </Paper>
        </div>   
    );
}