import React from 'react';
import Button from '@material-ui/core/Button';
import { createExcel } from '../service/createExcel';

export default function DownloadExcelButton({header, data, dataDefinition, filename}) {

    const download = () => {
        let newData = []

        data.map(x => {
            let row = []
            dataDefinition.map(key => {
                row.push(x[key])
                return key
            })
            newData.push(row)
            return x
        })

        createExcel(header, newData, filename)
    }

    return (
        <React.Fragment>
            {header && data && filename &&
                <Button variant="contained" color="secondary" onClick={download}>
                    Download Excel
                </Button>
        }
        </React.Fragment>
    );
}