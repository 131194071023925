import React, {Component} from 'react';
import moment from 'moment';
import { toBuddhistYear } from './service/utils';
import queryString from 'query-string';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import OrderStatusTag from './component/OrderStatusTag';
import Fab from '@material-ui/core/Fab';
import ReceiptIcon from '@material-ui/icons/Receipt';

const cssFab = {
    marginRight: "1rem"
}


export default class History extends Component {
    constructor() {
        super();    
        this.state = {
            order: [],
            patientId: '',
        }

    }

    componentDidMount() {
        const query = queryString.parse(this.props.location.search);  
        let user = JSON.parse(localStorage.getItem('user'))

        let payload = `/api/orderByDoctor?patientId=${query.id}&sort=dateDesc&isDeleted=false`
        if (user.role === 'doctor') {
            payload = `/api/orderByDoctor?patientId=${query.id}&sort=dateDesc&isDone=true&isDeleted=false`
        }

        fetch(process.env.REACT_APP_API_URL + payload)
        .then(res => res.json())
        .then(x => {
            console.log(x.data)
            if (x.data.length > 0) {
                this.props.changeTitle(`ประวัติตรวจ (${x.data[0].patient})`)
            } else {
                this.props.changeTitle(`ไม่พบประวัติ`)
            }
            document.title = 'ประวัติตรวจ'
            this.setState({order: x.data, patientId: query._id})
        });
    }

    
    buttonClick = (e) => {
        this.props.history.push(e)
    }

    render() {
    const state = this.state
    return (
        <div>
            { state.order.length > 0 && 
                <Paper style={{marginTop: "1rem", maxWidth: "1000px"}}>
                    <Table style={{minWidth: "650px"}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">วันที่ตรวจ</TableCell>
                            <TableCell align="center">รายการตรวจ</TableCell>
                            <TableCell align="center">แพทย์ส่ง</TableCell>
                            <TableCell align="center">รังสีแพทย์</TableCell>
                            <TableCell align="center">สถานะ</TableCell>
                            <TableCell align="center">เมนู</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.order.map(x => {
                            const date = moment(x.createDate)
                            return (
                                <TableRow key={x._id}>
                                    <TableCell align="center">
                                        <div style={{display: "block"}}>{date.format("H:mm")}</div>
                                        <div style={{display: "block"}}>{ toBuddhistYear( date, "D/M/YYYY")}</div>
                                    </TableCell>
                                    <TableCell>
                                        {x.option.map( (o, idx) => {
                                            return <div key={o._id} style={{display: "block"}}>{o.serviceName}</div>
                                        })}
                                    </TableCell>
                                    <TableCell>{x.sender}</TableCell>
                                    <TableCell>{x.doctor}</TableCell>
                                    <TableCell align="center">
                                        <OrderStatusTag order={x}/>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Fab variant="extended" aria-label="like" size="small" style={cssFab} onClick={() => this.buttonClick('/home/readFilm?id=' + x._id)}>
                                            <ReceiptIcon style={{marginRight: "0.5rem"}}/>
                                            รายละเอียด
                                        </Fab>
                                    </TableCell>
                                </TableRow>
                            )})}
                    </TableBody>
                    </Table>
                </Paper>
            }
        </div>   
    );
  }
}