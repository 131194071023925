import XLSX from 'xlsx';

function calculateColWidth (data) {
    let objectMaxLength = []; 
    for (let i = 0; i < data.length; i++) {
        let value = Object.values(data[i]);
        for (let j = 0; j < value.length; j++) {
            if (typeof value[j] == "number") {
                objectMaxLength[j] = 10;
            } else if (value[j]) {
                objectMaxLength[j] =
                    objectMaxLength[j] >= value[j].length
                    ? objectMaxLength[j]
                    : value[j].length;
            }
        }
    }

    let wscols = []
    for (let i = 0; i < objectMaxLength.length; i++) {
        wscols.push({width: objectMaxLength[i]})
    }
    return wscols
}

export function createExcel(header, data, filename) {
    let rows = []
    rows.push(header)
    rows = rows.concat(data)
    const ws = XLSX.utils.aoa_to_sheet(rows);
    ws["!cols"] = calculateColWidth(rows);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Report");
    XLSX.writeFile(wb, `${filename}.xlsx`)
}