import Chip from '@material-ui/core/Chip';
import React, {Component} from 'react';

export default class OrderStatusTag extends Component {
    
    render(){
        const order = this.props.order
        let label = ''
        let color = ''
        if (order) {
            if (order.isDone) {
                label = "ปิดเคส"
                color = '#1de9b6'
            } else if (order.isDiag) {
                label = "รอพิมพ์ผล"
                color = '#fff59d'
            } else if (order.isDoctorRead) {
                label = "แพทย์อ่านอยู่"
                color = '#bdbdbd'
            } else if (order.isUploaded) {
                label = "รอแพทย์เปิด"
                color = '#90caf9'
            } else {
                label = "รออัพโหลด"
                color = '#ff8a80'        
            }
        }
        return (
            <Chip label={label} style={{marginLeft: "1rem", backgroundColor: `${color}`}}/>
        )
    }
}
    
