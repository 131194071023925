import React, { useEffect } from 'react';
import queryString from 'query-string';
import moment from 'moment'
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AutoComplete from './component/AutoComplete';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import { toBuddhistYear } from './service/utils';

const cssFab = {
  marginRight: "1rem"
}


const prefix = ['นาย', 'นาง', 'นางสาว', 'เด็กชาย', 'เด็กหญิง', 'อื่นๆ']

const useStyles = makeStyles(theme => ({
  container: {
    flexWrap: 'wrap',
    width: '800px',
    
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: 'block',
    margin: 10,
  },
  typography: {
    margin: 10,
    display: 'block',
  }
}));

export default function NewPatient(props) {
  const classes = useStyles();

  const [title, setTitle] = React.useState('อื่นๆ');
  const [xrayCenter] = React.useState(() => {
    let x = JSON.parse(localStorage.getItem('xrayCenter'))
    x.ward = x.ward.filter ( x => x.active )
    x.ward.map ( x => x.label = x.name )
    return x
  });
  const [ward, setWard] = React.useState('');
  const [user, setUser] = React.useState('');
  const [userId, setUserId] = React.useState('');
  const [hn, setHn] = React.useState('');
  const [firstname, setFirstname] = React.useState('');
  const [lastname, setLastname] = React.useState('');
  const [age, setAge] = React.useState('');
  const [ageUnit, setAgeUnit] = React.useState('ปี');
  const [tel, setTel] = React.useState('');
  const [nationalId, setNationalId] = React.useState('');
  const [payment, setPayment] = React.useState('')
  const [wardEdit, setWardEdit] = React.useState(true)
  const [loading, setLoading] = React.useState(false)
  const [patientId, setPatientId] = React.useState('')
  const [etcTitle, setEtcTitle] = React.useState('')
  const [creator, setCreator] = React.useState('')
  const [createDate, setCreateDate] = React.useState('')

  const handleChange = event => {
    setTitle(event.target.value);
  };

  const handleAge = event => {
    setAgeUnit(event.target.value);
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    setUser(user.firstname + ' ' + user.lastname)
    setUserId(user.id)

    const query = queryString.parse(props.location.search);  
    if (!props.location.search) {
      props.changeTitle('ผู้ป่วยใหม่')
      document.title = 'ผู้ป่วยใหม่'
    } else {
      document.title = 'แก้ไขประวัติ'
      fetch(process.env.REACT_APP_API_URL + '/api/patient?id=' + query.id)
      .then(res => res.json())
      .then(x => {
        const patient = x.data
        setPatientId(patient._id)
        setHn(patient.hn)
        setNationalId(patient.nationalId)
        if (!prefix.includes(patient.title)) {
          setTitle('อื่นๆ')
          setEtcTitle(patient.title)
        } else {
          setTitle(patient.title)
        }
        setFirstname(patient.firstname)
        setLastname(patient.lastname)
        setLastname(patient.lastname)
        setAge(patient.age)
        setAgeUnit(patient.ageUnit)
        setTel(patient.tel)
        setPayment(patient.payment)
        setWard(patient.ward)
        setWardEdit(false)
        setCreateDate(patient.createDate)
        props.changeTitle('แก้ไขประวัติ (คุณ' + patient.firstname + ' ' + patient.lastname + ')')
        fetch(process.env.REACT_APP_API_URL + '/api/userById?_id=' + patient.creator)
            .then(res => res.json())
            .then(x => {
                let user = x.data
                if (user) {
                  user = user.firstname + ' ' + user.lastname
                  setCreator(user)
                }
            });
      });
    }
  }, [])

  const onSubmit = async () => {
    let aTitle = title
    if (aTitle === 'อื่นๆ') {
      aTitle = etcTitle
    }
    if (!firstname) {
      alert('กรุณากรอกชื่อ')
      return
    }
    if (!ward) {
      alert('กรุณาระบุ Ward')
      return
    }
    if (!payment) {
      alert('กรุณาระบุ สิทธิ์')
      return
    }

    const foundIds = await checkHn()

    // edit patient
    if (patientId) {
      createNewPatient(aTitle)

    // new patient
    } else {
      if (foundIds.length === 0) {
        createNewPatient(aTitle)
      } else {
        alert('HNซ้ำ ไม่สามารถสร้างผู้ป่วยใหม่ได้ กรุณาแก้ไขHN')
      }
    }
  }

  const checkHn = async () => {
    // return [patientId]
    // if such hn exists it returns the exisiting patient id
    let res = await axios.get( `${process.env.REACT_APP_API_URL}/api/patientSearchHn?hn=${hn}&xrayCenterId=${xrayCenter._id}`)
    res = res.data

    if (res.ok && res.data.length > 0) {
      let out = res.data.map(x => x._id)
      return out
    } else {
      return []
    }
  }

  const createNewPatient = (aTitle) => {
    let body = {
      hn: hn,
      title: aTitle,
      firstname: firstname,
      lastname: lastname,
      age: age,
      ageUnit: ageUnit,
      xrayCenter: xrayCenter._id,
      creator: userId,
      ward: ward.name,
      wardId: ward._id,
      tel: tel,
      nationalId: nationalId,
      payment: payment,
      createDate: moment().toISOString()
    }
    let method = 'POST'
    if (patientId) {
      body._id = patientId
      method = 'PATCH'
    }
    setLoading(true)
    fetch( process.env.REACT_APP_API_URL + '/api/patient', {
      method: method,
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(res => res.json())
    .then ( ( x ) => {
      if(x.ok || x.updated){
        props.history.push('/home/searchPatient')
      } else {
        alert('เกิดข้อผิดพลาด')
      }
    })
  }

  return (
    <div className={classes.container}>
        <div>
          <div style={{width: "50%", display: "inline-block", paddingRight: "1rem"}}>
            <TextField
              id="outlined-full-width"
              label="HN"
              className={classes.textField}
              margin="normal"
              fullWidth
              InputLabelProps={{
                  shrink: true,
              }}
              value={hn || ''}
              onChange={(e) => setHn(e.target.value)}
              variant="outlined"
            />
          </div>
          <div style={{width: "50%", display: "inline-block"}}>
            <TextField
              id="outlined-full-width"
              label="เลขบัตรประชาชน"
              className={classes.textField}
              margin="normal"
              fullWidth
              InputLabelProps={{
                  shrink: true,
              }}
              value={nationalId || ''}
              onChange={(e) => setNationalId(e.target.value)}
              variant="outlined"
            />
          </div>
        </div>
        
        <div style={{marginBottom: "1rem"}}>
          {prefix.map( x => 
            <div style={{display: "inline-block", margin: "0.3rem 1rem auto 0"}} key={x}>
                <Radio
                    checked={title === x}
                    onChange={handleChange}
                    value={x}
                    name="radio-button-demo"
                    inputProps={{ 'aria-label': 'A' }}
                    color="primary"
                    key={x}
                /> {x}
            </div>)}
 
            {title === 'อื่นๆ' && <div style={{display: "inline-block", verticalAlign: "top"}}>
              <TextField
                className={classes.textField}
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                }}
                value={etcTitle || ''}
                onChange={(e) => setEtcTitle(e.target.value)}
                style={{display: "inline-block"}}
              />
            </div>}
        </div>
        <div>
          <div style={{width: "50%", display: "inline-block", paddingRight: "1rem"}}>
            <TextField
              id="outlined-full-width"
              label="ชื่อ"
              className={classes.textField}
              margin="normal"
              fullWidth
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
              value={firstname || ''}
              onChange={(e) => setFirstname(e.target.value)}
            />
          </div>
          <div style={{width: "50%", display: "inline-block"}}>
            <TextField
              id="outlined-full-width"
              label="นามสกุล"
              className={classes.textField}
              margin="normal"
              fullWidth
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
              value={lastname || ''}
              onChange={(e) => setLastname(e.target.value)}
            />
          </div>
        </div>
        
        
        <div>
          <TextField
              id="outlined-full-width"
              label="อายุ"
              className={classes.textField}
              margin="normal"
              width="50%"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
              style={{display: "inline-block"}}
              value={age || ''}
              onChange={(e) => setAge(e.target.value)}
          />
          <div style={{height: "100%", margin: "1rem 0", display: "inline-block"}}>
              <div  style={{display: "inline-block"}}>
                  <Radio
                      checked={ageUnit === "ปี"}
                      onChange={handleAge}
                      value="ปี"
                      name="radio-button-demo"
                      color="primary"
                      inputProps={{ 'aria-label': 'A' }}
                  /> ปี
              </div>
              <div style={{display: "inline-block"}}>
                  <Radio
                      checked={ageUnit === "เดือน"}
                      onChange={handleAge}
                      value="เดือน"
                      name="radio-button-demo"
                      color="primary"
                      inputProps={{ 'aria-label': 'A' }}
                  /> เดือน
              </div>
              <div style={{display: "inline-block"}}>
                  <Radio
                      checked={ageUnit === "วัน"}
                      onChange={handleAge}
                      value="วัน"
                      name="radio-button-demo"
                      color="primary"
                      inputProps={{ 'aria-label': 'A' }}
                  /> วัน
              </div>
          </div>
          <TextField
              id="outlined-full-width"
              label="เบอร์โทร"
              className={classes.textField}
              margin="normal"
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
              style={{display: "inline-block"}}
              value={tel || ''}
              onChange={(e) => setTel(e.target.value)}
          />
        </div>
        <div>
        <div style={{ display: "inline-block", verticalAlign: "top" , margin: "0.7rem 1.5rem 0 1rem", fontWeight: "bold"}}>สิทธิ์</div>
        <div style={{ display: "inline-block" }}>
            <RadioGroup aria-label="position" name="payment" value={payment} onChange={(x) => setPayment(x.target.value)} row>
                <FormControlLabel
                  value="บัตรทอง"
                  control={<Radio color="primary" />}
                  label="บัตรทอง"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="ประกันสังคม"
                  control={<Radio color="primary" />}
                  label="ประกันสังคม"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="พรบ"
                  control={<Radio color="primary" />}
                  label="พรบ"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="เบิกราชการ"
                  control={<Radio color="primary" />}
                  label="เบิกราชการ"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="ชำระเงินสด"
                  control={<Radio color="primary" />}
                  label="ชำระเงินสด"
                  labelPlacement="end"
                />
            </RadioGroup>
        </div>
    </div>
        {wardEdit && <div style={{margin: "0.5rem 0 1rem 0.5rem"}}>
          <AutoComplete list={xrayCenter.ward} placeholder="Ward" onChange={ (x) => setWard(x)}/>
        </div>}
        {!wardEdit && <Typography className={classes.typography} variant="body1" gutterBottom>
          <Fab variant="extended" aria-label="like" size="small" style={cssFab} onClick={() => setWardEdit(true)}>
              <EditIcon /> แก้ไข
          </Fab>Ward {ward}
        </Typography>}
        <div style={{display: 'block', marginTop: "1.4rem"}}>
          { 
            creator ?   
              <Typography className={classes.typography} variant="body1" gutterBottom>
                  ผู้บันทึก: {creator}
              </Typography>
              : ''
          }
          { 
            createDate ?   
              <Typography className={classes.typography} variant="body1" gutterBottom>
                  วันที่แก้ไขล่าสุด: { toBuddhistYear( moment(createDate), 'D/M/YYYY H:mm')}
              </Typography>
              : ''
          }
          { 
            patientId ?   
              <Typography className={classes.typography} variant="body1" gutterBottom>
                  รหัสผู้ป่วย: {patientId}
              </Typography>
              : ''
          }
          <Button style={{ margin: 10 }} variant="contained" color="primary" onClick={onSubmit} disabled={loading}>บันทึก</Button>
        </div>

    </div>
  );
}