import React, {Component} from 'react';

import queryString from 'query-string';
import moment from 'moment';
import './report.css'
import { BAHTTEXT } from './service/bathtext';
import { formatNumber, toBuddhistYear } from './service/utils';
var HtmlToReactParser = require('html-to-react').Parser;

const cssBlockHead = {
    fontWeight: "bold",
    display: "inline-block",
    width: "150px",
    verticalAlign: "top"
}

const cssBlock = {
    display: "inline-block"
}

export default class Receipt extends Component {
    constructor() {
        super();
        this.state = {
            order: '',
            xrayCenter: '',
            doctor: ''
        }
    }    


    componentDidMount() {
        const query = queryString.parse(this.props.location.search);         

        fetch(process.env.REACT_APP_API_URL + '/api/orderById?id=' + query.id)
        .then(res => res.json())
        .then(x => {
            const order = x.data
            this.setState({order: order})
            document.title = 'ใบเสร็จ'
            fetch(process.env.REACT_APP_API_URL + '/api/xrayCenterById?id=' + order.xrayCenterId)
            .then(res => res.json())
            .then(y => {
                this.setState({xrayCenter: y.data})
            });
        });

    }


    render() {
        const state = this.state
        
        let totalPrice = 0      
        let address = state.xrayCenter.address ? state.xrayCenter.address : ''
        address = address.replace("%%", "")

        var htmlToReactParser = new HtmlToReactParser();

        return (
            <div className="paper">
                {state.order && 
                    <div style={{ marginBottom: '2rem', paddingBottom: '1rem'}}>
                        <div style={{marginBottom: "1rem", borderBottom: '1px solid black',}}>
                            <div style={{margin: "1rem 0 1rem 0"}}>
                                <div style={{fontWeight: "bold"}}>{state.order.xrayCenter}</div>
                                {htmlToReactParser.parse(address)}
                            </div>
                        </div>
                        <h2 style={{textAlign: "center", margin: "3rem auto"}}>{state.order.payment === "ชำระเงินสด" ? 'ใบเสร็จรับเงิน' : 'ใบแจ้งค่าบริการ'}</h2>
                        <div style={{width: "80%", margin: "auto"}}>
                            <div style={{margin: "0 auto 0 auto", display: "block", width: "100%"}}>
                                <div>
                                    <div style={{...cssBlockHead}}>เลขที่</div>
                                    <div style={{...cssBlock}}>{state.order.receiptId ? state.order.receiptId : '-'}</div>
                                </div>   
                                <div>
                                    <div style={{...cssBlockHead}}>วันที่</div>
                                    <div style={{...cssBlock}}>{ toBuddhistYear( moment(state.order.createDate), 'D/M/YYYY')}</div>
                                </div>   
                                
                                <div style={{marginTop: "1rem"}}>
                                    <div style={{...cssBlockHead}}>ชื่อ-สกุล</div>
                                    <div style={{...cssBlock}}>
                                        {state.order.patient}
                                        <b style={{marginLeft: "2rem"}}>HN</b> {state.order.hn ? state.order.hn : '-'}
                                        <br/><b>อายุ</b> {state.order.age ? state.order.age : '-'}
                                        <b style={{marginLeft: "2rem"}}>สิทธิ์</b> {state.order.payment ? state.order.payment : '-'}
                                        
                                    </div>
                                </div>
                                <div>
                                    <div style={{...cssBlockHead}}>แพทย์ส่งตรวจ</div>
                                    <div style={{...cssBlock}}>
                                        {state.order.sender}
                                        <br/><b>Ward</b> {state.order.ward ? state.order.ward : '-'}
                                    </div>
                                </div>
                            </div>
                            <table style={{width: "100%", margin: "2rem auto 2rem auto"}}>
                                <thead>
                                    <tr>
                                        <th>ลำดับ</th>
                                        <th>รายการ</th>
                                        <th align="right">ราคา</th>
                                        <th align="right">จำนวน</th>
                                        <th align="right">ราคารวม</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { state.order.option.map( (o,idx) => {
                                        const subTotal = parseInt(o.price) * parseInt(o.amount)
                                        totalPrice += subTotal
                                        return (
                                            <tr key={o._id}>
                                                <td align="center">{idx +1}</td>
                                                <td>{o.serviceName}{o.code ? ' (' + o.code + ')' : ''}</td>
                                                <td align="right">{formatNumber(o.price)}</td>
                                                <td align="right">{o.amount}</td>
                                                <td align="right">{formatNumber(subTotal)}</td>
                                            </tr>
                                            )
                                    })}
                                </tbody>
                            </table>
                            <div style={{textAlign: "right"}}>
                                <b>ยอดรวม </b>{formatNumber(totalPrice)} <br/>
                                (...{BAHTTEXT(totalPrice)}...)
                                <div style={{width: "400px", margin: "7rem 0 0 auto", textAlign: "center"}}>
                                    ลงชื่อ .......................................... เจ้าหน้าที่<br/>
                                    (......{state.order.creator}......)
                                </div>
                            </div>
                            
                        </div>
                    </div>
                }
            </div>
        )
    }
}